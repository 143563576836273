import { Grid, Paper, Typography } from "@mui/material";
import { Colors } from "../../../../../../config/palette";
import CourseCard from "./../../Coursecard/CourseCard";
import { useSelector } from "react-redux";
import English from "../../../../../../config/Langugage/English.json";
import Polish from "../../../../../../config/Langugage/Polish.json";
import CourseSkeleton from "../../../../../UI/skeleton/CourseSkeleton";

const PopularCoursesHome = ({ courseList, showBlogLoader }) => {
  const selector = useSelector((state) => state.auth.language);
  const size = { sm: "6", md: "4", lg: "4" };
  return (
    <Paper elevation={0} square={true} style={{ background: Colors.secondary }}>
      <Typography
        sx={{
          color: Colors.light,
          textAlign: "center",
          paddingTop: "50px",
          fontSize: "32.69px",
          fontWeight: "bold",
          fontFamily: '"Poppins", "sans-serif"',
        }}
      >
        {/* Popular Courses */}
        {selector === "English" ? ` ${English["36"]}` : Polish["36"]}
      </Typography>
      <Grid
        container
        spacing={6}
        // sx={{ paddingTop: "50px", paddingBottom: "50px" }}
        sx={{
          paddingTop: "50px",
          paddingBottom: "50px",
          paddingLeft: { md: "60px", sm: "0px" },
        }}
      >
        {showBlogLoader ? (
          <>
            <Grid item xs={12} sm={6} md={4}>
              <CourseSkeleton />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CourseSkeleton />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <CourseSkeleton />
            </Grid>
          </>
        ) : (
          <>
            <CourseCard size={size} courseList={courseList} />
            {/* <CourseSkeleton /> */}
          </>
        )}
      </Grid>
    </Paper>
  );
};

export default PopularCoursesHome;
