import React from "react";

const HomeSliderIcon = () => {
  const width = "100%";
  const height = "100%";

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 495 387"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4_8833)">
        <path
          d="M405.175 81.7588L168.976 243.912L152.856 220.431L354.855 81.7588H405.175Z"
          fill="#F2F2F2"
        />
        <path
          d="M482.779 81.7588V105.24L150.487 333.359L134.367 309.878L466.663 81.7588H482.779Z"
          fill="#F2F2F2"
        />
        <path
          d="M482.779 150.877V185.421L226.162 361.591H175.837L482.779 150.877Z"
          fill="#F2F2F2"
        />
        <path
          d="M247.42 386.077C384.029 386.077 494.772 380.931 494.772 374.584C494.772 368.236 384.029 363.091 247.42 363.091C110.811 363.091 0.0673828 368.236 0.0673828 374.584C0.0673828 380.931 110.811 386.077 247.42 386.077Z"
          fill="#E63369"
        />
        <path
          opacity="0.1"
          d="M365.849 384.578C397.586 384.578 423.315 380.999 423.315 376.583C423.315 372.167 397.586 368.588 365.849 368.588C334.112 368.588 308.383 372.167 308.383 376.583C308.383 380.999 334.112 384.578 365.849 384.578Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M163.969 384.578C203.986 384.578 236.426 380.327 236.426 375.083C236.426 369.84 203.986 365.589 163.969 365.589C123.953 365.589 91.5127 369.84 91.5127 375.083C91.5127 380.327 123.953 384.578 163.969 384.578Z"
          fill="black"
        />
        <path
          d="M250.783 111.333L263.475 108.432L272.904 111.333L201.1 368.089L190.946 373.891L201.1 242.612L250.783 111.333Z"
          fill="#CCCCCC"
        />
        <path
          opacity="0.1"
          d="M250.783 111.333L263.475 108.432L272.904 111.333L201.1 368.089L190.946 373.891L201.1 242.612L250.783 111.333Z"
          fill="black"
        />
        <path
          d="M71.8149 31.7314L192.215 373.71L264.744 108.613L71.8149 31.7314Z"
          fill="#CCCCCC"
        />
        <path
          d="M108.44 246.872C128.742 265.237 124.912 283.718 111.445 298.605C111.132 298.951 110.817 299.291 110.499 299.627C109.86 300.303 109.211 300.958 108.552 301.59C95.4468 314.165 78.5511 317.864 59.6701 300.785C40.1306 283.109 38.0624 235.831 37.9333 232.308C37.9348 232.307 37.9348 232.307 37.9347 232.304C37.9295 232.17 37.9277 232.102 37.9277 232.102C37.9277 232.102 88.139 228.508 108.44 246.872Z"
          fill="#CCCCCC"
        />
        <path
          d="M107.452 296.776L102.595 274.456L108.941 298.169L110.499 299.626C109.86 300.303 109.211 300.957 108.552 301.589L89.2023 282.155L89.0377 282.021L89.0243 281.975L87.1964 280.138L62.9075 276.184L85.7856 278.808L86.215 279.151L71.5955 264.468L48.0855 258.624L68.993 261.924L37.933 232.308L37.8301 232.209L37.9343 232.303L62.8989 255.139L60.5895 237.698L65.0268 257.13L78.5653 269.783L72.5134 249.968L80.557 271.642L88.0852 278.677L80.084 250.628L90.7101 281.133L107.452 296.776Z"
          fill="#E63369"
        />
        <path
          d="M162.471 212.181C145.669 212.181 129.245 217.163 115.276 226.497C101.306 235.832 90.4175 249.099 83.9879 264.621C77.5583 280.144 75.876 297.224 79.1538 313.703C82.4316 330.181 90.5222 345.318 102.403 357.198C114.283 369.079 129.419 377.169 145.898 380.447C162.377 383.725 179.457 382.042 194.979 375.613C210.502 369.183 223.769 358.295 233.104 344.325C242.438 330.355 247.42 313.931 247.42 297.13C247.42 274.6 238.47 252.993 222.539 237.062C206.608 221.131 185.001 212.181 162.471 212.181V212.181ZM163.97 317.868C154.449 317.868 146.73 308.471 146.73 296.88C146.73 285.289 154.449 275.893 163.97 275.893C173.491 275.893 181.21 285.289 181.21 296.88C181.21 308.471 173.491 317.868 163.97 317.868V317.868Z"
          fill="#E63369"
        />
        <path
          opacity="0.1"
          d="M163.47 317.867C153.949 317.867 146.23 308.471 146.23 296.88C146.23 285.289 153.949 275.892 163.47 275.892C172.691 275.892 180.221 284.706 180.687 295.79C180.697 295.488 180.71 295.186 180.71 294.881C180.708 289.308 178.88 283.889 175.505 279.454C172.13 275.02 167.394 271.814 162.022 270.328C156.651 268.843 150.941 269.159 145.766 271.229C140.592 273.299 136.239 277.008 133.374 281.788C130.509 286.569 129.291 292.156 129.905 297.695C130.52 303.234 132.933 308.419 136.776 312.455C140.619 316.492 145.679 319.157 151.181 320.042C156.684 320.928 162.324 319.985 167.239 317.358C166.011 317.694 164.743 317.866 163.47 317.867V317.867Z"
          fill="black"
        />
        <path
          d="M74.1725 0C66.9283 -9.88316e-07 59.8467 2.14817 53.8233 6.17286C47.8 10.1976 43.1053 15.918 40.333 22.6108C37.5608 29.3036 36.8354 36.6682 38.2487 43.7733C39.662 50.8783 43.1504 57.4048 48.2729 62.5272C53.3954 67.6497 59.9218 71.1381 67.0268 72.5514C74.1319 73.9647 81.4965 73.2393 88.1893 70.4671C94.8821 67.6948 100.603 63.0002 104.627 56.9768C108.652 50.9534 110.8 43.8719 110.8 36.6276C110.8 31.8176 109.853 27.0547 108.012 22.6108C106.171 18.167 103.473 14.1292 100.072 10.728C96.671 7.32679 92.6332 4.62882 88.1893 2.78811C83.7455 0.9474 78.9825 0 74.1725 0V0ZM74.8189 45.569C70.7137 45.569 67.3857 41.5176 67.3857 36.5199C67.3857 31.5222 70.7137 27.4707 74.8189 27.4707C78.9241 27.4707 82.2522 31.5221 82.2522 36.5199C82.2522 41.5176 78.9241 45.569 74.8189 45.569Z"
          fill="#E63369"
        />
        <path
          opacity="0.1"
          d="M74.6039 45.5688C70.4987 45.5688 67.1707 41.5173 67.1707 36.5196C67.1707 31.5219 70.4987 27.4704 74.6039 27.4704C78.5798 27.4704 81.8265 31.2705 82.0273 36.0499C82.0319 35.9195 82.0372 35.7893 82.0372 35.6578C82.0367 33.2549 81.2484 30.9184 79.7931 29.0063C78.3378 27.0942 76.2957 25.712 73.9798 25.0714C71.6638 24.4308 69.2017 24.5672 66.9707 25.4597C64.7397 26.3521 62.8628 27.9515 61.6276 30.0126C60.3924 32.0737 59.867 34.483 60.1319 36.8712C60.3969 39.2595 61.4375 41.495 63.0944 43.2353C64.7513 44.9756 66.9331 46.1247 69.3055 46.5065C71.6779 46.8883 74.11 46.4817 76.2293 45.3491C75.6996 45.4942 75.153 45.568 74.6039 45.5688V45.5688Z"
          fill="black"
        />
        <path
          d="M340.864 330.609H335.368V377.082C335.368 377.082 370.846 388.575 405.326 377.082V330.609H340.864Z"
          fill="#E63369"
        />
        <path
          d="M369.847 336.107C388.89 336.107 404.326 333.758 404.326 330.86C404.326 327.962 388.89 325.613 369.847 325.613C350.805 325.613 335.368 327.962 335.368 330.86C335.368 333.758 350.805 336.107 369.847 336.107Z"
          fill="#E63369"
        />
        <path
          opacity="0.1"
          d="M369.847 336.107C388.89 336.107 404.326 333.758 404.326 330.86C404.326 327.962 388.89 325.613 369.847 325.613C350.805 325.613 335.368 327.962 335.368 330.86C335.368 333.758 350.805 336.107 369.847 336.107Z"
          fill="black"
        />
        <path
          d="M409.927 275.086C401.229 307.239 371.436 327.153 371.436 327.153C371.436 327.153 355.746 294.935 364.444 262.782C373.142 230.629 402.934 210.716 402.934 210.716C402.934 210.716 418.625 242.933 409.927 275.086Z"
          fill="#E63369"
        />
        <path
          d="M330.799 275.086C339.497 307.239 369.29 327.153 369.29 327.153C369.29 327.153 384.98 294.935 376.282 262.782C367.584 230.629 337.791 210.716 337.791 210.716C337.791 210.716 322.101 242.933 330.799 275.086Z"
          fill="#CCCCCC"
        />
        <path
          d="M343.579 268.176C367.447 291.41 370.362 327.126 370.362 327.126C370.362 327.126 334.58 325.173 310.713 301.938C286.845 278.704 283.93 242.988 283.93 242.988C283.93 242.988 319.712 244.942 343.579 268.176Z"
          fill="#E63369"
        />
        <path
          d="M397.145 268.176C373.278 291.41 370.362 327.126 370.362 327.126C370.362 327.126 406.144 325.173 430.011 301.938C453.879 278.704 456.794 242.988 456.794 242.988C456.794 242.988 421.013 244.942 397.145 268.176Z"
          fill="#E63369"
        />
        <path
          d="M370.346 221.674C376.417 221.674 381.339 216.752 381.339 210.681C381.339 204.609 376.417 199.688 370.346 199.688C364.274 199.688 359.353 204.609 359.353 210.681C359.353 216.752 364.274 221.674 370.346 221.674Z"
          fill="#CCCCCC"
        />
        <path
          d="M165.687 124.91C165.687 124.91 186.847 125.485 190.342 126.757C193.837 128.028 195.399 137.121 195.399 137.121L178.97 142.285L166.681 141.589L157.433 142.242L162.597 128.717L165.687 124.91Z"
          fill="#2F2E41"
        />
        <path
          d="M166.599 117.966C166.599 117.966 167.992 123.343 164.902 127.15C161.812 130.958 178.185 144.525 178.185 144.525L186.822 143.04C186.822 143.04 184.919 126.518 187.476 122.334C190.033 118.15 166.599 117.966 166.599 117.966Z"
          fill="#9F616A"
        />
        <path
          opacity="0.1"
          d="M166.599 117.966C166.599 117.966 167.992 123.343 164.902 127.15C161.812 130.958 178.185 144.525 178.185 144.525L186.822 143.04C186.822 143.04 184.919 126.518 187.476 122.334C190.033 118.15 166.599 117.966 166.599 117.966Z"
          fill="black"
        />
        <path
          d="M224.043 275.183L204.985 300.456L217.992 308.049L235.892 278.762L224.043 275.183Z"
          fill="#9F616A"
        />
        <path
          d="M210.676 300.882C210.676 300.882 203.658 292.727 203.97 294.546C204.283 296.364 198.977 311.785 203.914 313.277C208.85 314.769 251.186 318.725 251.768 313.945C252.351 309.164 239.139 305.818 239.139 305.818C239.139 305.818 231.29 298.275 229.549 299.042C227.809 299.809 210.676 300.882 210.676 300.882Z"
          fill="#2F2E41"
        />
        <path
          d="M219.768 299.319L234.99 325.253L246.483 318.597L231.133 297.365L219.768 299.319Z"
          fill="#9F616A"
        />
        <path
          d="M243.798 237.619L239.281 244.012C239.281 244.012 217.744 273.924 220.031 276.339C222.318 278.754 231.844 287.882 236.688 286.113C241.532 284.345 258.743 253.772 258.743 253.772L254.254 235.822L243.798 237.619Z"
          fill="#2F2E41"
        />
        <path
          d="M151.022 231.569C151.022 231.569 155.845 240.569 168.07 243.616C180.295 246.663 193.066 252.892 193.521 252.814C193.975 252.736 195.261 252.047 195.574 253.866C195.886 255.684 199.239 258.852 199.239 258.852C199.239 258.852 212.742 304.739 217.899 304.789C223.056 304.838 233.654 301.145 233.874 299.703C234.095 298.261 218.084 251.401 217.928 250.492L216.052 239.581C216.052 239.581 237.227 242.962 239.202 243.559C241.176 244.155 258.743 253.773 258.743 253.773C258.743 253.773 276.65 240.866 259.9 227.832C243.151 214.798 214.362 205.237 214.362 205.237L204.033 202.332L195.032 201.794L193.707 196.712L172.388 197.942L150.141 207.382L151.022 231.569Z"
          fill="#2F2E41"
        />
        <path
          d="M179.158 128.67C188.329 128.67 195.764 121.235 195.764 112.064C195.764 102.893 188.329 95.458 179.158 95.458C169.987 95.458 162.552 102.893 162.552 112.064C162.552 121.235 169.987 128.67 179.158 128.67Z"
          fill="#9F616A"
        />
        <path
          d="M164.134 125.409L166.132 125.065C166.132 125.065 169.739 138.955 180.898 141.249C180.898 141.249 186.404 134.653 186.169 133.54C185.934 132.427 190.53 126.488 192.974 129.813C195.417 133.137 192.377 191.696 194.43 192.747C196.483 193.799 200.887 194.914 197.342 198.799C193.798 202.685 179.719 207.913 176.601 206.109C173.483 204.304 167.111 189.018 164.355 183.875C161.599 178.733 157.188 161.237 157.188 161.237C157.188 161.237 151.555 142.08 155.021 137.74C158.487 133.4 164.134 125.409 164.134 125.409Z"
          fill="#D0CDE1"
        />
        <path
          d="M180.027 197.502C179.656 201.581 179.02 205.632 178.125 209.629C177.31 212.881 176.362 214.845 175.28 214.761C172.519 214.555 171.264 218.604 170.617 223.273C169.925 228.227 169.925 233.877 169.546 235.874C169.044 238.513 165.831 240.526 159.41 239.935C155.587 239.5 151.834 238.58 148.243 237.196C139.575 234.045 139.831 208.486 141.281 189.52C142.168 177.895 143.502 168.742 143.502 168.742C143.502 168.742 140.869 160.888 140.026 155.505C139.859 154.523 139.775 153.528 139.775 152.532C139.904 147.829 148.488 135.122 153.676 127.207C158.869 119.292 165.953 125.099 165.953 125.099L163.488 132.54C163.488 132.54 167.309 138.436 167.337 144.047C167.365 149.659 176.485 153.709 179.759 156.425C181.818 158.132 181.767 180.919 180.027 197.502Z"
          fill="#2F2E41"
        />
        <path
          d="M187.238 127.758C187.238 127.758 187.836 128.465 190.576 128.12C193.862 127.706 198.381 128.843 199.43 131.981C201.355 137.734 206.782 161.139 206.782 161.139C206.782 161.139 209.041 187.897 210.057 193.807C211.073 199.717 210.568 204.952 211.57 208.056C212.571 211.16 201.682 202.267 197.669 203.425C193.655 204.583 191.077 189.581 191.581 184.346C192.086 179.111 192.512 173.421 191.12 168.044C189.727 162.667 192.196 148.435 192.103 145.175C192.011 141.915 183.254 133.697 187.238 127.758Z"
          fill="#2F2E41"
        />
        <path
          d="M198.987 210.922C198.987 210.922 216.57 220.514 218.252 234.167C219.935 247.82 194.735 220.947 194.735 220.947L198.987 210.922Z"
          fill="#9F616A"
        />
        <path
          d="M209.025 201.239C209.025 201.239 214.413 228.395 222.805 226.952C231.198 225.51 218.117 199.676 218.117 199.676L209.025 201.239Z"
          fill="#9F616A"
        />
        <path
          d="M237.768 319.627C237.768 319.627 233.47 324.578 232.405 323.825C231.339 323.072 228.889 333.322 231.098 335.282C233.307 337.243 241.817 340.46 241.909 343.721C242.001 346.981 264.824 346.335 265.641 342.918C266.458 339.502 262.778 331.709 260.193 330.282C257.607 328.854 248.047 315.713 248.047 315.713C248.047 315.713 240.559 316.807 237.768 319.627Z"
          fill="#2F2E41"
        />
        <path
          opacity="0.1"
          d="M196.187 227.529C192.109 224.65 180.724 223.936 170.617 223.273C166.182 222.988 161.993 222.709 158.774 222.257C155.673 221.828 152.884 218.994 150.407 214.771C146.653 208.373 143.619 198.779 141.281 189.519C142.168 177.895 143.502 168.741 143.502 168.741C143.502 168.741 140.869 160.887 140.026 155.504C140.62 154.678 141.385 153.989 142.269 153.485C152.99 148.883 163.226 194.172 164.525 201.278C164.548 201.395 164.564 201.507 164.581 201.602C165.212 205.261 172.022 208.39 178.125 209.628C181.884 210.393 185.382 210.443 186.994 209.45C191.228 206.851 204.247 217.254 205.234 217.549C206.222 217.851 202.044 231.673 196.187 227.529Z"
          fill="black"
        />
        <path
          d="M142.271 150.699C142.271 150.699 134.478 154.379 137.902 171.575C141.326 188.772 148.223 218.009 158.772 219.473C169.32 220.936 190.324 220.602 196.184 224.743C202.045 228.884 206.221 215.061 205.234 214.763C204.247 214.465 191.226 204.066 186.993 206.665C182.759 209.265 165.598 204.726 164.582 198.816C163.566 192.906 153.167 146.018 142.271 150.699Z"
          fill="#2F2E41"
        />
        <path
          d="M198.422 142.45L206.986 162.322C206.986 162.322 207.848 161.892 208.849 164.996C209.851 168.1 210.32 170.828 212.074 172.866C213.829 174.905 221.635 203.986 221.87 205.349C222.104 206.713 211.088 211.415 208.626 207.626C206.165 203.836 198.422 142.45 198.422 142.45Z"
          fill="#2F2E41"
        />
        <path
          d="M169.71 111.832C170.347 111.662 170.44 110.817 170.461 110.159C170.576 106.633 172.699 103.016 176.073 101.989C177.368 101.634 178.724 101.559 180.05 101.77C181.943 102.022 183.771 102.632 185.436 103.567C186.315 104.061 187.177 104.656 188.174 104.807C188.833 104.907 191.684 105.736 192.339 105.853C193.781 106.109 195.121 107.399 196.504 106.919C197.827 106.46 198.12 104.746 198.152 103.346C198.225 100.156 195.803 95.7399 193.67 93.3665C192.051 91.5654 189.613 90.734 187.231 90.3017C184.447 89.8545 181.626 89.6829 178.809 89.7894C174.996 89.8574 171.077 90.1518 167.639 91.8017C164.2 93.4517 161.312 96.7355 161.147 100.546C161.113 101.34 161.194 102.137 161.128 102.928C160.965 104.86 159.939 106.604 159.458 108.481C159.114 109.859 159.063 111.293 159.307 112.692C159.552 114.091 160.087 115.422 160.877 116.602C162.154 118.48 164.159 120.178 164.085 122.448L166.853 119.581C167.266 119.265 167.558 118.818 167.681 118.312C167.804 117.807 167.75 117.275 167.528 116.805L166.549 112.984C166.297 112.302 166.267 111.558 166.464 110.858C167.514 108.264 168.649 112.115 169.71 111.832Z"
          fill="#2F2E41"
        />
      </g>
      <defs>
        <clipPath id="clip0_4_8833">
          <rect
            width={width}
            height={height}
            fill="white"
            transform="translate(0.0673828)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HomeSliderIcon;
