import React from "react";

export const HomeIcon = ({ color }) => {
  return (
    <svg
      width="25"
      height="28"
      viewBox="0 0 25 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 9.81531L12.334 1L23.6679 9.81531V23.6679C23.6679 24.3359 23.4026 24.9766 22.9302 25.4489C22.4579 25.9212 21.8173 26.1866 21.1493 26.1866H3.51866C2.85067 26.1866 2.21004 25.9212 1.7377 25.4489C1.26536 24.9766 1 24.3359 1 23.6679V9.81531Z"
        stroke={color}
        strokeWidth="1.67911"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.55615 26.1866V13.5933H16.1121V26.1866"
        stroke={color}
        strokeWidth="1.67911"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
