import React from "react";
import TaskCompletedIcon from "../../../../assets/icons/TaskCompletedIcon";
import TaskUncompledIcon from "../../../../assets/icons/TaskUncompletedIcon";
import BoxCom from "../../../UI/BoxCom/BoxCom";
import {
  ProfileButton,
  SubHeading,
  TaskIconAndTextWrapper,
  TaskTypography,
} from "./AllTodo.style";
import isEqual from "date-fns/isEqual";
import { format, compareAsc } from "date-fns";

const AllTodo = ({
  setCurrentPage,
  data,
  sendTaskId,
  calenderTime,
  selector,
  English,
  Polish,
}) => {
  const onClickHandler = (taskId) => {
    sendTaskId(taskId);
    setCurrentPage("singleTodo");
  };
  const setCalenderTime = (taskDate) => {
    // const date = new Date(calenderTime);
    // let day = date.getDate();
    // let month = date.getMonth() + 1;
    // let year = date.getFullYear();
    // let currentDate =
    //   month < 10 ? `${day}/0${month}/${year}` : `${day}/${month}/${year}`;
    let currentDate;
    if (calenderTime) {

      currentDate = calenderTime.toLocaleDateString("en-GB");
    } else {
      currentDate = undefined;
    }

    return currentDate === taskDate; // "17-6-2022"
  };

  return (
    <>
      {/* TO-DO */}
      <SubHeading>
        {selector === "English" ? ` ${English["220"]}` : Polish["220"]}
      </SubHeading>
      <BoxCom>
        {data?.map((item, index) => {
          const dates = [new Date(item?.Date)];
          const newDate = dates.sort(compareAsc);
          let actualDate = setCalenderTime(item?.Date);

          return (
            <React.Fragment key={index}>
              {calenderTime === "" ? (
                <TaskIconAndTextWrapper
                  onClick={() => onClickHandler(item?.id)}
                >
                  {item.completed ? (
                    <TaskCompletedIcon />
                  ) : (
                    <TaskUncompledIcon color="yellow" />
                  )}

                  <TaskTypography>{item.name}</TaskTypography>
                </TaskIconAndTextWrapper>
              ) : actualDate ? (
                <TaskIconAndTextWrapper
                  onClick={() => onClickHandler(item?.id)}
                >
                  {item.completed ? (
                    <TaskCompletedIcon />
                  ) : (
                    <TaskUncompledIcon color="yellow" />
                  )}

                  <TaskTypography>{item.name}</TaskTypography>
                </TaskIconAndTextWrapper>
              ) : null}

            </React.Fragment>
          );
        })}
      </BoxCom>

      <ProfileButton
        variant="contained"
        onClick={() => setCurrentPage("todoForm")}
      >
        {/* Add Task */}
        {selector === "English" ? ` ${English["80"]}` : Polish["80"]}
      </ProfileButton>
    </>
  );
};

export default AllTodo;
