import BoxCom from "../../../UI/BoxCom/BoxCom";
import HomeCoachcom from "../../Home/HomeCoachCom/HomeCoachCom";
import {
  BlogContainer,
  BlogsHeading,
  BlogsImage,
  BlogsText,
  PageTitle,
  PriceButton,
} from "./SingleBlog.style";
import { useParams } from "react-router-dom";
import { getServiceById } from "../../../../services/services";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { productsActions } from "./../../../../redux/reducers/products";
import { subscriptionActions } from "./../../../../redux/reducers/subscription";
import Loader from "./../../../UI/Loader/Loader";

import English from "../../../../config/Langugage/English.json";
import Polish from "../../../../config/Langugage/Polish.json";
const SingleBlog = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [blog, setBlog] = useState([]);
  const { auth } = useSelector((state) => state);
  const selector = auth.language;
  const currentUser = auth?.userData;
  const [purchasedAlready, setPurchasedAlready] = useState(false);
  const [showLoader, setShowLoader] = useState(true);

  let { blogId } = useParams();

  async function getData() {
    const docSnap = await getServiceById("Blog", blogId);
    if (docSnap.exists()) {
      setBlog(docSnap.data());
      const blogData = docSnap.data();
      if (currentUser?.PurchasedBlogs.includes(blogData?.id)) {
        setPurchasedAlready(true);
      } else {
        setPurchasedAlready(false);
      }
      setShowLoader(false);
      dispatch(
        subscriptionActions.productIdState({
          productId: blogData?.id,
          productName: "blog",
          // item: item,
        })
      );
    } else {
      console.log("blog is empty");
    }
  }
  const productHandler = (coachid, price) => {
    navigate(`/subscription/${price}`);
    dispatch(productsActions.addCouchId(coachid));
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <PageTitle>{selector === "English" ? ` ${English["218"]}` : Polish["218"]}</PageTitle>
      {showLoader ? (
        <Loader />
      ) : (
        <BlogContainer>
          <BlogsImage src={blog.FeatureImage} alt="Paris" />
          <BlogsHeading>{blog.Title}</BlogsHeading>
          <BoxCom sx={{ marginTop: "10px" }}>
            <HomeCoachcom
              image={blog.CoachImage}
              name={blog.CoachName}
              title={blog.Title}
            />
          </BoxCom>
          <BlogsText>
            <div>
              {blog.Price === 0 || blog.PriceType === "Evolove Subscription" || purchasedAlready
                ? blog?.Discription
                : `${blog?.Discription.slice(0, 70)}. . .    `}

              {blog?.BlogText?.map((blogDetail) => {
                return (
                  <>
                    <BlogsImage
                      key={blogDetail.id}
                      src={
                        blog.Price === 0 || purchasedAlready
                          ? blogDetail.image
                          : null
                      }
                      alt=""
                      sx={{ borderRadius: "6px" }}
                    />
                    <p>
                      {blog.Price === 0 || blog.PriceType === "Evolove Subscription" || purchasedAlready
                        ? blogDetail?.text
                        : `${blogDetail?.text.slice(0, 50)}. . .    `}
                    </p>
                  </>
                );
              })}

              <p></p>
            </div>
            {blog.Price === 0 || blog.PriceType === "Evolove Subscription" || purchasedAlready ? null : (
              <PriceButton
                purchasedAlready={purchasedAlready}
                onClick={() =>
                  !purchasedAlready
                    ? productHandler(blog.CoachId, blog.Price)
                    : null
                }
              >
                {purchasedAlready ? selector === "English" ? ` ${English["409"]}` : Polish["409"] : selector === "English" ? ` ${English["390"]}` : Polish["390"]}
              </PriceButton>
            )}
          </BlogsText>
        </BlogContainer>
      )}
    </>
  );
};

export default SingleBlog;
