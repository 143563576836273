import React from "react";

const CalendarIcon = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0433 3.99194H5.1436C4.12584 3.99194 3.30078 4.817 3.30078 5.83476V18.7345C3.30078 19.7523 4.12584 20.5773 5.1436 20.5773H18.0433C19.0611 20.5773 19.8861 19.7523 19.8861 18.7345V5.83476C19.8861 4.817 19.0611 3.99194 18.0433 3.99194Z"
        stroke="#B7C6D9"
        strokewidth="1.84282"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.2792 2.14917V5.83481"
        stroke="#B7C6D9"
        strokewidth="1.84282"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.90784 2.14917V5.83481"
        stroke="#B7C6D9"
        strokewidth="1.84282"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.30078 9.52026H19.8861"
        stroke="#B7C6D9"
        strokewidth="1.84282"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CalendarIcon;
