import { Grid, styled, Typography } from "@mui/material";
import { Colors } from "../../../../../config/palette";
import BoxCom from "../../../../UI/BoxCom/BoxCom";
import Button from "../../../../UI/Button/Button";

export const SubHeading = styled(Typography)(() => ({
  marginTop: "0px",
  fontFamily: '"Poppins", "sans-serif"',
  color: Colors.light,
  fontSize: "13px",
}));

export const SubHeading2 = styled(Typography)(() => ({
  marginTop: "40px",
  fontFamily: '"Poppins", "sans-serif"',
  color: Colors.light,
  fontSize: "16px",
}));

export const FieldWrapper1 = styled(BoxCom)(({ theme, height, border }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: Colors.dark,
  borderRadius: "15px",
  height: height,
  marginTop: "20px",
  cursor: "pointer",
  border: border && ` 1px solid ${Colors.info}  `,
}));

export const FieldLabel = styled(Typography)(() => ({
  fontFamily: '"Poppins", "sans-serif"',
  color: Colors.dimGrey,
  fontWeight: "bold",
  fontSize: "16px",
}));

export const ProfileButton = styled(Button)(() => ({
  width: "100%",
  marginTop: "50px",
  textTransform: "none",
  fontSize: "16px",
  marginLeft: "2px",
}));

export const FieldWrapper = styled(BoxCom)(({ theme, height, border }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  background: Colors.dark,
  borderRadius: "10px",
  height: height,
  marginTop: "10px",
  cursor: "pointer",
  paddingLeft: "20px",
  paddingRight: "20px",
  border: border && ` 1px solid ${Colors.info}  `,
}));

export const CardText = styled(Typography)(({ theme }) => ({
  fontFamily: '"Poppins", "sans-serif"',
  color: Colors.light,
  fontSize: "16px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "7px",
  },
}));

export const LeftSideGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));

export const RightSideGrid = styled(Grid)(({ theme, marginLeft }) => ({
  marginLeft: marginLeft,
  [theme.breakpoints.down("lg")]: {
    marginLeft: "0px",
  },
}));

export const Count = styled(BoxCom)(() => ({
  color: Colors.light,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "#2A2A3F",
  borderRadius: "50%",
  fontSize: "12px",
  height: "18px",
  width: "18px",
  marginLeft: "10px",
  padding: "2px",
}));

export const SubHeading3 = styled(Typography)(() => ({
  fontFamily: '"Poppins", "sans-serif"',
  color: Colors.light,
  fontSize: "13.5px",
  marginTop: "10px",
}));

export const SubHeading4 = styled(Typography)(() => ({
  fontFamily: '"Poppins", "sans-serif"',
  color: "#9C9C9C",
  fontSize: "10.4px",
}));

export const TimeTypography = styled(Typography)(() => ({
  fontFamily: '"Poppins", "sans-serif"',
  color: "#9C9C9C",
  fontSize: "10.4px",
}));

export const CricullumIntroWrapper = styled(BoxCom)(
  ({ theme, height, border }) => ({
    display: "flex",
    flexDirection: "column",
    background: Colors.dark,
    borderRadius: "10px",
    height: height,
    marginTop: "10px",
    cursor: "pointer",
    paddingLeft: "20px",
    paddingRight: "20px",
    border: border && ` 1px solid ${Colors.info}  `,
  })
);
export const AddModuleButton = styled(Button)(({}) => ({
  textTransform: "Capitalize",
  borderRadius: "10px",
  width: "45%",
  height: "40px",
  padding: "0px",
  margin: "0px",
}));

export const VideoList = styled("div")(({}) => ({
  marginTop: "10px",
  maxHeight: "360px",
  overflowY: "auto",
}));

export const List = styled("div")(({}) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));
