import { styled } from "@mui/material/styles";
import Select from "@mui/material/Select";
import { Colors } from "./../../../../config/palette";

export const MainSelect = styled("select")(({ theme }) => ({
  width: "130px",
  fontSize: "18px",
  fontWeight: "bold",
  fontFamily: '"Poppins", "sans-serif"',
  color: Colors.grey,
  background: "transparent",
  borderColor: Colors.grey,
  borderRadius: "4px",
}));
export const Option = styled("option")(({ theme }) => ({
  background: "black",
  "&: hover": {
    color: "red",
  },
}));
