import React, { useEffect, useState } from "react";
import {
  CardFooter,
  CourseImage,
  CourseNameContainer,
  IconContainer,
  NameContainer,
  FavoriteButton,
  PayButton,
  // ShareIconContainer,
} from "./HomeCourseCard.style";
import BoxCom from "../../../UI/BoxCom/BoxCom";
import { Grid, Typography } from "@mui/material";
import Button from "../../../UI/Button/Button";
import { Colors } from "../../../../config/palette";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { subscriptionActions } from "./../../../../redux/reducers/subscription.js";
import { getServiceById } from "./../../../../services/services.js";
import { CURRENCY } from "../../../../config/constant";

const HomeCourseCard = ({
  size,
  courseList,
  favoriteButtonHandler,
  showButton,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({});

  const selector = useSelector((state) => state.user.currentUser);
  const addFavorite = (courseId, item, index) => {
    favoriteButtonHandler(courseId, item, index);
  };
  let user1;
  const getUser = async () => {
    let uid = await JSON.parse(localStorage.getItem("userData"));
    uid = uid.uid;
    let user = await getServiceById("Users", uid);
    user1 = user.data();
    setUserData(user1);
  };

  useEffect(() => {
    getUser();
  }, []);

  const navigatHandler = (item) => {
    dispatch(
      subscriptionActions.productIdState({
        productId: item.id,
        productName: "course",
        item: item,
      })
    );
    navigate(`/subscription/${item.Price}`);

    // navigate("/subscription");
  };

  return (
    <>
      {courseList?.map((item, index) => {
        let isCheck = item.favoritesBy
          ? item?.favoritesBy?.some((el) => el.includes(selector.uid))
          : false;
        item.flag = isCheck;
        let isPucrchased;
        if (userData?.PurchasedCourse?.includes(item.id)) {
          isPucrchased = false;
        } else {
          isPucrchased = true;
        }
        return (
          <Grid
            item
            xs={12}
            sm={size?.sm}
            md={size?.md}
            lg={size?.lg}
            key={index}
          >
            <BoxCom sx={{ background: Colors.dark, borderRadius: "16px" }}>
              <Link to={`/home/${item.id}`} style={{ textDecoration: "none" }}>
                <CourseImage src={item.CoverImage} alt="Paris" />
              </Link>
              <IconContainer>
                {/* <ShareIconContainer>
            <ShareIcon />
          </ShareIconContainer> */}
                <FavoriteButton
                  showButton={showButton}
                  favcolor={item.flag}
                  aria-label="share"
                  onClick={() => addFavorite(item.id, item, index)}
                >
                  {!showButton ? <FavoriteIcon /> : null}
                </FavoriteButton>
              </IconContainer>
              <BoxCom>
                <NameContainer variant="body1">{item.CoachName}</NameContainer>
                <CourseNameContainer variant="body1">
                  {item.Title}
                </CourseNameContainer>
                <CardFooter>
                  <Typography
                    variant="body1"
                    sx={{ color: "#0AB27D", marginLeft: "12px" }}
                  >
                    {item.Price} {CURRENCY}
                  </Typography>
                  {!showButton ? (
                    <PayButton
                      variant="contained"
                      isPucrchased={isPucrchased}
                      size="small"
                      onClick={() =>
                        isPucrchased ? navigatHandler(item) : null
                      }
                    >
                      {isPucrchased ? "Pay now" : "Paid"}
                    </PayButton>
                  ) : null}
                </CardFooter>
              </BoxCom>
            </BoxCom>
          </Grid>
        );
      })}
    </>
  );
};

export default HomeCourseCard;
