import React, { useState, useEffect } from "react";
import { Grid, useMediaQuery } from "@mui/material";
import {
  Heading,
  MainContainer,
  SubHeading,
  SubHeadingParagraph,
  SubHeadingParagraphContainer,
} from "./Subscription.style ";
import SubscriptionCard from "../SubscriptionCard/SubscriptionCard";
import AddCard from "../AddCard/AddCard";
import { getServiceById } from "./../../../../services/services";
import { useSelector } from "react-redux";
import English from "../../../../config/Langugage/English.json";
import Polish from "../../../../config/Langugage/Polish.json";
const Subscription = () => {
  const [showForm, setShowForm] = useState(false);
  const [bankDetailsData, setBankDetailsData] = useState({});
  const [bankDetail, setBankDetail] = useState({});
  const store = useSelector((state) => state);
  const { auth, user: _user } = store;
  const selector = auth.language;

  const small = useMediaQuery("(max-width:390px)");

  const getCurrentUser = async () => {
    const uid = await JSON.parse(localStorage.getItem("userData"));
    let user = await getServiceById("Users", uid.uid);
    const _data = user.data().BankDetails;

    setBankDetail(_data);
  };
  useEffect(() => {
    getCurrentUser();
  }, []);
  const bankDetails = (bankDetailsValue) => {
    setBankDetailsData(bankDetailsValue);
  };

  return (
    <>
      {showForm ? (
        <AddCard
          setShowForm={setShowForm}
          bankDetails={bankDetails}
          previousBankDetails={bankDetail}
        />
      ) : (
        <>
          <Heading small={small}>{selector === "English" ? ` ${English["383"]}` : Polish["383"]}</Heading>
          <SubHeading small={small}>
          {selector === "English" ? ` ${English["384"]}` : Polish["384"]}          
          </SubHeading>
          <SubHeadingParagraphContainer small={small}>
            <SubHeadingParagraph small={small}>
            Będąc subskrybentem dostaniesz dostęp do wyjątkowych materiałów i wesprzesz nas w rozwoju platformy. Ponadto dostaniesz informacje o promocjach i wydarzeniach nieogłaszanych nigdzie indziej. 
            </SubHeadingParagraph>
          </SubHeadingParagraphContainer>
          <MainContainer small={small}>
            <Grid container>
              <SubscriptionCard
                setShowForm={setShowForm}
                bankDetailsData={bankDetailsData}
              />
            </Grid>
          </MainContainer>
        </>
      )}
    </>
  );
};

export default Subscription;
