import React from "react";
import dayjs, { Dayjs } from "dayjs";
// import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { TimePicker } from "@mui/x-date-pickers/TimePicker";
// import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { styled } from "@mui/system";
import PropTypes from "prop-types";
import { Colors } from "./../../../config/palette";

export const SearchField = styled(TextField)(({ theme }) => ({
  // borderRadius: "12px",
  backgroundColor: Colors.dark,

  "& label.Mui-focused": {
    borderColor: "white",
  },
  "& .MuiInput-underline:after": {
    borderColor: "white",
  },
  "& .MuiOutlinedInput-root": {
    color: "white",
    borderColor: "white",
    "& fieldset": {
      borderColor: "white",
      borderRadius: "12px",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
}));

const Date = ({ dateHandler, currentDate }) => {
  // const [value, setValue] = React.useState(dayjs("2014-08-18T21:11:54"));

  const handleChange = (newValue: Dayjs | null) => {
    // setValue(newValue);
    dateHandler(newValue);
  };
  const color = "white";
  return (
    <div style={{}}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          inputFormat="MM/DD/YYYY"
          value={currentDate}
          onChange={handleChange}
          renderInput={(params) => (
            <SearchField
              fullWidth
              sx={{
                svg: { color },
              }}
              {...params}
            />
          )}
        />
      </LocalizationProvider>
    </div>
  );
};

export default Date;
Date.propTypes = {
  dateHandler: PropTypes.func,
};

Date.defaultProps = {
  dateHandler: (e) => {
    console.log("default time", e);
  },
};
