export const Colors = {
  primary: "#5f2c3e",
  secondary: "#131315",
  success: "#4CAF50",
  info: "#E63369",
  grey: "#DADAF7",
  darkGrey: "#8D98AF",
  dimGrey: "#B7C6D9",
  danger: "#FF5722",
  warning: "#FFC107",
  dark: "#1A1A1C",
  light: "#FFFFFF",
  muted: "#abafb3",
  border: "#DDDFE1",
  inverse: "#2F3D4A",
  shaft: "#333",
  pink: "#E63369",

  ///////////////
  // Grays
  ///////////////
  dim_grey: "#696969",
  dove_gray: "#d5d5d5",
  body_bg: "#f3f6f9",
  light_gray: "rgb(230,230,230)",
  ///////////////
  // Solid Color
  ///////////////
  white: "#fff",
  black: "#000",
};
