import React from "react";

export const ClockIcon = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00304 16.7717C13.1466 16.7717 16.5056 13.4127 16.5056 9.26915C16.5056 5.12561 13.1466 1.7666 9.00304 1.7666C4.85949 1.7666 1.50049 5.12561 1.50049 9.26915C1.50049 13.4127 4.85949 16.7717 9.00304 16.7717Z"
        stroke="#6978A0"
        strokeWidth="1.50051"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.00293 4.76807V9.2696L12.0039 10.7701"
        stroke="#6978A0"
        strokeWidth="1.50051"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
