import React, { useState } from "react";
import {
  AppBar,
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DrawerComp from "../NavBarDrawer/Drawer";
import { useLocation } from "react-router";
import { Colors } from "../../../../config/palette";
import NavBarLinks from "../NavBarLinks/NavBarLinks";
import { EvoloveIcon } from "../../../../assets/icons/EvoloveIcon";
import { useNavigate } from "react-router-dom";
import English from "./../../../../config/Langugage/English.json";
import Polish from "./../../../../config/Langugage/Polish.json";
import { MainSelect, Option } from "./Navbar.style";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "./../../../../redux/reducers/auth";
import { Link } from "react-router-dom";

const NavBar = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  // const [selectLanguage, setSelectLanguage] = useState("English");

  const handleChange = (event) => {
    localStorage.setItem("language", event.target.value);
    dispatch(authActions.languageHandler(event.target.value));
  };
  const selector = useSelector((state) => state.auth.language);

  return (
    <AppBar
      sx={{ background: "#1A1A1C", position: "sticky", boxShadow: "none" }}
    >
      <Box>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ ml: "100px", mt: "10px" }}>
            <Link to="/">
              <EvoloveIcon />
            </Link>
          </Box>
          {isMatch ? (
            <>
              <DrawerComp
                anchor="left"
                type="temporary"
                openDrawer={openDrawer}
                setOpenDrawer={setOpenDrawer}
              >
                <NavBarLinks />
              </DrawerComp>
            </>
          ) : (
            <List
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingRight: "100px",
              }}
            >
              <ListItemButton onClick={() => navigate("/")}>
                <ListItemIcon
                  sx={{
                    color: pathname === "/" ? "#E63369" : Colors.grey,
                    fontSize: "36px",
                  }}
                >
                  <ListItemText
                    sx={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      fontFamily: '"Poppins", "sans-serif"',
                    }}
                    primary={
                      selector === "English" ? ` ${English["217"]}` : "Strona Główna"
                    }
                    disableTypography
                  />
                </ListItemIcon>
              </ListItemButton>
              <ListItemButton onClick={() => navigate("/aboutUs")}>
                <ListItemIcon
                  sx={{
                    color: pathname === "/aboutUs" ? "#E63369" : Colors.grey,
                    fontSize: "36px",
                  }}
                >
                  <ListItemText
                    sx={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      fontFamily: '"Poppins", "sans-serif"',
                    }}
                    primary={
                      selector === "English"
                        ? ` ${English["325"]}`
                        : Polish["325"]
                    }
                    disableTypography
                  />
                </ListItemIcon>
              </ListItemButton>
              <ListItemButton onClick={() => navigate("/contactUs")}>
                <ListItemIcon
                  sx={{
                    color: pathname === "/contactUs" ? "#E63369" : Colors.grey,
                    fontSize: "36px",
                  }}
                >
                  <ListItemText
                    sx={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      fontFamily: '"Poppins", "sans-serif"',
                    }}
                    primary={
                      selector === "English"
                        ? ` ${English["326"]}`
                        : Polish["326"]
                    }
                    disableTypography
                  />
                </ListItemIcon>
              </ListItemButton>
              <ListItemButton onClick={() => navigate("/login")}>
                <ListItemIcon
                  sx={{
                    color: pathname === "/login" ? "#E63369" : Colors.grey,
                    fontSize: "36px",
                  }}
                >
                  <ListItemText
                    sx={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      fontFamily: '"Poppins", "sans-serif"',
                    }}
                    primary={
                      selector === "English" ? ` ${English["3"]}` : Polish["3"]
                    }
                    disableTypography
                  />
                </ListItemIcon>
              </ListItemButton>
              <ListItemButton onClick={() => navigate("/signUp")}>
                <ListItemIcon
                  sx={{
                    color: pathname === "/signUp" ? "#E63369" : Colors.grey,
                    fontSize: "36px",
                  }}
                >
                  <ListItemText
                    sx={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      fontFamily: '"Poppins", "sans-serif"',
                    }}
                    primary={
                      selector === "English"
                        ? ` ${English["327"]}`
                        : Polish["327"]
                    }
                    disableTypography
                  />
                </ListItemIcon>
              </ListItemButton>
              <MainSelect
                defaultValue={"DEFAULT"}
                // autofocus
                // value={language}
                name="Language"
                onChange={handleChange}
              >
                <Option value="DEFAULT" selected disabled hidden>
                język
                </Option>
                <Option value="Polish">Polish</Option>
                <Option value="English">English</Option>
              </MainSelect>

              {/* <SelectLanguage clickHandler={clickHandler} /> */}
            </List>
          )}
        </Toolbar>
      </Box>
    </AppBar>
  );
};

export default NavBar;
