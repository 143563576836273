import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { Colors } from "../../../../../../config/palette";
import {
  HomeAboutUs,
  // HomeAboutUsHeading,
  ProductAddToCart,
  ProductAddToCartSecond,
  ProductImage,
} from "./AboutUsHomeMain.style";
import AboutUsImage from "../../../../../../assets/images/homeOnBoarding/mackbok.png";
import { useSelector } from "react-redux";
import English from "./../../../../../../config/Langugage/English.json";
import Polish from "./../../../../../../config/Langugage/Polish.json";
import { Link } from "react-router-dom";

const AboutUsHome = ({ aboutUsData }) => {

  const selector = useSelector((state) => state.auth.language);


  function openInNewTab(url) {
    var win = window.open(url, '_blank');
    win.focus();
  }
  return (
    <Paper elevation={0} square={true} style={{ background: Colors.secondary }}>
      <Typography
        sx={{
          color: Colors.light,
          textAlign: "center",
          paddingRight: "5px",
          paddingTop: "50px",
          fontSize: "32px",
          fontWeight: "bold",
          fontFamily: '"Poppins", "sans-serif"',
        }}
      >
        {selector === "English" ? ` ${English["325"]}` : Polish["325"]}
      </Typography>
      <Grid
        container
        spacing={1}
        sx={{ paddingTop: "50px", paddingBottom: "50px" }}
      >
        <Grid
          item
          xs={12}
          md={5.2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ProductImage src={AboutUsImage} />
        </Grid>

        <Grid item sm={12} md={6.8}>

          <HomeAboutUs
            variant="body2"
            sx={{
              paddingRight: "100px",
              paddingTop: "20px",
              paddingBottom: "20px",
              fontSize: "18px",
            }}
          >
            {/* {aboutUsData.length < 500 ? aboutUsData : `${aboutUsData.slice(0, 500)}...`} */}
            {selector === "English" ? ` ${English["414"]}` : Polish["414"]}
          </HomeAboutUs>
          <div
            style={{
              display: "flex",
              justifyContent: "space-even",
              marginTop: "10%",
            }}
          >
            {/* Read More and Download App */}
            <Link to="/aboutUs" style={{ textDecoration: "none" }}>
              <ProductAddToCart>
                {selector === "English" ? ` ${English["333"]}` : Polish["333"]}
              </ProductAddToCart>
            </Link>
            <ProductAddToCart sx={{ marginLeft: "20px" }} onClick={() => openInNewTab(` https://apps.apple.com/us/app/evolove/id1617855211`)}>
              {selector === "English" ? ` ${English["334"]}` : Polish["334"]}
            </ProductAddToCart>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{ display: "flex", justifyContent: "center", padding: "20px" }}
        >
          <ProductAddToCartSecond>Read More</ProductAddToCartSecond>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{ display: "flex", justifyContent: "center", padding: "20px" }}
        >
          <ProductAddToCartSecond>Download App</ProductAddToCartSecond>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AboutUsHome;
