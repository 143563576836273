import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  imagePostService,
  postServiceByCoustomId,
} from "../../../../services/services";
import { BlogSubmitForm } from "../BlogSubmission/BlogSubmiForm/BlogSubmitForm";
import UploadBlogForm from "../BlogSubmission/UploadBlogForm/UploadBlogForm";
import CourseVideoAndImageUpload from "../CourseSubmission/CourseVideoAndImageUpload/CourseVideoAndImageUpload";
import CreateCourseMoudle from "../CourseSubmission/CreateCourseModule/CreateCourseModule";
import NewSubmissionCategoryForm from "../NewSubmissionCategoryForm/NewSubmissionCategoryForm";
import ProductSubmitForm from "../ProductSubmission/ProductSubmitForm";
import { Heading, MainContainer } from "./NewSubmission.style";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

const NewSubmission = () => {
  const [newSubmissionState, setNewSubmissionState] = useState({
    selectedCategory: "Course",
    step: 1,
    title: "",
    category: "",
    description: "",
    image: "",
    previewImage: "",
    featureImage: "",
    previewFeatureImage: "",
    video: "",

    previewVideo: "",
    price: "",
    priceCategory: "Fixed Price",
    loading: false,
  });

  const { enqueueSnackbar } = useSnackbar();

  let navigate = useNavigate();
  const selector = useSelector((state) => state.auth.userData);

  const {
    selectedCategory,
    step,
    title,
    category,
    description,
    image,
    featureImage,
    video,
    priceCategory,
    Module,
  } = newSubmissionState;

  async function submitApiCall(payload) {
    setNewSubmissionState({ ...newSubmissionState, loading: true });
    if (selectedCategory === "Course") {
      const imageUrl = await imagePostService(image);
      const videoUrl = await imagePostService(video);
      const uuid = uuidv4();
      const Price = payload.price;
      let _moduleList = [];
      Module?.forEach((item) => {
        const listArray = item.list?.map((item) => {
          return {
            Title: item.details.name,
            Video: item.videoUrl,
            duration: "0.56",
          };
        });

        _moduleList.push({ ModuleName: item.name, ModuleVideoArr: listArray });
      });
      const data = {
        CoverImage: imageUrl,
        Video: videoUrl,
        Price: Number(Price),
        Title: title,
        Modules: _moduleList,
        Discription: description,
        PriceType: priceCategory,
        Category: category,
        id: uuid,
        Status: "Active",
        CoachId: selector?.id,
        CoachImage: selector?.ProfileImage,
        Type: "Course",
        CoachName: selector?.FullName,
        introImage: "",
        isIntroImage: false,
      };
      await postServiceByCoustomId("Courses", data, uuid);
    } else if (selectedCategory === "Product") {
      const imageUrl = await imagePostService(payload.image);
      const uuid = uuidv4();
      const Price = payload.price;
      const data = {
        Image: imageUrl,
        Price: Number(Price),
        Title: title,
        Discription: description,
        pricetype: priceCategory,
        Category: category,
        CoachId: selector?.id,
        CoachImage: selector?.ProfileImage,
        Type: "Product",
        CoachName: selector?.FullName,
        id: uuid,
        Details: [
          {
            title: "Description",
            content: description,
            flag: true,
            icon: "hair-cross",
            publisherImage: selector.ProfileImage,
            publisherName: selector.FullName,
            designation: selector.Coach ? "Coach" : "User",
            reviewFlag: false,
            descriptionFlag: true,
            aboutFlag: false,
          },
          {
            title: "About Publisher",
            content: selector.About,
            flag: true,
            icon: "hair-cross",
            publisherImage: selector.ProfileImage,
            publisherName: selector.FullName,
            designation: selector.Coach ? "Coach" : "User",
            reviewFlag: false,
            descriptionFlag: false,
            aboutFlag: true,
          },
        ],
      };
      await postServiceByCoustomId("Product", data, uuid);
    } else {
      const imageUrl = await imagePostService(image);
      const featureImageUrl = await imagePostService(featureImage);
      const uuid = uuidv4();
      const Price = payload.price;
      const data = {
        FeatureImage: featureImageUrl,
        Price: Number(Price),
        Title: title,
        Discription: description,
        PriceType: priceCategory,
        Category: category,
        CoachId: selector?.id,
        CoachImage: selector?.ProfileImage,
        Type: "Blog",
        CoachName: selector?.FullName,
        BlogText: [{ image: imageUrl, imageflag: "", loading: "", text: "" }],
        id: uuid,
        Status: "InActive",
        desigination: selector.Coach ? "Coach" : "User",
        // date: moment().format('DD-MM-YYYY'),
      };
      await postServiceByCoustomId("Blog", data, uuid);
    }
    setNewSubmissionState({ ...newSubmissionState, loading: false });
    enqueueSnackbar("successfully created", {
      variant: "success",
      autoHideDuration: 4000,
    });

    navigate("/newSubmissionSuccess");
  }

  const propsForAll = {
    newSubmissionState,
    setNewSubmissionState,
    submitApiCall,
  };

  function renderComponents() {
    if (step === 1) {
      return <NewSubmissionCategoryForm {...propsForAll} />;
    } else if (step === 2 && selectedCategory === "Product") {
      return <ProductSubmitForm {...propsForAll} />;
    } else if (step === 2 && selectedCategory === "Course") {
      return <CourseVideoAndImageUpload {...propsForAll} />;
    } else if (step === 2 && selectedCategory === "Blog") {
      return <UploadBlogForm {...propsForAll} />;
    } else if (step === 3 && selectedCategory === "Blog") {
      return <BlogSubmitForm {...propsForAll} />;
    } else if (step === 3 && selectedCategory === "Course") {
      return <CreateCourseMoudle {...propsForAll} />;
    } else if (step === 4 && selectedCategory === "Course") {
      return <BlogSubmitForm {...propsForAll} />;
    } else {
      return <></>;
    }
  }

  return (
    <MainContainer>
      <Heading>New Submission</Heading>
      {renderComponents()}
    </MainContainer>
  );
};

export default NewSubmission;
