import React, { useState, useEffect } from "react";
import BoxCom from "../../../UI/BoxCom/BoxCom";
import TextfieldComp from "../../../UI/TextFieldCom/Textfield";
import Time from "./../../../UI/Time/Time.js";
import Date from "./../../../UI/Date/Date.js";
import dayjs, { Dayjs } from "dayjs";
import { v4 as uuidv4 } from "uuid";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  postServiceByCoustomId,
  updateService,
} from "./../../../../services/services.js";
import { FieldLabel, ProfileButton, SubHeading } from "./TodoForm.style";
import { useSnackbar } from "notistack";

const TodoForm = ({
  setCurrentPage,
  singleTaskData,
  identifier,
  selector,
  English,
  Polish,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [date, setDate] = useState(dayjs());
  const [time, setTime] = useState(dayjs());
  // console.log("singleTaskData", singleTaskData);
  const validationSchema = yup.object({
    _taskName: yup.string().required("Task name required"),
    _taskDetails: yup.string().required("Task details required"),
  });
  const formik = useFormik({
    initialValues: {
      _taskName: identifier === "update" ? singleTaskData.TaskName : "",
      _taskDetails: identifier === "update" ? singleTaskData.description : "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (identifier === "update") {
        updateData(values);
      } else {
        addData(values);
      }
    },
  });

  const updateData = async (values) => {

    const fullDate = date?.$d;
    const updateDate = dayjs(date).format("DD/MM/YYYY");
    const updateTime = dayjs(time).format("hh:mm A");
    const { _taskName: taskName, _taskDetails: taskDetails } = values;

    let payload = {
      ...singleTaskData,
      Date: updateDate,
      Details: taskDetails,
      EndTime: updateTime,
      StartTime: updateTime,
      addedDate: fullDate,
      description: taskDetails,
      name: taskName,
      TaskName: taskName,
      time: updateTime,
      title: taskName,
    };
    console.log('update payload', payload)

    await updateService("Tasks", singleTaskData.id, payload)
      .then(() => {

        // Task updated
        enqueueSnackbar(selector === "English" ? English["352"] : Polish["352"], {
          variant: "success",
          autoHideDuration: 4000,
        });
        setCurrentPage("allTodo");
      })
      .catch((error) => {
        alert('Please select data and time')
      })
  };

  const addData = async (values) => {
    console.log('add call')

    const uid = await JSON.parse(localStorage.getItem("userData"));
    const fullDate = date?.$d;
    const newDate = dayjs(date).format("DD/MM/YYYY");
    const newTime = dayjs(time).format("hh:mm A");
    const uuid = uuidv4();
    const { _taskName: taskName, _taskDetails: taskDetails } = values;

    let payload = {
      Date: newDate,
      Details: taskDetails,
      EndTime: newTime,
      StartTime: newTime,
      TaskName: taskName,
      UserId: uid.uid,
      addedDate: fullDate,
      completed: false,
      description: taskDetails,
      id: uuid,
      name: taskName,
      time: newTime,
      title: taskName,
    };
    console.log("new payload", payload);
    await postServiceByCoustomId("Tasks", payload, uuid);
    enqueueSnackbar(selector === "English" ? English["353"] : Polish["353"], {
      variant: "success",
      autoHideDuration: 4000,
    });
    setCurrentPage("allTodo");
  };

  const timeHandler = (newValue) => {
    setTime(newValue);
  };
  const dateHandler = (currentDateValue) => {
    setDate(currentDateValue);
  };

  useEffect(() => {
    if (identifier === "update") {
      setDate(singleTaskData.Date);
      setTime(singleTaskData.StartTime);
    } else {
      console.log("not this time");
    }
  }, []);

  return (
    <>
      {/* TO-DO */}
      <SubHeading>
        {selector === "English" ? English["220"] : Polish["220"]}
      </SubHeading>
      <form onSubmit={formik.handleSubmit}>
        <BoxCom sx={{ marginTop: "20px" }}>
          {/* Date */}
          <FieldLabel>
            {selector === "English" ? English["81"] : Polish["81"]}
          </FieldLabel>

          <Date dateHandler={dateHandler} currentDate={date} />

          {/* <TextfieldComp
          height="45px"
          width="100%"
          autoComplete="false"
          onChange={(e) => setDate(e.target.value)}
          name="date"
          value={date}
          justifyproperty="flex-start"
          alignproperty="null"
          icon={true}
          iconPosition="end"
          iconType="calendar"
          calenderHandler={calenderHandler}
        /> */}
        </BoxCom>

        <BoxCom sx={{ marginTop: "40px" }}>
          <BoxCom
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {/* Time */}
            <FieldLabel>
              {selector === "English" ? English["82"] : Polish["82"]}
            </FieldLabel>
          </BoxCom>
          <Time timeHandler={timeHandler} currentTime={time} />
        </BoxCom>
        <BoxCom sx={{ marginTop: "40px" }}>
          <BoxCom
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {/* Task Name */}
            <FieldLabel>
              {selector === "English" ? English["83"] : Polish["83"]}
            </FieldLabel>
          </BoxCom>
          <TextfieldComp
            autoComplete="false"
            onChange={formik.handleChange}
            error={formik.touched._taskName && Boolean(formik.errors._taskName)}
            helperText={formik.touched._taskName && formik.errors._taskName}
            name="_taskName"
            id="_taskName"
            value={formik.values._taskName}
            selector={selector}
            English={English}
            Polish={Polish}
          />
        </BoxCom>

        <BoxCom sx={{ marginTop: "40px" }}>
          <BoxCom
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {/* Task Details */}
            <FieldLabel>
              {selector === "English" ? English["84"] : Polish["84"]}
            </FieldLabel>
          </BoxCom>
          <TextfieldComp
            width="100%"
            autoComplete="true"
            multiLine={true}
            height="220px"
            onChange={formik.handleChange}
            error={
              formik.touched._taskDetails && Boolean(formik.errors._taskDetails)
            }
            helperText={
              formik.touched._taskDetails && formik.errors._taskDetails
            }
            name="_taskDetails"
            id="_taskDetails"
            value={formik.values._taskDetails}
          />
        </BoxCom>
        <ProfileButton variant="contained" type="submit">
          {/* Update Task */}
          {identifier === "update"
            ? selector === "English"
              ? English["351"]
              : Polish["351"]
            : selector === "English"
              ? English["80"]
              : Polish["80"]}
        </ProfileButton>
      </form>
    </>
  );
};

export default TodoForm;
