import React from 'react'

const CheckEmailIcon = () => {
  return (
    <svg width="349" height="213" viewBox="0 0 349 213" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M348.293 211.881H0.969788V212.453H348.293V211.881Z" fill="#DADAF7"/>
    <path d="M166.393 152.43V212.111H67.1171V111.347L166.393 152.43Z" fill="#EAEAFF"/>
    <path d="M67.1171 131.889V177.665L139.557 189.28L166.393 152.431L74.2696 114.322L67.1171 131.889Z" fill="#DADAF7"/>
    <path d="M39.4795 145.049L139.557 189.28L166.393 152.43L66.8877 111.347L39.4795 145.049Z" fill="#F4F3FD"/>
    <path d="M72.2092 115.237L48.7492 133.548L45.8882 146.136L46.4604 148.425C51.1524 147.967 56.7599 145.163 59.6209 143.275C67.5744 137.953 96.6992 139.842 103.68 138.125C108.258 136.981 114.437 135.55 115.753 131.545L72.2092 115.237Z" fill="#DADAF7"/>
    <path d="M166.393 152.43V212.111H265.727V111.347L166.393 152.43Z" fill="#DADAF7"/>
    <path d="M265.727 131.889V177.665L193.287 189.28L166.393 152.431L258.574 114.322L265.727 131.889Z" fill="#D0D0EF"/>
    <path d="M66.8881 111.346L166.393 81.3057L265.955 111.346L166.393 151.915L66.8881 111.346Z" fill="#EAEAFF"/>
    <path d="M293.364 145.049L193.287 189.28L166.393 152.43L265.955 111.347L293.364 145.049Z" fill="#F4F3FD"/>
    <path d="M202.669 144.418C208.964 146.707 214.686 143.273 214.686 143.273C214.686 143.273 220.465 152.6 225.557 151.856C233.568 150.712 230.993 144.704 230.993 144.704C230.993 144.704 235.571 148.709 239.29 147.279C243.009 145.848 242.723 143.273 242.723 143.273C242.723 143.273 248.731 144.704 250.162 142.129C251.592 139.554 239.919 122.331 239.919 122.331L236.086 123.475L197.405 139.44C198.206 141.328 199.751 143.388 202.669 144.418Z" fill="#DADAF7"/>
    <path d="M227.16 90.6895L241.751 126.623L207.648 140.642L197.291 98.5857L227.16 90.6895Z" fill="#F9D2DD"/>
    <path d="M48.1768 135.149C48.1768 135.149 41.8254 143.046 44.9153 147.337C48.749 152.544 56.7597 139.441 56.7597 139.441C56.7597 139.441 97.3285 141.901 115.009 129.084C126.396 120.844 129.028 110.201 129.028 110.201L97.9579 101.676C97.9579 101.676 97.3285 111.403 65.1139 114.493C41.711 116.724 38.1634 127.31 37.6484 135.206C37.3051 140.185 39.4794 144.934 39.4794 144.934C39.4794 144.934 46.8035 136.351 48.1768 135.149Z" fill="#F9D2DD"/>
    <path d="M227.16 90.6895L197.291 98.5857L199.122 115.637L231.737 101.676L227.16 90.6895Z" fill="#F9ACC0"/>
    <path d="M200.953 105.28L232.023 96.7547C232.023 96.7547 210.337 54.6982 178.122 54.6982C145.907 54.6982 131.087 56.5294 118.442 68.0877C105.739 79.6461 96.7556 101.618 96.7556 101.618L129.199 110.144L125.651 135.321L166.22 152.201L207.018 135.149L200.953 105.28Z" fill="#E63369"/>
    <path d="M208.278 140.643L241.808 126.624L239.348 120.559C239.348 120.559 231.738 119.586 221.095 123.019C217.147 124.278 206.447 132.175 206.447 132.175L208.278 140.643Z" fill="#F9ACC0"/>
    <path d="M201.068 106.024C200.953 106.024 200.782 105.91 200.782 105.796L196.719 85.5399C196.662 85.3682 196.776 85.1965 196.948 85.1965C197.12 85.1393 197.291 85.2537 197.291 85.4254L201.354 105.681C201.411 105.853 201.297 106.024 201.125 106.024C201.125 106.024 201.068 106.024 201.068 106.024Z" fill="#DADAF7"/>
    <path d="M128.914 111.117H128.857C128.685 111.06 128.571 110.945 128.628 110.774L134.064 84.7388C134.121 84.5672 134.292 84.4528 134.407 84.51C134.579 84.5672 134.693 84.6816 134.636 84.8532L129.2 110.888C129.2 111.003 129.086 111.117 128.914 111.117Z" fill="#DADAF7"/>
    <path d="M122.735 121.76C127.713 115.638 129.029 110.145 129.029 110.145L97.9586 101.619C97.9586 101.619 97.8441 103.107 95.7842 105.11L122.735 121.76Z" fill="#F9ACC0"/>
    <path d="M242.893 75.1843C253.365 75.1843 269.272 75.1843 276.195 75.1843C292.846 75.1843 299.255 57.7323 285.923 49.7215C277.511 44.629 270.302 50.9803 270.302 50.9803C270.302 50.9803 270.302 36.0461 252.735 36.3322C235.169 36.6183 237.172 55.3862 237.172 55.3862C237.172 55.3862 228.074 55.6724 226.929 63.7976C225.728 72.7811 237.057 75.1843 242.893 75.1843Z" fill="#EAEAFF"/>
    <path d="M174.916 36.5611C174.916 36.5611 186.875 33.3567 186.36 18.7657C185.96 8.35177 182.527 -2.06215 165.647 0.512737C154.031 2.28655 143.331 4.91868 139.097 15.2754C136.121 22.5423 139.04 36.9616 145.963 42.6836C157.064 51.8387 160.726 46.3457 160.726 46.3457L174.916 36.5611Z" fill="#1F1F39"/>
    <path d="M182.069 21.2256C182.069 21.2256 172.342 26.0893 162.557 27.2909C160.555 27.5198 158.781 27.7486 157.236 27.9203C158.609 33.2417 155.233 38.9065 155.233 38.9065C155.233 38.9065 155.233 37.7049 155.233 34.0429C155.233 31.8685 154.547 29.6369 154.032 28.2636C150.255 28.6069 148.539 28.607 148.539 28.607C148.539 28.607 143.046 27.9776 141.844 33.4707C140.642 38.9638 146.135 40.7947 149.168 40.7947C150.942 40.7947 151.457 40.5659 151.629 40.4514V57.9034C151.629 57.9034 152.258 62.1949 163.187 62.1949C172.342 62.1949 174.173 57.9034 174.173 57.9034V45.0861C174.173 45.0861 180.238 42.0536 181.497 37.1899C182.756 32.3263 182.069 21.2256 182.069 21.2256Z" fill="#F9D2DD"/>
    <path d="M156.435 46.8027C160.154 52.1813 167.879 54.184 174.116 54.9851V44.9717C166.791 48.6337 156.435 46.8027 156.435 46.8027Z" fill="#F9ACC0"/>
    <path d="M213.483 129.256C213.483 129.256 196.489 134.578 201.868 141.101C207.304 147.624 218.29 139.556 218.29 139.556C218.29 139.556 221.609 144.534 222.009 146.937C222.41 149.34 225.328 151.915 229.905 149.913C234.483 147.91 234.082 145.507 234.082 145.507C234.082 145.507 238.317 148.482 241.578 146.079C244.84 143.676 244.496 141.272 244.496 141.272C244.496 141.272 248.216 144.992 251.42 141.959C254.624 138.926 249.703 128.055 246.098 124.965C242.494 121.875 239.289 121.188 232.08 122.39C224.87 123.649 213.483 129.256 213.483 129.256Z" fill="#F9D2DD"/>
    <path d="M233.853 145.793C233.738 145.793 233.624 145.735 233.566 145.621L229.275 134.749C229.218 134.578 229.275 134.406 229.447 134.349C229.618 134.291 229.79 134.349 229.847 134.52L234.139 145.392C234.196 145.564 234.139 145.735 233.967 145.793C233.91 145.793 233.853 145.793 233.853 145.793Z" fill="white"/>
    <path d="M244.783 141.558C244.669 141.558 244.554 141.501 244.497 141.386L240.205 130.515C240.148 130.343 240.205 130.171 240.377 130.114C240.549 130.057 240.72 130.114 240.778 130.286L245.069 141.158C245.126 141.329 245.069 141.501 244.897 141.558C244.897 141.558 244.84 141.558 244.783 141.558Z" fill="white"/>
    <path d="M38.2772 142.188C38.2199 142.188 38.2199 142.188 38.2772 142.188C38.0483 142.188 37.9338 142.016 37.9911 141.844C37.9911 141.615 38.6206 136.866 41.081 133.776C43.5414 130.686 45.9446 129.485 46.0591 129.428C46.2307 129.371 46.4024 129.428 46.4596 129.542C46.5168 129.714 46.4596 129.885 46.3452 129.943C46.3452 129.943 43.9419 131.202 41.5959 134.12C39.2499 137.038 38.6205 141.844 38.6205 141.902C38.5633 142.073 38.3916 142.188 38.2772 142.188Z" fill="white"/>
    <path d="M36.9612 134.979H36.904C36.7324 134.922 36.6178 134.75 36.6751 134.636C36.7323 134.464 37.8767 129.772 40.6804 126.968C43.4842 124.222 46.0019 123.249 46.1163 123.249C46.288 123.192 46.4597 123.249 46.517 123.421C46.5742 123.592 46.5169 123.764 46.3453 123.821C46.3453 123.821 43.8275 124.737 41.1382 127.426C38.4489 130.058 37.3045 134.75 37.2473 134.807C37.19 134.865 37.0756 134.979 36.9612 134.979Z" fill="white"/>
    </svg>
    

  )
}

export default CheckEmailIcon