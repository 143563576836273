import React, { useEffect } from "react";
import ApplicationRoutes from "./Routes/ApplicationRoutes";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./config/Firebase/firebase";
import Snackbar from "./Components/Providers/Snackbar";
import { useDispatch } from "react-redux";
import { userActions } from "./redux/reducers/user";
import { authActions } from "./redux/reducers/auth";
import { getServiceById } from "./services/services";

function App() {
  const dispatch = useDispatch();
  const getUserData = async (user) => {
    let userData = await getServiceById("Users", user.uid);
    let user1 = userData.data();
    dispatch(authActions.setUserData(user1));
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        dispatch(userActions.setUserData(user));
        getUserData(user);
      }
    });
  }, []);

  return (
    <Snackbar>
      <ApplicationRoutes />
    </Snackbar>
  );
}

export default App;
