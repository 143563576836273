import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  currentUser: {},
  stripeUserId: "",
};

const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.currentUser = action.payload;
      return state;
    },
    connectedCoachCode: (state, action) => {
      state.stripeUserId = action.payload;
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const userActions = user.actions;

export const userReducer = user.reducer;
