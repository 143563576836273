import React from "react";

const TaskSmallClockIcon = () => {
  return (
    <svg
      width="11"
      height="10"
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.58931 0.516235C8.18326 0.516235 10.2936 2.62658 10.2936 5.22054C10.2936 7.81449 8.18326 9.92484 5.58931 9.92484C2.99536 9.92484 0.88501 7.81449 0.88501 5.22054C0.88501 2.62658 2.99536 0.516235 5.58931 0.516235ZM5.58931 1.22188C3.3844 1.22188 1.59065 3.01563 1.59065 5.22054C1.59065 7.42544 3.3844 9.21919 5.58931 9.21919C7.79422 9.21919 9.58797 7.42544 9.58797 5.22054C9.58797 3.01563 7.79422 1.22188 5.58931 1.22188ZM5.42993 2.91326C5.62516 2.91326 5.78275 3.07133 5.78275 3.26609V5.34633L7.38457 6.3013C7.55157 6.4015 7.60661 6.6179 7.50688 6.78537C7.44055 6.89593 7.32341 6.95755 7.20345 6.95755C7.14182 6.95755 7.07973 6.94156 7.02281 6.90816L5.24928 5.85016C5.14297 5.78618 5.07711 5.67093 5.07711 5.54673V3.26609C5.07711 3.07133 5.23517 2.91326 5.42993 2.91326Z"
        fill="#FDFDFF"
      />
    </svg>
  );
};

export default TaskSmallClockIcon;
