import { useState, useEffect } from "react";
import { getService } from "./../services/services";
import { useLocation } from "react-router";

export const useBanners = () => {
  const [bannerData, setBannerData] = useState({
    bannerTagline: "",
    bannerImage: "",
  });
  const pathlocation = useLocation();
  const pathName = pathlocation.pathname.substring(1);

  const getBanners = async () => {
    const courseData = await getService("Advertisment");

    const bannersArray = [];
    courseData.docs.forEach((doc) => {
      bannersArray.push({ ...doc.data() });
    });

    const _shopBanner = bannersArray.filter(
      (el) => el.screenData.toLowerCase() === pathName.toLowerCase()
    );

    _shopBanner.forEach((el) => {
      setBannerData({ bannerTagline: el.tagline, bannerImage: el.bannerUrl });
    });
  };

  useEffect(() => {
    getBanners();
  }, []);

  return bannerData;
};
