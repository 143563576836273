import { Grid, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { Colors } from "../../../../config/palette";
import { getService, getServiceById } from "../../../../services/services";
import Loader from "../../../UI/Loader/Loader";
import BlogCard from "../BlogCard/BlogCard";
import { PageTitle } from "./AllBlogs.style";
import English from "../../../../config/Langugage/English.json";
import Polish from "../../../../config/Langugage/Polish.json";
import { useSelector, useDispatch } from "react-redux";
import { productsActions } from "../../../../redux/reducers/products";
import Alert from "../../../UI/Alert/Alert";

const AllBlogs = () => {
  const dispatch = useDispatch();
  const { auth, products } = useSelector((state) => state);
  const selector = auth?.language;
  const currentUser = auth?.userData;
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [blogsForFilter, setBlogsForFilter] = useState([])

  const { categoryName, showFilteredData } = products;

  const getData = async () => {
    setLoading(true);
    let blogList = [];
    const blogData = await getService("Blog");
    blogData.forEach((doc) => {
      blogList.push({ id: doc.id, ...doc.data() });
    });
    setBlogs(blogList);
    setBlogsForFilter(blogList);
    setLoading(false);
  };

  const categoryBlogs = () => {
    let tempData = [...blogsForFilter];
    tempData = tempData.filter((course) => course.Category === categoryName);
    setCategoryFilter(tempData);
    setLoading(false);
  };

  useEffect(() => {
    categoryBlogs();
  }, [categoryName, blogsForFilter]);
  useEffect(() => {
    getData();
    dispatch(productsActions.setFilteredCategoryName({ showCard: false }));
  }, []);
  return (
    <Paper elevation={0} square={true} style={{ background: Colors.secondary }}>
      <PageTitle>
        {selector === "English" ? ` ${English["67"]}` : Polish["67"]}
      </PageTitle>
      <Grid container spacing={6} sx={{ paddingRight: { lg: "35px" } }}>
        {loading ? (
          <Loader />
        ) : (
          showFilteredData ? categoryFilter.length > 0 ?
            categoryFilter.map((filterBlog, index) => {
              let purchased;
              if (currentUser.PurchasedBlogs.includes(filterBlog?.id)) {
                purchased = true;
              } else {
                purchased = false;
              }
              return (
                <BlogCard
                  key={index}
                  tag={true}
                  title={filterBlog.Title}
                  description={filterBlog.Discription}
                  image={filterBlog.FeatureImage}
                  blogId={filterBlog.id}
                  price={filterBlog.Price}
                  priceType={filterBlog.PriceType}
                  purchasedAlready={purchased}
                  selector={selector}
                />
              );
            }) :
            <div style={{ background: "blue", width: '100%', marginTop: '20px' }}>
              <Alert title={`${selector === "English" ? ` ${English["413"]}` : Polish["413"]} ${categoryName}!`} />
            </div>
            :
            blogs?.map((blog, index) => {
              let purchased;
              if (currentUser.PurchasedBlogs.includes(blog?.id)) {
                purchased = true;
              } else {
                purchased = false;
              }
              return (
                <BlogCard
                  key={index}
                  tag={true}
                  title={blog.Title}
                  description={blog.Discription}
                  image={blog.FeatureImage}
                  blogId={blog.id}
                  price={blog.Price}
                  priceType={blog.PriceType}
                  purchasedAlready={purchased}
                  selector={selector}
                />
              );
            })
        )}
      </Grid>
    </Paper>
  );
};

export default AllBlogs;
