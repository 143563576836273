import { useEffect, useState } from "react";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "./../config/Firebase/firebase";

const useGetNotifications = (collectionName) => {
  const [state, setState] = useState([]);

  const getNotifications = async () => {
    const uid = await JSON.parse(localStorage.getItem("userData"));
    const colRef = collection(db, collectionName);
    onSnapshot(colRef, (snapshot) => {
      let Notifications = [];
      snapshot.docs.forEach((doc) => {
        Notifications.push({ ...doc.data() });
      });
      const filteredNotifications = Notifications.filter(
        (doc) => doc.userId === uid.uid
      );

      setState(filteredNotifications);
    });
  };

  useEffect(() => {
    getNotifications();
  }, []);
  return state;
};

export default useGetNotifications;
