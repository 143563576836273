import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import BoxCom from "../../../UI/BoxCom/BoxCom";
import { Filter, FilterWrapper, Heading } from "./Favorite.style";
import ProductCard from "../../Shop/ProductCard/ProductCard";
import { Colors } from "../../../../config/palette";
import HomeCourseCard from "../../Home/HomeCourseCard/HomeCourseCard";
import {
  getService,
  getServiceById,
  updateService,
  postServiceByCoustomId,
} from "./../../../../services/services.js";
import Alert from "./../../../UI/Alert/Alert";
import { useSelector } from "react-redux";
import English from "../../../../config/Langugage/English.json";
import Polish from "../../../../config/Langugage/Polish.json";

const Favorite = () => {
  const [filter, setFilter] = useState("courses");
  const [favoriteProduct, setFavoriteProduct] = useState([]);
  const [favoriteCourse, setFavoriteCourse] = useState([]);

  const size = { sm: "6", md: "6", lg: "3" };
  const selector = useSelector((state) => state.auth.language);

  //*******************Get Favorite Products */
  const getProducts = async () => {
    const uid = await JSON.parse(localStorage.getItem("userData"));
    let user = await getServiceById("Users", uid.uid);
    let user1 = user.data();
    let productArray = [];
    let filteredFavorite = [];

    await getService("Product")
      .then((el) => {
        el.forEach((doc) => {
          productArray.push({ ...doc.data() });
        });

        user1.FavoriteProducts.forEach((item) => {
          const _favroriteProduct = productArray.filter(
            (ele) => ele.id === item
          );
          filteredFavorite.push(..._favroriteProduct);
          setFavoriteProduct(filteredFavorite);
        });
      })
      .catch((error) => console.error(error));
  };
  // *********************Get Favorite Course***********************
  const getCourses = async () => {
    let courseList = [];
    let _fullCourse = [];
    let filteredFavoriteCourse = [];
    const uid = await JSON.parse(localStorage.getItem("userData"));
    let user = await getServiceById("Users", uid.uid);
    let user1 = user.data();
    const courseData = await getService("PopularCourses");
    const fullCourse = await getService("Courses");

    fullCourse.docs.forEach((doc) => {
      _fullCourse.push({ ...doc.data() });
    });

    courseData.docs.forEach((doc) => {
      doc.data().PopularCourses.forEach((item) => {
        const data = _fullCourse.filter((el) => el.id === item.id);
        courseList.push(...data);
      });
    });

    user1.Favorites.forEach((item) => {
      const _favoriteCourse = courseList.filter((ele) => ele.id === item);
      filteredFavoriteCourse.push(..._favoriteCourse);
    });
    setFavoriteCourse(filteredFavoriteCourse);
  };
  // ************************Favorie button Handler *************************

  const checkStatus = async (courseId) => {
    const uid = await JSON.parse(localStorage.getItem("userData"));
    let user = await getServiceById("Users", uid.uid);
    let user1 = user.data();
    let arr = [...user1.Favorites];
    let check = arr.some((el) => el.includes(courseId));
    return check;
  };
  const favoriteButtonHandler = async (courseId, item, index) => {
    let check = await checkStatus(courseId);
    const uid = await JSON.parse(localStorage.getItem("userData"));
    let user = await getServiceById("Users", uid.uid);
    let user1 = user.data();
    let arr = [...user1.Favorites];
    // let check = arr.some((el) => el.includes(courseId));
    let array = item?.favoritesBy?.length ? [...item.favoritesBy] : [];
    let check2 = array.some((el) => el.includes(uid.uid));
    if (check2) {
      let index = array.indexOf(uid.uid);
      array.splice(index, 1);
      let payload2 = { ...item, favoritesBy: array };
      delete payload2.flag;
      await postServiceByCoustomId("Courses", payload2, courseId);
    } else {
      array.push(uid.uid);
      let payload2 = { ...item, favoritesBy: array };
      delete payload2.flag;
      await postServiceByCoustomId("Courses", payload2, courseId);
    }
    if (check) {
      let index = arr.indexOf(courseId);
      arr.splice(index, 1);
      let payload = { ...user1, Favorites: arr };
      await updateService("Users", uid.uid, payload);
    } else {
      arr.push(courseId);
      let payload = { ...user1, Favorites: arr };
      await updateService("Users", uid.uid, payload);
    }
    getCourses();
  };

  function selectFilter(selectedTab) {
    setFilter(selectedTab);
  }

  function checkSpacing() {
    if (filter === "courses") {
      return 2;
    } else {
      return 4;
    }
  }
  useEffect(() => {
    getProducts();
    getCourses();
  }, []);

  return (
    <BoxCom sx={{ marginTop: "36px", paddingRight: { lg: "20px", md: "0px" } }}>
      {/* Favorite */}
      <Heading>
        {selector === "English" ? ` ${English["344"]}` : Polish["344"]}
      </Heading>
      <FilterWrapper>
        <Filter
          onClick={() => {
            selectFilter("courses");
          }}
          component="span"
          sx={{ color: filter === "courses" && Colors.info }}
        >
          {/* Courses */}
          {selector === "English" ? ` ${English["93"]}` : Polish["93"]}
        </Filter>
        <Filter
          onClick={() => {
            selectFilter("products");
          }}
          component="span"
          sx={{ color: filter === "products" && Colors.info }}
        >
          {/* Products */}
          {selector === "English" ? ` ${English["94"]}` : Polish["94"]}
        </Filter>

      </FilterWrapper>
      {filter === "products" && favoriteProduct.length === 0 ? (
        <Alert severity="info" message={selector === "English" ? ` ${English["398"]}` : Polish["398"]} />
      ) : null}
      {filter === "courses" && favoriteCourse.length === 0 ? (
        <Alert severity="info" message={selector === "English" ? ` ${English["397"]}` : Polish["397"]} />
      ) : null}
      <Grid container spacing={checkSpacing()} sx={{ paddingTop: "20px" }}>
        {filter === "products" && (
          <>
            {favoriteProduct.map((product) => {
              return (
                <ProductCard
                  heading={"Products"}
                  id={product.id}
                  size={size}
                  title={product.Title}
                  name={product.CoachName}
                  price={product.Price}
                  image={product.Image}
                />
              );
            })}
          </>
        )}
        {filter === "courses" && (
          <>
            <HomeCourseCard
              size={size}
              courseList={favoriteCourse}
              favoriteButtonHandler={favoriteButtonHandler}
            />
          </>
        )}
      </Grid>
    </BoxCom>
  );
};

export default Favorite;
