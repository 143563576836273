import { Grid } from "@mui/material";
import React, { useState } from "react";
import BoxCom from "../../../UI/BoxCom/BoxCom";
import Calender from "../../../UI/Calender/Calender";
import {
  getServiceById,
  updateService,
  deleteService,
} from "./../.././../../services/services.js";
import useGetData from "./../../../../hooks/useGetData.js";
import { useSnackbar } from "notistack";

import { LeftSideGrid, RightSideGrid } from "./Todo.style";
import TodoForm from "../TodoForm/TodoForm";
import AllTodo from "../AllTodo/AllTodo";
import SingleTodo from "../SingleTodo/SingleTodo";
import { useSelector } from "react-redux";
import English from "../../../../config/Langugage/English.json";
import Polish from "../../../../config/Langugage/Polish.json";

// {selector === "English" ? ` ${English["71"]}` : Polish["71"]}
const Todo = () => {
  const data = useGetData("Tasks");
  const selector = useSelector((state) => state.auth.language);
  const { enqueueSnackbar } = useSnackbar();
  const [currentPage, setCurrentPage] = useState("allTodo");
  const [singleTaskData, setSingleTaskData] = useState({});
  const [identifier, setIdentifier] = useState("");
  const [calenderTime, setCalenderTime] = useState("");

  function getFormattedDateTime() {
    const date = new Date();
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  const sendTaskId = async (taskId) => {
    let user = await getServiceById("Tasks", taskId);
    setSingleTaskData(user.data());
  };
  const handleCompleteButton = async () => {
    let payload = { ...singleTaskData, completed: true };

    try {
      await updateService("Tasks", singleTaskData?.id, payload);
      enqueueSnackbar("Tasks Completed", {
        variant: "success",
        autoHideDuration: 6000,
      });
      setCurrentPage("allTodo");
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: 6000,
      });
    }
  };
  const handleDeleteButton = async () => {
    // let blogList = [];

    // const blogData = await getService("Tasks");

    // blogData.docs.forEach((doc) => {
    //   blogList.push({ ...doc.data() });
    // });

    // let index = blogList.findIndex((object) => {
    //   return object.id === singleTaskData.id;
    // });

    // blogList.splice(index, 1);
    // console.log("spliced", blogList);
    // let payload = { ...blogList };
    // await updateService("Tasks", uid.uid, payload);
    try {
      await deleteService("Tasks", singleTaskData.id);
      enqueueSnackbar("Tasks deleted successfully", {
        variant: "success",
        autoHideDuration: 4000,
      });
      setCurrentPage("allTodo");
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: 6000,
      });
    }
  };
  const handleEditButton = async (taskIdentifier) => {
    setIdentifier(taskIdentifier);

    setCurrentPage("todoForm");
  };

  const sideEffects = (selectedDate) => {
    console.log("current data", selectedDate)
    setCalenderTime(selectedDate);
  };

  return (
    <Grid container spacing={2} sx={{ width: { md: "80%", lg: "100%" } }}>
      <LeftSideGrid item xs={12} sm={6} md={12} lg={6}>
        {currentPage === "allTodo" ? (
          <AllTodo
            setCurrentPage={setCurrentPage}
            data={data}
            sendTaskId={sendTaskId}
            calenderTime={calenderTime}
            selector={selector}
            English={English}
            Polish={Polish}
          />
        ) : currentPage === "todoForm" ? (
          <TodoForm
            setCurrentPage={setCurrentPage}
            singleTaskData={singleTaskData}
            identifier={identifier}
            selector={selector}
            English={English}
            Polish={Polish}
          />
        ) : (
          <SingleTodo
            setCurrentPage={setCurrentPage}
            singleTaskData={singleTaskData}
            handleCompleteButton={handleCompleteButton}
            handleDeleteButton={handleDeleteButton}
            handleEditButton={handleEditButton}
            selector={selector}
            English={English}
            Polish={Polish}
          />
        )}
      </LeftSideGrid>

      <RightSideGrid item xs={12} sm={6} md={12} lg={5} marginLeft="5%">
        <BoxCom sx={{ marginTop: "90px" }}>
          <Calender sideEffects={sideEffects} />
        </BoxCom>
      </RightSideGrid>
    </Grid>
  );
};

export default Todo;
