import React from "react";
import ProfileSwitchIcon from "../../../../assets/icons/ProfileSwitchIcon.jsx";
import { useNavigate, useLocation } from "react-router-dom";
import { MainWrapper, TextWrapper } from "./CoachProfileLink.style";
import { useSelector } from "react-redux";
import English from "./../../../../config/Langugage/English.json";
import Polish from "./../../../../config/Langugage/Polish.json";
import IconButton from "@mui/material/IconButton";

const CoachProfileLink = ({ pathname, coachHandler }) => {
  // const location = useLocation();
  const navigate = useNavigate();
  const store = useSelector((state) => state);
  const { auth } = store;
  const selector = auth.language;
  const currentUserData = auth.userData;
  // const code = user.coachCode;
  // const ca = "ca_MDJtzTAK3qSh3yi3NaP4ELPGuYwycWaY";
  // const STRIPE_CONNECT_REDIRECT_URI =
  //   "https://connect.stripe.com/connect/default/oauth/test";
  // // const connectURL = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${ca}&scope=read_write`;
  // const connectURL = `https://connect.stripe.com/express/oauth/authorize?redirect_uri=${STRIPE_CONNECT_REDIRECT_URI}&client_id=${ca}`;
  const coachButtonHandler = () => {
    if (currentUserData?.Coach === false) {
      window.location.replace(process.env.REACT_APP_ACCOUNT_KEY);
    } else {
      navigate(pathname === "coachProfile" ? "/profile" : "/coachProfile");
    }
  };

  return (
    <MainWrapper>
      <TextWrapper>
        {selector === "English" ? ` ${English["127"]}` : Polish["127"]}
      </TextWrapper>

      <IconButton aria-label="delete" onClick={coachButtonHandler}>
        <ProfileSwitchIcon
          position={pathname === "coachProfile" ? "right" : "left"}
        />
      </IconButton>
    </MainWrapper>
  );
};

export default CoachProfileLink;
