import React from "react";

const ProfileSwitchIcon = ({ position }) => {
  return (
    <>
      {position !== "right" ? (
        <svg
          width="26"
          height="17"
          viewBox="0 0 26 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_2_5531)">
            <path
              d="M26 8.125C26 9.22526 25.7842 10.2769 25.3525 11.2798C24.9209 12.2827 24.3433 13.146 23.6196 13.8696C22.896 14.5933 22.0327 15.1709 21.0298 15.6025C20.0269 16.0342 18.9753 16.25 17.875 16.25L8.125 16.25C7.02474 16.25 5.97315 16.0342 4.97022 15.6025C3.96729 15.1709 3.10401 14.5933 2.38037 13.8696C1.65674 13.146 1.0791 12.2827 0.647463 11.2798C0.215822 10.2769 1.90735e-06 9.22526 1.90735e-06 8.125C1.90735e-06 7.02474 0.215822 5.97315 0.647463 4.97022C1.0791 3.96729 1.65674 3.10401 2.38037 2.38037C3.10401 1.65674 3.96729 1.0791 4.97022 0.647463C5.97315 0.215822 7.02474 1.90735e-06 8.125 1.90735e-06L17.875 1.90735e-06C18.9753 1.90735e-06 20.0269 0.215822 21.0298 0.647463C22.0327 1.0791 22.896 1.65674 23.6196 2.38037C24.3433 3.10401 24.9209 3.96729 25.3525 4.97022C25.7842 5.97315 26 7.02474 26 8.125ZM8.125 1.625C7.24479 1.625 6.40479 1.79639 5.60498 2.13916C4.80518 2.48194 4.11328 2.94531 3.5293 3.5293C2.94531 4.11328 2.48194 4.80518 2.13916 5.60498C1.79639 6.40479 1.625 7.24479 1.625 8.125C1.625 9.00521 1.79639 9.84522 2.13916 10.645C2.48194 11.4448 2.94531 12.1367 3.5293 12.7207C4.11328 13.3047 4.80518 13.7681 5.60498 14.1108C6.40479 14.4536 7.24479 14.625 8.125 14.625C9.00521 14.625 9.84522 14.4536 10.645 14.1108C11.4448 13.7681 12.1367 13.3047 12.7207 12.7207C13.3047 12.1367 13.7681 11.4448 14.1108 10.645C14.4536 9.84522 14.625 9.00521 14.625 8.125C14.625 7.24479 14.4536 6.40479 14.1108 5.60498C13.7681 4.80518 13.3047 4.11328 12.7207 3.5293C12.1367 2.94531 11.4448 2.48194 10.645 2.13916C9.84522 1.79639 9.00521 1.625 8.125 1.625Z"
              fill="#B7C6D9"
            />
          </g>
          <defs>
            <clipPath id="clip0_2_5531">
              <rect
                width="26"
                height="16.25"
                fill="white"
                transform="translate(26 16.25) rotate(-180)"
              />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          width="26"
          height="17"
          viewBox="0 0 26 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_2_2308)">
            <path
              d="M0 8.125C0 7.02474 0.21582 5.97314 0.647461 4.97021C1.0791 3.96728 1.65674 3.104 2.38037 2.38037C3.104 1.65674 3.96728 1.0791 4.97021 0.647461C5.97314 0.21582 7.02474 0 8.125 0H17.875C18.9753 0 20.0269 0.21582 21.0298 0.647461C22.0327 1.0791 22.896 1.65674 23.6196 2.38037C24.3433 3.104 24.9209 3.96728 25.3525 4.97021C25.7842 5.97314 26 7.02474 26 8.125C26 9.22526 25.7842 10.2769 25.3525 11.2798C24.9209 12.2827 24.3433 13.146 23.6196 13.8696C22.896 14.5933 22.0327 15.1709 21.0298 15.6025C20.0269 16.0342 18.9753 16.25 17.875 16.25H8.125C7.02474 16.25 5.97314 16.0342 4.97021 15.6025C3.96728 15.1709 3.104 14.5933 2.38037 13.8696C1.65674 13.146 1.0791 12.2827 0.647461 11.2798C0.21582 10.2769 0 9.22526 0 8.125ZM17.875 14.625C18.7552 14.625 19.5952 14.4536 20.395 14.1108C21.1948 13.7681 21.8867 13.3047 22.4707 12.7207C23.0547 12.1367 23.5181 11.4448 23.8608 10.645C24.2036 9.84521 24.375 9.00521 24.375 8.125C24.375 7.24479 24.2036 6.40478 23.8608 5.60498C23.5181 4.80518 23.0547 4.11328 22.4707 3.5293C21.8867 2.94531 21.1948 2.48193 20.395 2.13916C19.5952 1.79639 18.7552 1.625 17.875 1.625C16.9948 1.625 16.1548 1.79639 15.355 2.13916C14.5552 2.48193 13.8633 2.94531 13.2793 3.5293C12.6953 4.11328 12.2319 4.80518 11.8892 5.60498C11.5464 6.40478 11.375 7.24479 11.375 8.125C11.375 9.00521 11.5464 9.84521 11.8892 10.645C12.2319 11.4448 12.6953 12.1367 13.2793 12.7207C13.8633 13.3047 14.5552 13.7681 15.355 14.1108C16.1548 14.4536 16.9948 14.625 17.875 14.625Z"
              fill="#E63369"
            />
          </g>
          <defs>
            <clipPath id="clip0_2_2308">
              <rect
                width="26"
                height="16.25"
                fill="white"
                transform="translate(26 16.25) rotate(-180)"
              />
            </clipPath>
          </defs>
        </svg>
      )}
    </>
  );
};

export default ProfileSwitchIcon;
