import React, { useEffect, useState } from "react";
import SalesCard from "../SalesCard/SalesCard";
import { getService } from "../../../../services/services";
import Loader from "../../../UI/Loader/Loader";
import { useSelector } from "react-redux";
import {
  Heading,
  MainContainer,
  SalesCardContainer,
  SubHeading,
} from "./SalesBoard.style ";
import { CURRENCY } from "../../../../config/constant";

const SalesBoard = () => {
  const store = useSelector((state) => state.auth);
  const { userData } = store;
  const currentCouchId = userData.id;
  const [showLoader, setShowLoader] = useState(true);
  const [courses, setCourses] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [products, setProducts] = useState([]);

  const currencyUnit = CURRENCY;

  // total coach courses
  const getCoachData = async () => {
    let courseList = [];
    let blogList = [];
    let productList = [];

    const courseData = await getService("Courses");
    const blogData = await getService("Blog");
    const productData = await getService("Product");

    // ***********#################### filter Coach Courses ####################*****************

    courseData.forEach((doc) => {
      courseList.push({ id: doc.id, ...doc.data() });
    });
    const coachCourse = courseList.filter(
      (course) => course.CoachId === currentCouchId
    );
    setCourses(coachCourse);

    // ***********#################### filter Coach Blogs ####################*****************
    blogData.forEach((doc) => {
      blogList.push({ id: doc.id, ...doc.data() });
    });
    const coachBlogs = blogList.filter(
      (blog) => blog.CoachId === currentCouchId
    );
    setBlogs(coachBlogs);

    // ***********#################### filter Coach Products ####################*****************
    productData.forEach((doc) => {
      productList.push({ id: doc.id, ...doc.data() });
    });
    const coachProducts = productList.filter(
      (product) => product.CoachId === currentCouchId
    );
    setProducts(coachProducts);
    setShowLoader(false);
  };

  useEffect(() => {
    getCoachData();
  }, []);
  return (
    <MainContainer>
      <Heading>Sales Board</Heading>
      <SubHeading>Your Total Sales</SubHeading>
      {showLoader ? (
        <Loader />
      ) : (
        <SalesCardContainer>
          <SalesCard
            category="Courses"
            count={courses.length}
            amount={`${userData.SoldCourses} ${currencyUnit}`}
          />
          <SalesCard
            category="Products"
            count={products.length}
            amount={`${userData.SoldEbooks} ${currencyUnit}`}
          />
          <SalesCard
            category="Blogs"
            count={blogs.length}
            amount={`${userData.SoldArticles} ${currencyUnit}`}
          />
        </SalesCardContainer>
      )}
    </MainContainer>
  );
};

export default SalesBoard;
