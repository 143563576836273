import React, { useEffect, useState } from "react";
import BoxCom from "../../../UI/BoxCom/BoxCom";
import {
  MainContainer,
  PopularCourseHeading,
} from "../HomeCoachesContainer/HomeCoachesContainer.style";
import {
  getService,
  getServiceById,
  updateService,
  postServiceByCoustomId,
} from "../../../../services/services";
import HomeCourseCard from "../HomeCourseCard/HomeCourseCard";
import { Grid } from "@mui/material";
import HomeCoachcom from "../HomeCoachCom/HomeCoachCom";
import { useBanners } from "./../../../../hooks/useBanners";
import TopBanner from "./../../../UI/HomeTopCard/HomeTopCard";
import Loader from "./../../../UI/Loader/Loader";
import { useSnackbar } from "notistack";
import Button from "@mui/material/Button";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import Searchbar from "./../../../UI/SearchBar/SearchBarMain/SearchContainer";
import { useSelector } from "react-redux";
import English from "../../../../config/Langugage/English.json";
import Polish from "../../../../config/Langugage/Polish.json";

// {selector === "English" ? ` ${English["3"]}` : Polish["3"]}
const Home = () => {
  const bannerData = useBanners();
  const { enqueueSnackbar } = useSnackbar();
  const [courses, setCourses] = useState([]);
  const [coaches, setCoaches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [limitedCoureseList, setLimitedCoureseList] = useState([]);
  const [limitedCoaches, setLimitedCoaches] = useState([]);
  const currentData = useSelector((state) => state.auth);
  const { language: selector, userData } = currentData;

  const size = { xs: "12", sm: "6", md: "6", lg: "3" };

  const [showALL, setShowAll] = useState(false);
  const [showAllCoaches, setShowAllCoaches] = useState(false);
  const seeAllHandler = () => {
    setShowAll(!showALL);
  };
  const seeAllCoachesHandler = () => {
    setShowAllCoaches(!showAllCoaches);
  };

  const getCourses = async () => {
    setLoading(true);
    let courseList = [];
    let _fullCourse = [];
    const courseData = await getService("PopularCourses");
    const fullCourse = await getService("Courses");
    fullCourse.docs.forEach((doc) => {
      _fullCourse.push({ ...doc.data() });
    });

    courseData.docs.forEach((doc) => {
      doc.data().PopularCourses.forEach((item) => {
        const data = _fullCourse.filter((el) => el.id === item.id);
        courseList.push(...data);
      });
    });
    const limitedCourseList = courseList.slice(0, 4);
    setLimitedCoureseList(limitedCourseList);
    setCourses(courseList);
    setLoading(false);
  };

  const getCoaches = async () => {
    setLoading(true);

    let tempData = [];

    const usersData = await getService("Users");
    usersData.forEach((doc) => {
      if (doc.data().Coach === true)
        tempData.push({ id: doc.id, ...doc.data() });
    });
    const _limitedCoaches = tempData.slice(0, 4);
    setLimitedCoaches(_limitedCoaches);
    setCoaches(tempData);
    setLoading(false);
  };

  const checkStatus = async (courseId) => {
    const uid = await JSON.parse(localStorage.getItem("userData"));
    let user = await getServiceById("Users", uid.uid);
    let user1 = user.data();
    // const user1 = userData;
    let arr = [...user1.Favorites];
    let check = arr.some((el) => el.includes(courseId));
    return check;
  };
  const favoriteButtonHandler = async (courseId, item, index) => {
    let check = await checkStatus(courseId);
    const uid = await JSON.parse(localStorage.getItem("userData"));
    let user = await getServiceById("Users", uid.uid);
    let user1 = user.data();
    // const user1 = userData;
    let arr = [...user1.Favorites];
    // let check = arr.some((el) => el.includes(courseId));
    let array = item?.favoritesBy.length ? [...item.favoritesBy] : [];
    let check2 = array.some((el) => el.includes(user1.id));
    if (check2) {
      let index = array.indexOf(user1.id);
      array.splice(index, 1);
      let payload2 = { ...item, favoritesBy: array };
      delete payload2.flag;
      await postServiceByCoustomId("Courses", payload2, courseId);
    } else {
      array.push(user1.id);
      let payload2 = { ...item, favoritesBy: array };
      delete payload2.flag;
      await postServiceByCoustomId("Courses", payload2, courseId);
    }
    if (check) {
      let index = arr.indexOf(courseId);
      arr.splice(index, 1);
      let payload = { ...user1, Favorites: arr };
      await updateService("Users", user1.id, payload);
      // setFavColor(false);
      // enqueueSnackbar("Rmoved from My favorite", {
      //   variant: "info",
      //   autoHideDuration: 4000,
      // });
    } else {
      arr.push(courseId);
      let payload = { ...user1, Favorites: arr };
      await updateService("Users", user1.id, payload);
      // setFavColor(true);
      // enqueueSnackbar("Added to My favorite", {
      //   variant: "success",
      //   autoHideDuration: 4000,
      // });
    }
    getCourses();
  };

  useEffect(() => {
    getCourses();
    getCoaches();
    // checkStatus();
  }, []);

  return (
    <>
      {/* <Searchbar selector={selector} English={English} Polish={Polish} /> */}
      <BoxCom sx={{ marginTop: "60px" }}>
        <TopBanner
          tagline={bannerData.bannerTagline}
          image={bannerData.bannerImage}
        />
      </BoxCom>
      {/* <FilteredCourses/> */}
      {loading ? (
        <Loader />
      ) : (
        <>
          <BoxCom sx={{ marginTop: "33px" }}>
            <MainContainer>
              {/* Popular Courses */}
              <PopularCourseHeading>
                {selector === "English" ? ` ${English["36"]}` : Polish["36"]}
              </PopularCourseHeading>
              {courses?.length >= 4 ? (
                <Button
                  sx={{ textTransform: "capitalize" }}
                  variant="text"
                  color={showALL ? "success" : "info"}
                  endIcon={
                    showALL ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )
                  }
                  onClick={seeAllHandler}
                >
                  {/* See Less Or See All Courses */}
                  {showALL
                    ? selector === "English"
                      ? ` ${English["347"]}`
                      : Polish["347"]
                    : selector === "English"
                      ? `${English["348"]} (${courses.length})`
                      : `${Polish["348"]} (${courses.length})`}
                </Button>
              ) : null}
            </MainContainer>
            <Grid container spacing={2}>
              {showALL ? (
                <>
                  <HomeCourseCard
                    size={size}
                    courseList={courses}
                    favoriteButtonHandler={favoriteButtonHandler}
                  />
                  );
                </>
              ) : (
                <>
                  <HomeCourseCard
                    size={size}
                    courseList={limitedCoureseList}
                    favoriteButtonHandler={favoriteButtonHandler}
                  />

                </>
              )}
            </Grid>
          </BoxCom>
        </>
      )}

      <BoxCom sx={{ marginTop: "60px" }}>
        <MainContainer>
          {/* Top Coaches */}
          <PopularCourseHeading>
            {selector === "English" ? ` ${English["37"]}` : Polish["37"]}
          </PopularCourseHeading>
          {coaches?.length > 4 ? (
            <Button
              sx={{ textTransform: "capitalize" }}
              variant="text"
              color={showAllCoaches ? "success" : "info"}
              endIcon={
                showAllCoaches ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )
              }
              onClick={seeAllCoachesHandler}
            >
              {showAllCoaches
                ? selector === "English"
                  ? ` ${English["347"]}`
                  : Polish["347"]
                : selector === "English"
                  ? `${English["379"]} (${coaches.length})`
                  : `${Polish["379"]} (${coaches.length})`}
            </Button>
          ) : (
            ""
          )}
        </MainContainer>
        <Grid container spacing={2}>
          {showAllCoaches ? (
            <>
              {" "}
              {coaches?.map((coach) => {
                return (
                  <HomeCoachcom
                    name={coach.FullName}
                    image={coach.ProfileImage}
                  // coursePrice={course.price}
                  />
                );
              })}{" "}
            </>
          ) : (
            limitedCoaches?.map((coach) => {
              return (
                <HomeCoachcom
                  name={coach.FullName}
                  image={coach.ProfileImage}
                // coursePrice={course.price}
                />
              );
            })
          )}
        </Grid>
        {/* <HomeCoachesContainer/> */}
      </BoxCom>
    </>
  );
};

export default Home;
