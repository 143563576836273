import React, { useState } from "react";
import CheckCircleIcon from "../../../../assets/icons/CheckCircleIcon";
import BoxCom from "../../../UI/BoxCom/BoxCom";
import TextfieldComp from "../../../UI/TextFieldCom/Textfield";
import {
  Details,
  FieldLabel,
  Heading,
  MainContainer,
  ProfileButton,
  SubHeading,
} from "./AddBank.style";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

const AddBank = ({ showNewBankForm, setShowNewBankForm }) => {
  const [bankName, setBankName] = React.useState("");
  const [accountNumber, setAccountNumber] = React.useState("");
  const [branchCode, setBranchCode] = React.useState("");
  const [zipCode, setZipCode] = React.useState("");
  const [stripeObject, setStripeObject] = useState(null);
  const [bankDetails, setBankDetails] = React.useState(
    "Account Number : 8379834******"
  );
  const [check, setCheck] = React.useState(true);

  let FORM_VALIDATION = "";
  let initialValues = "";

  if (!showNewBankForm) {
    FORM_VALIDATION = Yup.object().shape({
      bankDetails: Yup.string().required("bank details is required"),
    });

    initialValues = {
      bankDetails: "",
    };
  } else {
    FORM_VALIDATION = Yup.object().shape({
      bankName: Yup.string().required("bank name is required"),

      accountNumber: Yup.string().required("account name is required"),

      branchCode: Yup.string().required("branch code is required"),

      zipCode: Yup.string().required("zip code is required"),
    });

    initialValues = {
      bankName: "",

      accountNumber: "",

      branchCode: "",

      zipCode: "",
    };
  }

  async function submitHandler(values) {
    console.log("values", values);
  }

  // React.useEffect(() => {
  //   // Google Analytics

  //   var referrer = document.referrer;
  //   console.log("referrer url", referrer);

  //   console.log("ishtiaq", window.location);
  // }, []);

  // const getDashboardStats = async () => {
  //   await fetch(
  //     "https://us-central1-evolove-56393.cloudfunctions.net/redirectUrl"
  //   )
  //     .then((response) => response.json())
  //     .then((result) => console.log("connected", result))
  //     .catch((error) => console.log("connected errors", error));
  // };

  return (
    <MainContainer>
      <>
        <Formik
          initialValues={initialValues}
          validationSchema={FORM_VALIDATION}
          onSubmit={(values) => {
            submitHandler(values);
            console.log(values);
          }}
        >
          {(formik) => {
            const { errors, touched, values, handleChange } = formik;
            console.log("errors", errors);
            console.log("values", values);
            console.log("touched", touched);

            return (
              <>
                <Form>
                  <SubHeading>Add Bank</SubHeading>
                  <BoxCom sx={{ marginTop: "20px" }}>
                    <BoxCom
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <FieldLabel>Bank Name</FieldLabel>
                    </BoxCom>
                    <TextfieldComp
                      height="50px"
                      width="100%"
                      autoComplete="false"
                      onChange={handleChange}
                      name="bankName"
                      value={values.bankName}
                      justifyproperty="flex-start"
                      alignproperty="null"
                      helperText={errors.bankName ? errors.bankName : ""}
                      error={errors.bankName ? true : null}
                    />
                  </BoxCom>
                  <BoxCom sx={{ marginTop: "40px" }}>
                    <BoxCom
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <FieldLabel>Account Number</FieldLabel>
                    </BoxCom>
                    <TextfieldComp
                      height="50px"
                      width="100%"
                      autoComplete="false"
                      onChange={handleChange}
                      name="accountNumber"
                      value={values.accountNumber}
                      justifyproperty="flex-start"
                      alignproperty="null"
                      helperText={
                        errors.accountNumber ? errors.accountNumber : ""
                      }
                      error={errors.accountNumber ? true : null}
                    />
                  </BoxCom>
                  <BoxCom sx={{ marginTop: "40px" }}>
                    <BoxCom
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <FieldLabel>Branch Code</FieldLabel>
                    </BoxCom>
                    <TextfieldComp
                      height="50px"
                      width="100%"
                      autoComplete="false"
                      onChange={handleChange}
                      name="branchCode"
                      value={values.branchCode}
                      justifyproperty="flex-start"
                      alignproperty="null"
                      helperText={errors.branchCode ? errors.branchCode : ""}
                      error={errors.branchCode ? true : null}
                    />
                  </BoxCom>
                  <BoxCom sx={{ marginTop: "40px" }}>
                    <BoxCom
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <FieldLabel>Zip Code</FieldLabel>
                    </BoxCom>
                    <TextfieldComp
                      height="50px"
                      width="100%"
                      autoComplete="false"
                      onChange={handleChange}
                      name="zipCode"
                      value={values.zipCode}
                      justifyproperty="flex-start"
                      alignproperty="null"
                      helperText={errors.zipCode ? errors.zipCode : ""}
                      error={errors.zipCode ? true : null}
                    />
                  </BoxCom>
                  <BoxCom
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      marginTop: "40px",
                    }}
                  >
                    <BoxCom onClick={() => setCheck(!check)}>
                      <CheckCircleIcon check={check} />
                    </BoxCom>
                    <Heading sx={{ marginLeft: "10px" }}>
                      Save for Later Payouts
                    </Heading>
                  </BoxCom>

                  {/* </> */}
                </Form>
              </>
            );
          }}
        </Formik>
      </>

    </MainContainer>
  );
};
// https://connect.stripe.com/express/oauth/authorize?response_type=code&client_id=ca_MVcboX8eFq383WkYp1ey9iQ77D2cHi8e
export default AddBank;
