import { styled } from "@mui/system";
import { IconButton } from "@mui/material";
import CardComp from "./../Card/Card.jsx";
import Modal from "@mui/material/Modal";
import { Colors } from "./../../../config/palette";

export const Content = styled(CardComp)(
  ({ theme, modelWidth, modelPadding, modelheight }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: modelWidth,
    background: Colors.secondary,
    padding: modelPadding,
    minHeight: modelheight,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  })
);

export const StyledModal = styled((props) => {
  return <Modal {...props} />;
})(({ theme }) => ({
  ".css-1jjmlwa-MuiModal-root": {
    background: "red",
  },
}));

export const CrossButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: 5,
  right: 5,
  cursor: "pointer",
}));
