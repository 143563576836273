import { Box, Container, Grid, Typography } from "@mui/material";
import Button from "../../../../UI/Button/Button";
import Footer from "../../../../UI/Footer/FooterMain/Footer";
import NavBar from "../../../../UI/NavBar/NavBarMain/NavBar";
import TextfieldComp from "../../../../UI/TextFieldCom/Textfield";
import React from "react";
import { useSnackbar } from "notistack";
import { sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  FormContainer,
  GridItem,
  Heading,
  PaperContainer,
} from "./ForgetPasswordMain.style";
import { auth } from "../../../../../config/Firebase/firebase";
import { useSelector } from "react-redux";
import English from "./../../../../../config/Langugage/English.json";
import Polish from "./../../../../../config/Langugage/Polish.json";

function ForgetPassword() {
  // const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const initialValues = {
    email: "",
  };
  const selector = useSelector((state) => state.auth.language);

  const FORM_VALIDATION = Yup.object().shape({
    email: Yup.string()
      .email()
      .required(selector === "English" ? English[366] : Polish[366]),
  });

  async function submitHandler(values) {
    const resetPassword = async (email) => {
      setLoading(true);
      await sendPasswordResetEmail(auth, email)
        .then(() => {
          setLoading(false);
          // Reset Password link send to your Email.
          enqueueSnackbar(selector === "English" ? English[366] : Polish[366], {
            variant: "success",
            autoHideDuration: 4000,
          });
          navigate("/verifyCode");
        })
        .catch((e) => {
          setLoading(false);
          enqueueSnackbar(e, {
            variant: "error",
            autoHideDuration: 4000,
          });
        });
    };
    resetPassword(values.email);
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={FORM_VALIDATION}
      onSubmit={(values) => {
        submitHandler(values);
      }}
    >
      {(formik) => {
        const { errors, values, handleChange } = formik;
        return (
          <>
            <Form>
              <Box sx={{ background: "#131315" }}>
                <NavBar />
                <Container style={{ padding: "0px" }}>
                  <PaperContainer elevation={0}>
                    <Heading>
                      {/* Forgot Password */}
                      {selector === "English" ? ` ${English[19]}` : Polish[19]}
                    </Heading>
                    <Typography
                      variant="body2"
                      sx={{
                        alignText: "center",
                        color: "#B7C6D9",
                        fontSize: "18px",
                      }}
                    >
                      {/* Enter the email address associated with your account */}
                      {selector === "English" ? ` ${English[20]}` : Polish[20]}
                    </Typography>
                    <FormContainer>
                      <Grid container spacing={1}>
                        <GridItem item xs={12}>
                          <TextfieldComp
                            autoComplete="false"
                            placeholder="Email"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            name="email"
                            value={values.email}
                            helperText={errors.email ? errors.email : ""}
                            error={errors.email ? true : null}
                          />
                        </GridItem>
                        <GridItem
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "50px",
                          }}
                        >
                          <Button type="submit" variant="contained">
                            {/* please wait... and Send */}
                            {loading
                              ? selector === "English"
                                ? English[360]
                                : Polish[360]
                              : selector === "English"
                              ? English[21]
                              : Polish[21]}
                          </Button>
                        </GridItem>
                      </Grid>
                    </FormContainer>
                  </PaperContainer>
                </Container>
                <Footer />
              </Box>
            </Form>
          </>
        );
      }}
    </Formik>
  );
}

export default ForgetPassword;
