import { styled } from "@mui/material";
import { Colors } from "../../../config/palette";
import Skeleton from "@mui/material/Skeleton";

export const SkeletonWrapper = styled(Skeleton)(() => ({
  background: Colors.dark,
}));
export const Favorite = styled("div")(() => ({
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "-26px !important",
  marginRight: "30px !important",
}));
export const PriceWrapper = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));
