import React from "react";
import dayjs, { Dayjs } from "dayjs";
// import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
// import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
// import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { styled } from "@mui/system";
import PropTypes from "prop-types";
import { Colors } from "./../../../config/palette";
import "./time.css";

export const SearchField = styled(TextField)(({ theme }) => ({
  borderRadius: "12px",
  // backgroundColor: "white",

  //   "& label.Mui-focused": {
  //     color: "white",
  //   },
  //   "& .MuiInput-underline:after": {
  //     borderBottomColor: "white",
  //   },
  "& .MuiOutlinedInput-root": {
    color: "white",
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
}));
export const TimeField = styled(TimePicker)(({ theme }) => ({
  // backgroundColor: "white",
  backgroundColor: Colors.dark,
  // color: "green",

  // "& label.Mui-focused": {
  //   color: "white",
  // },
  // "& .MuiInput-underline:after": {
  //   borderBottomColor: "white",
  // },
  "& .MuiOutlinedInput-root": {
    color: "white",
    "& fieldset": {
      borderColor: "white",
      borderRadius: "12px",
      // color: "white",
    },
    // "&:hover fieldset": {
    //   borderColor: "white",
    //   color: "green",
    // },
    // "&.Mui-focused fieldset": {
    //   borderColor: "white",
    //   color: "green",
    // },
  },
}));

const Time = ({ timeHandler, currentTime }) => {
  // const [value, setValue] = React.useState();

  const handleChange = (newValue) => {
    // setValue(newValue);

    timeHandler(newValue);
  };
  console.log("currentTime", currentTime);
  const color = "white";
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimeField
          value={currentTime}
          onChange={handleChange}
          renderInput={(params) => (
            <SearchField
              fullWidth={true}
              sx={{
                svg: { color },
              }}
              {...params}
            />
          )}
        />
      </LocalizationProvider>
    </div>
  );
};

export default Time;
Time.propTypes = {
  timeHandler: PropTypes.func,
};

Time.defaultProps = {
  timeHandler: (e) => {
    console.log("default time", e);
  },
};
