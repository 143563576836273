import React from "react";

const FavoriteIcon = ({ color }) => {
  return (
    <svg
      width="23"
      height="20"
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.48959 2.75621C0.50347 4.74235 0.50347 7.96253 2.48959 9.94867L11.1716 18.6308L19.8535 9.94867C21.8396 7.96253 21.8396 4.74235 19.8535 2.75621C17.8674 0.770066 14.6473 0.770066 12.6611 2.75621L11.1716 4.24588L9.68196 2.75621C7.69585 0.770066 4.47571 0.770066 2.48959 2.75621Z"
        stroke={color}
        strokeWidth="1.89869"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FavoriteIcon;
