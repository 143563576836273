import React from "react";

const Right = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="10.6094"
        cy="11.0114"
        rx="10.5"
        ry="10.3845"
        fill="#E63369"
      />
      <path
        d="M8.96875 14.2574L12.25 11.0122L8.96875 7.76709"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Right;
