import React from "react";
import BoxCom from "../../../UI/BoxCom/BoxCom";
import { Grid } from "@mui/material";
import {
  Details,
  FieldLabel,
  SalesCategory,
  FieldWrapper,
  ProfileButton,
} from "./SubscriptionCard.style ";
import { useNavigate } from "react-router-dom";
import {
  updateService,
  getServiceById,
  postService,
  postServiceByCoustomId,
} from "./../../../../services/services.js";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "./../../../../redux/reducers/auth";
import {
  confirmPayment,
  payToConnectedAccount,
} from "./../../../../Utils/stripeConnects.ts";
import English from "../../../../config/Langugage/English.json";
import Polish from "../../../../config/Langugage/Polish.json";
import { CURRENCY } from '../../../../config/constant';
const SubscriptionCard = ({ setShowForm, bankDetailsData }) => {
  const navigate = useNavigate();
  const { price } = useParams();
  const dispatch = useDispatch();
  const { products, subscription } = useSelector((state) => state);
  const { product_id, productName } = subscription;
  const { couchId } = products;
  const store = useSelector((state) => state);
  const { auth, user: _user } = store;
  const selector = auth.language;
  const { enqueueSnackbar } = useSnackbar();

  const payNowHandler = async () => {
    let uid = await JSON.parse(localStorage.getItem("userData"));
    uid = uid.uid;
    let user = await getServiceById("Users", uid);
    let user1 = user.data();
    switch (productName) {
      case "course":
        if (user1.PurchasedCourse.includes(product_id)) {
          enqueueSnackbar("Course purchased Already", {
            variant: "info",
            autoHideDuration: 3000,
          });
        } else if (product_id) {
          await createRecharge(uid, user1);
          sendNotifications("Successfully Purchased a Course", uid);
          // navigate("/subscriptionSuccess");
        } else {
          enqueueSnackbar(
            "Product id is undefiend! Revisit the payment method again Please!",
            {
              variant: "warning",
              autoHideDuration: 6000,
            }
          );
        }
        break;
      case "product":
        if (user1.PurchasedProducts.includes(product_id)) {
          enqueueSnackbar("Product purchased Already", {
            variant: "info",
            autoHideDuration: 3000,
          });
        } else if (product_id) {
          await createRecharge(uid, user1);
          sendNotifications("Successfully Purchased a Product", uid);
          // navigate("/subscriptionSuccess");
        } else {
          enqueueSnackbar(
            "Product id is undefiend! Revisit the payment method again Please!",
            {
              variant: "warning",
              autoHideDuration: 6000,
            }
          );
        }
        break;
      case "blog":
        if (product_id) {
          await createRecharge(uid, user1);
          sendNotifications("Blog Purchased Successfully", uid);
        }
        break;
      case "membership":
        await createRecharge(uid, user1);
        sendNotifications("Congratulations! Now you become a member", uid);
        break;
      default:
        break;
    }
  };

  const sendNotifications = (notificationData, uid) => {
    const payload = {
      Date: moment(Date.now()).format("L"),
      NotificationText: notificationData,
      userId: uid,
    };
    postService("Notifications", payload);
  };

  const addCourseId = async (uid, user1) => {
    let arr = [...user1.PurchasedCourse];
    arr.push(product_id);
    let payload = { ...user1, PurchasedCourse: arr };
    await updateService("Users", uid, payload);
  };

  const addProductId = async (uid, user1) => {
    let arr1 = [...user1.PurchasedProducts];
    arr1.push(product_id);
    let payload1 = { ...user1, PurchasedProducts: arr1 };
    await updateService("Users", uid, payload1);
  };

  const addBlog = async (uid, user1) => {
    let arr1 = [...user1.PurchasedBlogs];
    arr1.push(product_id);
    let payload1 = { ...user1, PurchasedBlogs: arr1 };
    await updateService("Users", uid, payload1);
  };

  // const handleMemberShip = async (uid,amount,cardDetails) => {
  const buyTicketOrMemberShip = async (uid, user1) => {
    try {
      console.log("uid", uid, user1);
      const payload = {
        ...user1,
        membership: true,
        membershipDate: moment().format("YYYY-MM-DD"),
      };
      await postServiceByCoustomId("Users", payload, uid);
      dispatch(authActions.setUserData(payload));
    } catch (e) {
      console.log("error occured", e);
    }
  };
  // };
  const createRecharge = async (uid, user1) => {
    let amount = price * 100;
    let cardDetails = {
      number: bankDetailsData?.cardNumber,
      expiryMonth: "11",
      expiryYear: "23",
      cvc: bankDetailsData?.cvv,
    };

    try {
      await confirmPayment(amount, cardDetails);
      // payToConnectedAccount(amount, )
      if (productName === "course") {
        addCourseId(uid, user1);
        paymentTransfer(amount);
      } else if (productName === "product") {
        addProductId(uid, user1);
        paymentTransfer(amount);
      } else if (productName === "blog") {
        addBlog(uid, user1);
        paymentTransfer(amount);
      } else if (productName === "membership") {
        // addBlog(uid, user1);
        buyTicketOrMemberShip(uid, user1);
        // paymentTransfer(amount);
      } else {
        alert("Product name not available");
      }
      navigate("/subscriptionSuccess");
    } catch (e) {
      console.log("end error");
      alert(e);
    }
    // }
  };

  const paymentTransfer = async (amount) => {
    let user = await getServiceById("Users", couchId);
    let user1 = user.data();
    const connectedAccountId = user1?.stripe_user_id;
    if (connectedAccountId !== undefined) {
      payToConnectedAccount(amount, connectedAccountId);
    } else {
      console.log("not connectedAccountId", connectedAccountId);
    }
  };
  return (
    <>
      <Grid
        border={true}
        sx={{ marginTop: "50px" }}
        item
        xs={12}
        md={8}
        lg={10}
      >
        <BoxCom sx={{ display: "flex", justifyContent: "space-between" }}>
          <FieldLabel>
            {bankDetailsData !== undefined ? selector === "English" ? ` ${English["385"]}` : Polish["385"] : selector === "English" ? ` ${English["149"]}` : Polish["149"]}
          </FieldLabel>
          <Details onClick={() => setShowForm(true)}>{selector === "English" ? ` ${English["386"]}` : Polish["386"]}</Details>
        </BoxCom>
        <FieldWrapper height={"69px"} border={true}>
          <SalesCategory>{bankDetailsData?.cardNumber}</SalesCategory>
          {/* <SubscriptionCardIcon /> */}
        </FieldWrapper>
      </Grid>

      <Grid sx={{ marginTop: "70px" }} item xs={12} md={8} lg={10}>
        <FieldLabel>{selector === "English" ? ` ${English["387"]}` : Polish["387"]}</FieldLabel>
        <FieldWrapper height={"69px"}>
          <SalesCategory>{price} {CURRENCY}</SalesCategory>
        </FieldWrapper>
      </Grid>

      <Grid sx={{ marginTop: "70px" }} item xs={12} md={8} lg={10}>
        <ProfileButton variant="contained" onClick={payNowHandler}>
          {selector === "English" ? ` ${English["388"]}` : Polish["388"]}
        </ProfileButton>
      </Grid>
    </>
  );
};

export default SubscriptionCard;
