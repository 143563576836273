import { Grid, Paper, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Colors } from "../../../../config/palette";
import { useParams, useNavigate } from "react-router-dom";
import VideoPlayer from "./../../../UI/VideoPlayer/VideoPlayer.js";
import { useSelector } from "react-redux";
import {
  ExploreCourseVideo,
  ExploreCoursePriceText,
  ExploreCoursePriceHeading,
  PriceButton,
} from "./SingleCourse.style";
import CourseAccordions from "../CourseVideos/CourseVideoAccordian";
import { getServiceById } from "./../../../../services/services.js";

const SingleCourse = () => {
  const { id: CourseId } = useParams();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.userData);
  const [courseData, setCourseData] = useState({});
  const [puchasedAlready, setPuchasedAlready] = useState(false);

  const getCourse = async () => {
    let user1 = currentUser;
    if (user1.PurchasedCourse.includes(CourseId)) {
      setPuchasedAlready(false);
    } else {
      setPuchasedAlready(true);
    }
    const courseGetFromDb = await getServiceById("Courses", CourseId);

    setCourseData(courseGetFromDb.data());
  };
  useEffect(() => {
    getCourse();
  }, []);

  return (
    <Paper elevation={0} square={true} style={{ background: Colors.secondary }}>
      <Typography
        sx={{
          color: Colors.white,
          paddingTop: "40px",
          fontSize: "20px",
          fontFamily: '"Poppins", "sans-serif"',
          paddingLeft: "20px",
        }}
      >
        {courseData.Type}
      </Typography>
      <Grid
        container
        spacing={6}
        sx={{ paddingBottom: "50px", paddingLeft: { md: "20px", sm: "0px" } }}
      >
        <Grid
          item
          xs={12}
          lg={6}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
          }}
        >
          <ExploreCourseVideo>
            <VideoPlayer
              videoLink={
                courseData.Video === undefined
                  ? "https://firebasestorage.googleapis.com:443/v0/b/evolove-56393.appspot.com/o/CoverThumbnail%2Ftrim.5EA329F8-894F-471E-A9C8-DA27998182FB.MOV?alt=media&token=e6bc8f64-a115-4ef8-af6c-e76ed6754f1b"
                  : courseData.Video
              }
            />
          </ExploreCourseVideo>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography style={{ paddingTop: "20px", color: "#8D98AF" }}>
                {courseData.CoachName}
              </Typography>

              <Typography style={{ paddingTop: "10px", color: "white" }}>
                {courseData.Title}
              </Typography>
            </div>

            <IconButton
              aria-label="share"
              style={{
                color: Colors.darkGrey,
                background: "#2A2A3F",
                boxShadow: "0px 3.39814px 6.79629px rgba(0, 0, 0, 0.05)",
              }}
            >
              <FavoriteIcon />
            </IconButton>
          </div>
          <div style={{ marginTop: "30px" }}>
            {" "}
            <CourseAccordions module={courseData.Modules} />
          </div>
        </Grid>
        <Grid item xs={12} lg={6}>
          <ExploreCoursePriceText>
            <Typography
              sx={{
                color: "white",
                fontSize: "15px",
                fontFamily: "Poppins",
                fontWeight: "600px",
              }}
            >
              Price
            </Typography>
            <Typography
              sx={{
                color: "white",
                fontSize: "15px",
                fontFamily: "Poppins",
                fontWeight: "600px",
              }}
            >
              {courseData.PriceType === "free"
                ? courseData.PriceType
                : courseData.Price}
            </Typography>
          </ExploreCoursePriceText>

          <Typography
            sx={{
              color: Colors.white,
              paddingTop: "20px",
              fontSize: "20px",
              fontFamily: '"Poppins", "sans-serif"',
            }}
          >
            Description
          </Typography>
          <ExploreCoursePriceHeading>
            {courseData.Discription}
          </ExploreCoursePriceHeading>
          <PriceButton
            puchasedAlready={puchasedAlready}
            onClick={() =>
              puchasedAlready
                ? navigate(`/subscription/${courseData.Price}`)
                : null
            }
          >
            {!puchasedAlready ? "Purchased" : "Buy Now"}
          </PriceButton>
        </Grid>
      </Grid>
    </Paper>
  );
};
export default SingleCourse;
