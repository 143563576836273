import React from "react";
import { Wrapper } from "./VideoPlayer.style";
import PropTypes from "prop-types";

const VideoPlayer = ({ videoLink, maxHeight }) => {
  return (
    <div>
      <Wrapper maxHeight={maxHeight} controls>
        <source src={videoLink} type="video/mp4" />
      </Wrapper>
    </div>
  );
};

export default VideoPlayer;
VideoPlayer.propTypes = {
  maxHeight: PropTypes.string,
};

VideoPlayer.defaultProps = {
  maxHeight: "20%",
};
