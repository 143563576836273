import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  product_id: "",
  productName: '',
  itemData: {}
};

const subscription = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    productIdState: (state, action) => {
      const {productId, productName, item} = action.payload
      state.product_id = productId;
      state.productName =productName;
      state.itemData = item
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const subscriptionActions = subscription.actions;

export const subscriptionReducer = subscription.reducer;
