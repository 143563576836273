import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import BoxCom from "../../../UI/BoxCom/BoxCom";
import English from "../../../../config/Langugage/English.json";
import Polish from "../../../../config/Langugage/Polish.json";
import {
  BlogsHeading,
  BlogsImage,
  BlogsText,
  Tag,
  TagHeadingWrappper,
  // PriceButton,
} from "./BlogCard.style";
import { CURRENCY } from "../../../../config/constant";

const BlogCard = ({
  tag,
  title,
  description,
  image,
  blogId,
  price,
  priceType,
  purchasedAlready,
  selector
}) => {

  return (
    <Grid
      item
      xs={12}
      sm={6}
      lg={4}
      style={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
      }}
    >
      <Link to={`/blogs/${blogId}`} style={{ textDecoration: "none" }}>
        <BlogsImage src={image} alt="Paris" />
        <TagHeadingWrappper>
          <BlogsHeading variant="h6">
            {title?.length < 14 ? title : `${title?.slice(0, 15)}...`}
          </BlogsHeading>
          {tag && (
            <BoxCom>
              <Tag purchasedAlready={purchasedAlready} priceType={priceType} price={{ price }}>
                {priceType === "free" || price === 0
                  ? selector === "English" ? ` ${English["411"]}` : Polish["411"]
                  : priceType === "Evolove Subscription" ? selector === "English" ? ` ${English["260"]}` : Polish["260"]
                    : purchasedAlready
                      ? selector === "English" ? ` ${English["412"]}` : Polish["412"]
                      : `${price} ${CURRENCY}`}
              </Tag>
            </BoxCom>
          )}
        </TagHeadingWrappper>

        <BlogsText>
          {description?.length < 90
            ? description
            : `${description?.slice(0, 99)}...`}
        </BlogsText>
      </Link>
    </Grid>
  );
};

export default BlogCard;
