import { Box, Container, Grid } from "@mui/material";
import React, { useState } from "react";
import Button from "../../../../UI/Button/Button";
import NavBar from "../../../../UI/NavBar/NavBarMain/NavBar";
import TextfieldComp from "../../../../UI/TextFieldCom/Textfield";
import { LinkContainer } from "../../Login/LoginMain/Login.style";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import English from "./../../../../../config/Langugage/English.json";
import Polish from "./../../../../../config/Langugage/Polish.json";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  FormContainer,
  Heading,
  PaperContainer,
  ErrorText,
} from "./SignUp.style";
import {
  postServiceByCoustomId,
  signup,
  postService,
} from "./../../../../../services/services";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function SignUp() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [errorData, setErrorData] = useState("");
  const [error, setError] = useState(false);
  const selector = useSelector((state) => state.auth.language);

  const userModel = {
    About: "",
    ArticlesEarnings: 0,
    BankDetails: [],
    Coach: false,
    Contact: "",
    CourseEarnings: 0,
    EbooksEarnings: 0,
    Email: "",
    FavoriteProducts: [],
    FavoriteTickets: [],
    Favorites: [],
    FullName: "",
    ProfileImage: "",
    PurchasedBlogs: [],
    PurchasedCourse: [],
    PurchasedProducts: [],
    SoldArticles: 0,
    SoldCourses: 0,
    SoldEbooks: 0,
    SubscribeCoach: [],
    TotalEarnings: 0,
    WalletRecentActivity: [],
    id: "",
    stripe_user_id: "",
  };
  const saveUserData = async (path, data, id) => {
    try {
      await postServiceByCoustomId(path, data, id);
      // Successfully LoggedIn
      enqueueSnackbar(selector === "English" ? English[373] : Polish[373], {
        variant: "success",
        autoHideDuration: 4000,
      });
      navigate("/home");
      setError(false);
    } catch (error) {
      setError(true);
      enqueueSnackbar(error.message, {
        variant: "error",
        autoHideDuration: 4000,
      });
    }
  };

  const validationSchema = yup.object({
    // Username Required
    name: yup
      .string()
      .required(selector === "English" ? English[365] : Polish[365]),
    email: yup
      .string()
      // Email format is incorrect
      .email(selector === "English" ? English[369] : Polish[369])
      // Email required!
      .required(selector === "English" ? English[366] : Polish[366]),
    phoneNumber: yup
      .string()
      // Phone number required!
      .required(selector === "English" ? English[377] : Polish[377])
      // Phone number is not valid
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        selector === "English" ? English[378] : Polish[378]
      ),

    password: yup
      // Enter your password
      .string(selector === "English" ? English[370] : Polish[370])
      // Password should be of minimum 8 characters length
      .min(8, selector === "English" ? English[371] : Polish[371])

      .required(selector === "English" ? English[372] : Polish[372]),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phoneNumber: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const { email, password, phoneNumber, name } = values;
      signup(email, password)
        .then((res) => {
          userModel.FullName = name;
          userModel.Contact = phoneNumber;
          userModel.Email = email;
          userModel.id = res.user.uid;
          saveUserData("Users", userModel, res.user.uid);

          const payload = {
            Date: moment(Date.now()).format("L"),
            NotificationText: "Welcome to Evolove!",
            userId: res?.user.uid,
          };

          postService("Notifications", payload);
        })
        .catch((error) => {
          setError(true);
          enqueueSnackbar(error.message, {
            variant: "error",
            autoHideDuration: 4000,
          });
          setErrorData(error.code);
        });
    },
  });
  return (
    <Box sx={{ background: "#131315" }}>
      <NavBar />
      <Container style={{ padding: "0px" }}>
        <PaperContainer elevation={0}>
          <Heading>
            {selector === "English" ? ` ${English["327"]}` : Polish["327"]}
          </Heading>

          <FormContainer>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextfieldComp
                    height="60px"
                    placeholder={
                      selector === "English" ? ` ${English["4"]}` : Polish["4"]
                    }
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    name="name"
                    id="name"
                    value={formik.values.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextfieldComp
                    height="60px"
                    placeholder="Email"
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    name="email"
                    id="email"
                    value={formik.values.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextfieldComp
                    height="60px"
                    placeholder={
                      selector === "English" ? ` ${English["6"]}` : Polish["6"]
                    }
                    name="phoneNumber"
                    onChange={formik.handleChange}
                    error={
                      formik.touched.phoneNumber &&
                      Boolean(formik.errors.phoneNumber)
                    }
                    helperText={
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                    }
                    id="phoneNumber"
                    value={formik.values.phoneNumber}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextfieldComp
                    height="60px"
                    type="password"
                    placeholder={
                      selector === "English" ? ` ${English["7"]}` : Polish["7"]
                    }
                    name="password"
                    id="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />
                </Grid>
                <div style={{ width: "100%" }}>
                  {error ? <ErrorText>{errorData}</ErrorText> : null}
                </div>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "30px",
                  }}
                >
                  <Button variant="contained" type="submit">
                    {selector === "English"
                      ? ` ${English["327"]}`
                      : Polish["327"]}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormContainer>

          <LinkContainer sx={{ textAlign: "center" }}>
            <p>{selector === "English" ? ` ${English["9"]}` : Polish["9"]} </p>

            <Link
              style={{
                color: "#295BE0",
                fontSize: "20px",
                fontFamily: '"Poppins", "sans-serif"',
                textDecoration: "none",
              }}
              to="/aboutUs"
            >
              {selector === "English" ? ` ${English["328"]}` : Polish["328"]}
            </Link>
          </LinkContainer>
        </PaperContainer>
      </Container>
      {/* <Footer /> */}
    </Box>
  );
}

export default SignUp;
