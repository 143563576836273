import React from "react";

const ShopIcon = ({ color }) => {
  return (
    <svg
      width="30"
      height="29"
      viewBox="0 0 30 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0748 27.4459C11.7703 27.4459 12.3341 26.8821 12.3341 26.1866C12.3341 25.4911 11.7703 24.9272 11.0748 24.9272C10.3793 24.9272 9.81543 25.4911 9.81543 26.1866C9.81543 26.8821 10.3793 27.4459 11.0748 27.4459Z"
        stroke={color}
        strokeWidth="1.67911"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.9273 27.4459C25.6228 27.4459 26.1866 26.8821 26.1866 26.1866C26.1866 25.4911 25.6228 24.9272 24.9273 24.9272C24.2318 24.9272 23.668 25.4911 23.668 26.1866C23.668 26.8821 24.2318 27.4459 24.9273 27.4459Z"
        stroke={color}
        strokeWidth="1.67911"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 1H6.03732L9.41232 17.8624C9.52748 18.4422 9.8429 18.963 10.3034 19.3337C10.7638 19.7044 11.34 19.9013 11.931 19.8899H24.1717C24.7627 19.9013 25.3388 19.7044 25.7993 19.3337C26.2598 18.963 26.5752 18.4422 26.6903 17.8624L28.7053 7.29665H7.29665"
        stroke={color}
        strokeWidth="1.67911"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ShopIcon;
