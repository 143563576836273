import React from "react";
import { TaskDeleteIcon } from "../../../../assets/icons/TaskDeleteIcon";
import { TaskEditIcon } from "../../../../assets/icons/TaskEditIcon";
// import TaskShareIcon from "../../../../assets/icons/TaskShareIcon";
import TaskSmallClockIcon from "../../../../assets/icons/TaskSmallClockIcon";
import TaskUncompledIcon from "../../../../assets/icons/TaskUncompletedIcon";
import BoxCom from "../../../UI/BoxCom/BoxCom";
import IconButton from "@mui/material/IconButton";
import PromptSnackbar from "./../../../UI/Snackbar/PromptSnackbar.js";

import {
  ProfileButton,
  SubHeading,
  TaskClockIconAndTimeWrapper,
  TaskDateAndTimeWrapper,
  TaskDetailTypography,
  TaskEstimatedTime,
  TaskIconAndTextWrapper,
  TaskTime,
  TaskTimerDetailsWrapper,
  TaskTimerDetailsWrapperStartColor,
  TaskTypography,
} from "./SingleTodo.style";

const SingleTodo = ({
  setCurrentPage,
  singleTaskData,
  handleCompleteButton,
  handleDeleteButton,
  handleEditButton,
  selector,
  English,
  Polish,
}) => {
  console.log("in task", singleTaskData);

  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const completeButtonHandler = () => {
    handleCompleteButton();
  };
  const deleteButtonHandler = (newState) => () => {
    setState({ open: true, ...newState });
  };
  const deleteUserHandler = () => {
    handleDeleteButton();

    setState({ ...state, open: false });
  };
  const editButtonHandler = () => {
    handleEditButton("update");
  };
  return (
    <>
      <PromptSnackbar
        setState={setState}
        state={state}
        clickHandler={deleteUserHandler}
        selector={selector}
      >
        {selector === "English" ? ` ${English["399"]}` : Polish["399"]}
      </PromptSnackbar>
      {/* TO-DO */}
      <SubHeading>
        {selector === "English" ? ` ${English["220"]}` : Polish["220"]}
      </SubHeading>
      <BoxCom>
        <TaskIconAndTextWrapper>
          <TaskUncompledIcon color="yellow" />
          <TaskTypography>{singleTaskData.TaskName}</TaskTypography>
        </TaskIconAndTextWrapper>
        <TaskDetailTypography>
          {singleTaskData.description}
        </TaskDetailTypography>
        <TaskTimerDetailsWrapper>
          <TaskTimerDetailsWrapperStartColor></TaskTimerDetailsWrapperStartColor>
          <div>
            <TaskDateAndTimeWrapper>
              <TaskTime sx={{ marginLeft: "10px" }}>
                {singleTaskData.Date}
              </TaskTime>
              <TaskTime
                sx={{ marginLeft: "25px" }}
              >{`${singleTaskData.StartTime} - ${singleTaskData.EndTime}`}</TaskTime>
            </TaskDateAndTimeWrapper>

            <TaskClockIconAndTimeWrapper>
              <TaskSmallClockIcon />
              <TaskEstimatedTime sx={{ marginLeft: "10px", marginTop: "2px" }}>
                0 hour
              </TaskEstimatedTime>
            </TaskClockIconAndTimeWrapper>
          </div>
        </TaskTimerDetailsWrapper>
      </BoxCom>

      <BoxCom
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          marginTop: "40px",
        }}
      >
        <BoxCom>
          <IconButton
            color="error"
            component="label"
            onClick={deleteButtonHandler({
              vertical: "top",
              horizontal: "center",
            })}
          >
            <TaskDeleteIcon />
          </IconButton>
        </BoxCom>
        <BoxCom sx={{ marginLeft: "15px" }}>
          <IconButton
            color="info"
            component="label"
            onClick={editButtonHandler}
          >
            <TaskEditIcon />
          </IconButton>
        </BoxCom>
        <BoxCom sx={{ marginLeft: "15px" }}>{/* <TaskShareIcon /> */}</BoxCom>

        <ProfileButton
          complete={singleTaskData.completed}
          disabled={singleTaskData.completed ? true : false}
          variant="contained"
          onClick={completeButtonHandler}
          sx={{ marginLeft: "15px" }}
        >
          {singleTaskData.completed
            ? selector === "English"
              ? English["280"]
              : Polish["280"]
            : selector === "English"
            ? English["87"]
            : Polish["87"]}
        </ProfileButton>
      </BoxCom>
    </>
  );
};

export default SingleTodo;
