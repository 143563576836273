import React from "react";
import Stack from "@mui/material/Stack";
import { Colors } from "./../../../config/palette";
import { SkeletonWrapper } from "./blockSkeleton.style";

const BlogSkeleton = () => {
  return (
    <div>
      <Stack spacing={1}>
        {/* For variant="text", adjust the height via font-size */}
        <SkeletonWrapper
          variant="rounded"
          width={335}
          height={150}
          style={{ borderRadius: "20px" }}
        />
        <SkeletonWrapper
          variant="text"
          sx={{ fontSize: "1.5rem" }}
          width={120}
        />

        {/* For other variants, adjust the size with `width` and `height` */}
        <SkeletonWrapper variant="rectangular" width={335} height={100} />
      </Stack>
    </div>
  );
};

export default BlogSkeleton;
