import React from "react";
import Stack from "@mui/material/Stack";
import { SkeletonWrapper, TextWrapper } from "./coachSkeleton.style";

const CoachSkeleton = () => {
  return (
    <div>
      <Stack direction="row" alignItems="center" spacing={1.5}>
        <SkeletonWrapper variant="circular" height={55} width={55} />
        <TextWrapper>
          <SkeletonWrapper
            variant="text"
            sx={{ fontSize: "1.3rem" }}
            width={120}
          />
          <SkeletonWrapper
            variant="text"
            sx={{ fontSize: "1.3rem" }}
            width={120}
          />
        </TextWrapper>
      </Stack>
    </div>
  );
};

export default CoachSkeleton;
