import React, { useEffect, useState } from "react";
import {
  Grid,
  List,
  ListItemText,
  Typography,
  Box,
  ListItem,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Colors } from "../../../../config/palette";
import { Link } from "react-router-dom";
import { getService } from "./../../../../services/services";
import English from "./../../../../config/Langugage/English.json";
import Polish from "./../../../../config/Langugage/Polish.json";
import { useSelector } from "react-redux";

export default function Footer() {
  const [aboutUsData, setAboutUsData] = useState("");
  const selector = useSelector((state) => state.auth.language);
  const getAboutUs = async () => {
    const aboutUs = await getService("AddPolicy");
    setAboutUsData(aboutUs.docs[0]?.data()?.descriptionName);
  };
  useEffect(() => {
    getAboutUs();
  });
  function openInNewTab(url) {
    var win = window.open(url, '_blank');
    win.focus();
  }
  return (
    <Box
      sx={{
        background: Colors.dark,
        color: Colors.light,
        fontSize: { xs: "12px", md: "14px" },
      }}
    >
      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={12} md={3}>
          <List>
            <ListItem>
              <ListItemText
                primary={
                  selector === "English" ? ` ${English["325"]}` : Polish["325"]
                }
                sx={{
                  color: Colors.light,
                  textAlign: { xs: "center", md: "left" },
                  fontSize: "30px",
                  fontFamily: '"Poppins", "sans-serif"',
                }}
                disableTypography
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <Box
                sx={{
                  mt: "34px",
                  color: Colors.dove_gray,
                  paddingLeft: "20px",
                }}
              >
                <FacebookIcon sx={{ mr: 1 }} onClick={() => openInNewTab(`https://www.facebook.com/evolovepl`)} />
                <InstagramIcon sx={{ mr: 1 }} onClick={() => openInNewTab(`https://www.instagram.com/evolove.pl/`)} />

              </Box>
              {/* <ListItemText>
                <Typography
                  lineHeight={2}
                  variant="body1"
                  sx={{
                    textAlign: { xs: "center", md: "left" },
                    fontFamily: '"Poppins", "sans-serif"',
                  }}
                >
                  {`${aboutUsData.slice(0, 80)}...`}
                </Typography>
              </ListItemText> */}
            </ListItem>
          </List>
        </Grid>

        <Grid item sm={4} md={3}>
          <List>
            <ListItem>
              {/* Search */}
              <ListItemText
                primary={
                  selector === "English" ? ` ${English["222"]}` : Polish["222"]
                }
                sx={{
                  color: Colors.light,
                  fontSize: "30px",
                  fontFamily: '"Poppins", "sans-serif"',
                }}
                disableTypography
              />
            </ListItem>
            <Link to="/login" style={{ textDecoration: "none" }}>
            <ListItem>
              {/* Browse Categories */}
              <ListItemText
                primary={
                  selector === "English" ? ` ${English["337"]}` : Polish["337"]
                }
                sx={{
                  color: Colors.light,
                  fontFamily: '"Poppins", "sans-serif"',
                }}
                disableTypography
              />
            </ListItem>
            </Link>
            <Link to="/login" style={{ textDecoration: "none" }}>
            <ListItem>
              {/* Browse Courses */}
              <ListItemText
                primary={
                  selector === "English" ? ` ${English["338"]}` : Polish["338"]
                }
                sx={{
                  color: Colors.light,
                  fontFamily: '"Poppins", "sans-serif"',
                }}
                disableTypography
              />
            </ListItem>
            </Link>
            <Link to="/login" style={{ textDecoration: "none" }}>
            <ListItem>
              {/* Browse Products */}
              <ListItemText
                primary={
                  selector === "English" ? ` ${English["339"]}` : Polish["339"]
                }
                sx={{
                  padding: "0",
                  color: Colors.light,
                  fontFamily: '"Poppins", "sans-serif"',
                }}
                disableTypography
              />
            </ListItem>
            </Link>
          </List>
        </Grid>
        <Grid item sm={4} md={3}>
          <List>
            <ListItem>
              {/* Support */}
              <ListItemText
                primary={
                  selector === "English" ? ` ${English["340"]}` : Polish["340"]
                }
                sx={{
                  color: Colors.light,
                  fontSize: "30px",
                  fontFamily: '"Poppins", "sans-serif"',
                }}
                disableTypography
              />
            </ListItem>
            <Link to="/contactUs" style={{ textDecoration: "none" }}>
              <ListItem>
                {/* Contact Us */}
                <ListItemText
                  primary={
                    selector === "English"
                      ? ` ${English["326"]}`
                      : Polish["326"]
                  }
                  sx={{
                    color: Colors.light,
                    fontFamily: '"Poppins", "sans-serif"',
                  }}
                  disableTypography
                />
              </ListItem>
            </Link>
            {/* Share With Friends */}

            {/* <ListItem>
              <ListItemText
                primary={
                  selector === "English" ? ` ${English["341"]}` : Polish["341"]
                }
                sx={{
                  padding: "0",
                  color: Colors.light,
                  fontFamily: '"Poppins", "sans-serif"',
                }}
                disableTypography
              />
            </ListItem> */}
          </List>
        </Grid>
      </Grid>

      {/* © 2022 Evolove All Rights Reserved. Terms of Use | Privacy Policy */}
      <Typography
        variant="body1"
        sx={{
          textAlign: "center",
          mt: "34px",
          fontFamily: '"Poppins", "sans-serif"',
        }}
      >
        © 2022 {selector === "English" ? ` ${English["342"]}` : Polish["342"]}.{" "}
        {selector === "English" ? ` ${English["343"]}` : Polish["343"]} |
        {selector === "English" ? ` ${English["12"]}` : Polish["12"]}
      </Typography>
    </Box>
  );
}
