import React from "react";

export const EvoloveIcon = () => {
  return (
    <svg
      width="59"
      height="70"
      viewBox="0 0 59 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.71142 63.241C8.75554 63.3427 8.77831 63.4524 8.77831 63.5633C8.77831 63.6742 8.75554 63.7839 8.71142 63.8857C8.66862 63.9862 8.60552 64.0767 8.52607 64.1517C8.44661 64.2266 8.35252 64.2844 8.2497 64.3213L3.5236 66.2204C3.42343 66.2584 3.31711 66.2776 3.20998 66.277C3.07049 66.2767 2.93326 66.2418 2.81053 66.1755C2.6878 66.1092 2.58339 66.0136 2.50661 65.8971C2.42984 65.7807 2.38308 65.647 2.37052 65.5081C2.35796 65.3692 2.37999 65.2293 2.43464 65.101C2.47721 65.0003 2.54024 64.9096 2.61972 64.8346C2.69921 64.7597 2.79341 64.702 2.89636 64.6654L6.72951 63.1278C6.37719 62.6768 5.89292 62.3471 5.34425 62.1846C4.79557 62.0221 4.20986 62.0349 3.6688 62.2212C3.12775 62.4075 2.65833 62.7581 2.32604 63.2239C1.99374 63.6898 1.81514 64.2478 1.81514 64.82C1.81514 65.3922 1.99374 65.9502 2.32604 66.4161C2.65833 66.8819 3.12775 67.2325 3.6688 67.4188C4.20986 67.6051 4.79557 67.6179 5.34425 67.4554C5.89292 67.2929 6.37719 66.9632 6.72951 66.5123C6.86774 66.3363 7.07009 66.2224 7.29219 66.1955C7.5143 66.1685 7.73802 66.2307 7.9143 66.3685C8.00498 66.4342 8.0809 66.5181 8.13717 66.6149C8.19343 66.7116 8.2288 66.8191 8.24099 66.9304C8.25664 67.0413 8.24924 67.1543 8.21926 67.2622C8.18928 67.3701 8.13737 67.4707 8.06676 67.5577C7.66432 68.1 7.13811 68.5383 6.53196 68.8361C5.92581 69.1338 5.25729 69.2824 4.58207 69.2695C3.9872 69.2881 3.39464 69.1871 2.83958 68.9723C2.28452 68.7575 1.77827 68.4334 1.35089 68.0192C0.923502 67.605 0.583695 67.1091 0.351637 66.561C0.119579 66.013 0 65.4239 0 64.8287C0 64.2335 0.119579 63.6444 0.351637 63.0964C0.583695 62.5483 0.923502 62.0525 1.35089 61.6383C1.77827 61.2241 2.28452 60.8999 2.83958 60.6852C3.39464 60.4704 3.9872 60.3693 4.58207 60.3879C5.47905 60.3879 6.35507 60.6591 7.09512 61.166C7.83517 61.6728 8.40467 62.3916 8.72885 63.2279L8.71142 63.241Z"
        fill="#D60B52"
      />
      <path
        d="M17.2053 60.4654C17.3065 60.5105 17.3974 60.5759 17.4723 60.6576C17.5472 60.7393 17.6046 60.8354 17.6409 60.9402C17.6799 61.0439 17.6977 61.1544 17.6932 61.2651C17.6887 61.3759 17.6621 61.4846 17.6148 61.5848L14.3174 68.7807C14.2554 68.9323 14.1481 69.0611 14.01 69.1492C13.872 69.2374 13.71 69.2806 13.5464 69.2729C13.3833 69.2809 13.2217 69.2378 13.0842 69.1496C12.9468 69.0613 12.8403 68.9324 12.7797 68.7807L9.47802 61.5848C9.4339 61.4831 9.41113 61.3734 9.41113 61.2625C9.41113 61.1516 9.4339 61.0419 9.47802 60.9402C9.51285 60.8347 9.56969 60.7378 9.64478 60.656C9.71988 60.5741 9.81151 60.5091 9.9136 60.4654C10.0147 60.4159 10.125 60.388 10.2375 60.3835C10.35 60.379 10.4622 60.398 10.567 60.4392C10.6695 60.4776 10.7634 60.5358 10.8434 60.6106C10.9234 60.6853 10.9878 60.7751 11.0331 60.8748L13.5638 66.3893L16.0946 60.8748C16.1413 60.7739 16.2077 60.6833 16.29 60.6085C16.3723 60.5337 16.4688 60.4761 16.5737 60.4392C16.675 60.3993 16.7835 60.3809 16.8923 60.3854C17.0011 60.3899 17.1077 60.4172 17.2053 60.4654Z"
        fill="#662483"
      />
      <path
        d="M22.7984 60.3911C23.8226 60.3918 24.8149 60.7474 25.6064 61.3974C26.3979 62.0473 26.9398 62.9515 27.1398 63.9559C27.3398 64.9604 27.1855 66.0031 26.7033 66.9067C26.2211 67.8102 25.4407 68.5188 24.4949 68.9118C23.5492 69.3048 22.4964 69.3579 21.5159 69.0621C20.5353 68.7664 19.6876 68.14 19.1168 67.2896C18.5461 66.4392 18.2877 65.4173 18.3855 64.3978C18.4833 63.3783 18.9314 62.4242 19.6535 61.6978C20.0607 61.2769 20.5499 60.9438 21.0907 60.719C21.6316 60.4943 22.2128 60.3827 22.7984 60.3911ZM22.7984 67.5782C23.4365 67.5784 24.0548 67.3573 24.5481 66.9526C25.0414 66.5479 25.3791 65.9847 25.5035 65.3589C25.628 64.7331 25.5316 64.0835 25.2308 63.5209C24.9299 62.9582 24.4432 62.5173 23.8537 62.2733C23.2641 62.0293 22.6082 61.9973 21.9977 62.1828C21.3872 62.3683 20.86 62.7598 20.5058 63.2905C20.1516 63.8212 19.9925 64.4583 20.0555 65.0933C20.1185 65.7282 20.3998 66.3216 20.8513 66.7724C21.1032 67.0332 21.406 67.2394 21.741 67.378C22.076 67.5167 22.4359 67.5848 22.7984 67.5782Z"
        fill="#D60B52"
      />
      <path
        d="M29.6631 57.0332C29.7742 57.0326 29.8843 57.0541 29.9871 57.0964C30.0899 57.1386 30.1833 57.2008 30.2619 57.2794C30.3405 57.358 30.4027 57.4514 30.445 57.5542C30.4872 57.657 30.5087 57.7671 30.5081 57.8783V68.4194C30.5098 68.5308 30.489 68.6415 30.4471 68.7447C30.4052 68.848 30.343 68.9418 30.2642 69.0205C30.1871 69.1022 30.0938 69.1669 29.9903 69.2104C29.8868 69.2539 29.7753 69.2753 29.6631 69.2732C29.5521 69.2751 29.442 69.2536 29.3399 69.2101C29.2378 69.1666 29.1461 69.102 29.0707 69.0205C28.9904 68.9426 28.927 68.8491 28.8843 68.7457C28.8416 68.6423 28.8205 68.5313 28.8224 68.4194V57.8783C28.8235 57.6552 28.9123 57.4416 29.0696 57.2835C29.2269 57.1254 29.4401 57.0355 29.6631 57.0332Z"
        fill="#662483"
      />
      <path
        d="M36.5409 60.3911C37.5653 60.3908 38.5581 60.7456 39.3503 61.395C40.1425 62.0443 40.6852 62.9482 40.886 63.9527C41.0867 64.9572 40.9332 66.0003 40.4514 66.9043C39.9697 67.8083 39.1895 68.5174 38.2438 68.9109C37.298 69.3045 36.2451 69.3581 35.2643 69.0626C34.2834 68.7672 33.4353 68.1409 32.8643 67.2905C32.2932 66.44 32.0345 65.418 32.1323 64.3983C32.23 63.3786 32.6781 62.4243 33.4003 61.6978C33.8068 61.277 34.2951 60.9439 34.8353 60.7192C35.3754 60.4944 35.9559 60.3828 36.5409 60.3911ZM36.5409 67.5782C37.1789 67.5784 37.7973 67.3573 38.2906 66.9526C38.7839 66.5479 39.1215 65.9847 39.246 65.3589C39.3705 64.7331 39.2741 64.0835 38.9732 63.5209C38.6724 62.9582 38.1857 62.5173 37.5962 62.2733C37.0066 62.0293 36.3507 61.9973 35.7402 62.1828C35.1297 62.3683 34.6024 62.7597 34.2483 63.2905C33.8941 63.8212 33.735 64.4583 33.798 65.0933C33.861 65.7282 34.1422 66.3216 34.5938 66.7724C34.8456 67.0332 35.1485 67.2394 35.4835 67.378C35.8184 67.5167 36.1784 67.5848 36.5409 67.5782Z"
        fill="#D60B52"
      />
      <path
        d="M49.4257 60.4644C49.5268 60.5097 49.6176 60.5752 49.6924 60.6569C49.7673 60.7385 49.8248 60.8346 49.8613 60.9392C49.9437 61.1461 49.9437 61.3768 49.8613 61.5838L46.5596 68.7797C46.4923 68.9271 46.3841 69.0521 46.2477 69.1398C46.1114 69.2274 45.9528 69.274 45.7907 69.274C45.6287 69.274 45.4701 69.2274 45.3337 69.1398C45.1974 69.0521 45.0892 68.9271 45.0219 68.7797L41.7202 61.5838C41.6761 61.4821 41.6533 61.3724 41.6533 61.2615C41.6533 61.1506 41.6761 61.0409 41.7202 60.9392C41.7545 60.8334 41.8112 60.7363 41.8863 60.6544C41.9615 60.5724 42.0534 60.5076 42.1558 60.4644C42.2575 60.417 42.3678 60.3908 42.48 60.3872C42.5921 60.3835 42.7039 60.4026 42.8084 60.4433C42.913 60.484 43.0083 60.5455 43.0885 60.6239C43.1687 60.7024 43.2322 60.7963 43.2752 60.8999L45.806 66.4145L48.3411 60.8999C48.3862 60.7987 48.4517 60.7079 48.5333 60.6329C48.615 60.558 48.7112 60.5006 48.8159 60.4644C48.9125 60.4241 49.0161 60.4034 49.1208 60.4034C49.2255 60.4034 49.3291 60.4241 49.4257 60.4644Z"
        fill="#662483"
      />
      <path
        d="M50.5492 63.2316C50.8734 62.3953 51.4429 61.6765 52.183 61.1697C52.923 60.6628 53.799 60.3916 54.696 60.3916C55.8497 60.4277 56.9441 60.9114 57.7474 61.7403C58.5507 62.5692 58.9999 63.6781 58.9999 64.8324C58.9999 65.9867 58.5507 67.0956 57.7474 67.9245C56.9441 68.7534 55.8497 69.2371 54.696 69.2732C54.0206 69.2872 53.3517 69.1391 52.7454 68.8412C52.1391 68.5434 51.613 68.1045 51.2113 67.5613C51.0834 67.3832 51.0293 67.1625 51.0604 66.9455C51.0915 66.7284 51.2053 66.5317 51.3781 66.3966C51.5508 66.2616 51.7692 66.1986 51.9873 66.2208C52.2055 66.243 52.4066 66.3488 52.5486 66.5159C52.7988 66.8558 53.1276 67.13 53.5069 67.315C53.8862 67.5001 54.3046 67.5906 54.7265 67.5788C55.194 67.5811 55.6545 67.4642 56.0643 67.2393C56.4742 67.0144 56.8201 66.6888 57.0693 66.2932C57.3185 65.8976 57.4628 65.445 57.4887 64.9782C57.5145 64.5114 57.4211 64.0456 57.2171 63.6249C57.0131 63.2042 56.7054 62.8424 56.3229 62.5736C55.9403 62.3047 55.4956 62.1378 55.0307 62.0884C54.5658 62.039 54.0959 62.1089 53.6655 62.2914C53.235 62.4738 52.8581 62.763 52.5704 63.1314L56.4035 64.6691C56.5065 64.7057 56.6007 64.7633 56.6801 64.8383C56.7596 64.9133 56.8227 65.004 56.8652 65.1046C56.9095 65.2056 56.9323 65.3146 56.9323 65.4248C56.9323 65.535 56.9095 65.644 56.8652 65.745C56.8116 65.9089 56.7051 66.0504 56.5624 66.1474C56.4198 66.2443 56.249 66.2912 56.0768 66.2807C55.9711 66.2814 55.8662 66.2621 55.7675 66.2241L51.0414 64.3249C50.9387 64.2841 50.845 64.2235 50.7657 64.1464C50.6864 64.0694 50.6231 63.9774 50.5794 63.8759C50.5357 63.7743 50.5125 63.6651 50.5111 63.5546C50.5096 63.444 50.53 63.3343 50.571 63.2316H50.5492Z"
        fill="#D60B52"
      />
      <path
        d="M51.3335 23.4623L46.1064 26.5593C44.6444 27.3802 43.0059 27.8363 41.33 27.8886C39.6541 27.9409 37.9903 27.588 36.48 26.8598C36.9144 28.8281 37.0233 30.8543 36.8023 32.8578C40.8623 33.9787 45.2011 33.4446 48.868 31.3725L54.1604 28.2363C54.7681 27.8512 55.2021 27.2447 55.3707 26.5453C55.5392 25.8459 55.429 25.1083 55.0635 24.4886C54.6979 23.869 54.1056 23.4159 53.4119 23.2251C52.7182 23.0344 51.9775 23.121 51.3465 23.4666L51.3335 23.4623Z"
        fill="url(#paint0_linear_2_7142)"
      />
      <path
        d="M7.92294 24.8C9.66528 23.7808 13.102 21.7509 13.15 21.7074C14.6112 20.8864 16.249 20.4304 17.9242 20.3781C19.5994 20.3258 21.2625 20.6787 22.772 21.4068C22.3412 19.438 22.2338 17.4122 22.4541 15.4088C18.3944 14.2894 14.0565 14.8267 10.3927 16.9029L5.11777 20.0478C4.54121 20.445 4.13672 21.046 3.98587 21.7297C3.83503 22.4133 3.94904 23.1287 4.30493 23.7317C4.66081 24.3346 5.23209 24.7801 5.90353 24.9784C6.57498 25.1766 7.29663 25.1129 7.92294 24.8Z"
        fill="url(#paint1_linear_2_7142)"
      />
      <path
        d="M34.2891 29.8215C34.2542 29.3119 34.1889 28.8066 34.1061 28.2969L28.7745 31.4593C28.6627 33.4555 27.979 35.3773 26.8048 36.9956C25.6307 38.6138 24.0158 39.86 22.1528 40.5856C20.2898 41.3111 18.2573 41.4853 16.298 41.0874C14.3387 40.6895 12.5352 39.7363 11.1028 38.3415L28.3956 28.0879L33.2654 25.2043L53.6595 13.1298C54.2912 12.7554 54.7484 12.1454 54.9305 11.4339C55.1127 10.7225 55.0049 9.96789 54.6309 9.3359L54.0254 8.31663C52.0426 5.45218 49.177 3.31518 45.866 2.23179C42.555 1.1484 38.9804 1.17814 35.6879 2.31646C32.3954 3.45478 29.5657 5.63916 27.6308 8.53619C25.6959 11.4332 24.7621 14.8838 24.9719 18.3612C24.9719 18.3874 24.9719 18.4135 24.9719 18.4396C25.0082 18.9622 25.0707 19.4827 25.1592 19.999L30.4864 16.841C30.5888 14.8377 31.2676 12.9066 32.4412 11.2798C33.6147 9.653 35.2331 8.39969 37.1018 7.67052C38.9705 6.94135 41.01 6.76735 42.9752 7.16941C44.9404 7.57148 46.7477 8.53251 48.1799 9.93701L30.8566 20.2038L25.9868 23.096L5.61881 35.1618C4.99229 35.5331 4.53753 36.1366 4.35322 36.8412C4.16892 37.5458 4.26995 38.2947 4.63439 38.9252L5.23985 39.9445C7.11879 42.6722 9.80047 44.7471 12.9125 45.8812C16.0245 47.0152 19.4124 47.1521 22.6058 46.2729C25.7991 45.3936 28.6395 43.5418 30.7324 40.9746C32.8253 38.4074 34.0668 35.2522 34.2847 31.9472C34.3283 31.2654 34.3283 30.5816 34.2847 29.8999C34.284 29.8737 34.2854 29.8475 34.2891 29.8215Z"
        fill="url(#paint2_linear_2_7142)"
      />
      <path
        d="M17.323 3.36403C12.1598 6.41544 8.325 11.2882 6.57275 17.024L8.54595 15.8566C10.3821 11.1567 13.7384 7.20642 18.0802 4.63533C22.4219 2.06423 27.4992 1.02021 32.5032 1.6696C33.2853 1.25318 34.0978 0.896442 34.9337 0.602415C28.9265 -0.768369 22.6223 0.220224 17.323 3.36403Z"
        fill="url(#paint3_linear_2_7142)"
      />
      <path
        d="M50.7152 32.409C48.879 37.109 45.5227 41.0592 41.181 43.6303C36.8392 46.2014 31.7619 47.2454 26.758 46.5961C25.9749 47.0107 25.1626 47.3673 24.3274 47.6632C30.2576 49.0148 36.4796 48.0724 41.7436 45.0255C47.0077 41.9786 50.9239 37.0527 52.7058 31.2373L50.7152 32.409Z"
        fill="url(#paint4_linear_2_7142)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2_7142"
          x1="47.8531"
          y1="31.9431"
          x2="42.809"
          y2="23.2053"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#662483" />
          <stop offset="0.08" stopColor="#79207B" />
          <stop offset="0.27" stopColor="#A11769" />
          <stop offset="0.44" stopColor="#BE105C" />
          <stop offset="0.58" stopColor="#D00C55" />
          <stop offset="0.69" stopColor="#D60B52" />
          <stop offset="0.82" stopColor="#D70D52" />
          <stop offset="0.88" stopColor="#DA1554" />
          <stop offset="0.94" stopColor="#DE2356" />
          <stop offset="0.98" stopColor="#E53659" />
          <stop offset="1" stopColor="#E83F5B" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2_7142"
          x1="16.4517"
          y1="25.0614"
          x2="11.4076"
          y2="16.3235"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#662483" />
          <stop offset="0.15" stopColor="#6A2381" />
          <stop offset="0.29" stopColor="#75217C" />
          <stop offset="0.43" stopColor="#881C74" />
          <stop offset="0.56" stopColor="#A21769" />
          <stop offset="0.69" stopColor="#C40F5A" />
          <stop offset="0.75" stopColor="#D60B52" />
          <stop offset="0.85" stopColor="#D70D52" />
          <stop offset="0.91" stopColor="#DA1554" />
          <stop offset="0.95" stopColor="#DE2356" />
          <stop offset="0.99" stopColor="#E53659" />
          <stop offset="1" stopColor="#E83F5B" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2_7142"
          x1="37.7171"
          y1="38.1368"
          x2="21.5438"
          y2="10.1243"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#662483" />
          <stop offset="0.12" stopColor="#70227E" />
          <stop offset="0.33" stopColor="#8C1B72" />
          <stop offset="0.6" stopColor="#B9115F" />
          <stop offset="0.75" stopColor="#D60B52" />
          <stop offset="0.85" stopColor="#D70D52" />
          <stop offset="0.91" stopColor="#DA1554" />
          <stop offset="0.95" stopColor="#DE2356" />
          <stop offset="0.99" stopColor="#E53659" />
          <stop offset="1" stopColor="#E83F5B" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2_7142"
          x1="13.3897"
          y1="20.9617"
          x2="27.5985"
          y2="-3.64454"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#662483" />
          <stop offset="0.09" stopColor="#851D76" />
          <stop offset="0.19" stopColor="#A21769" />
          <stop offset="0.3" stopColor="#B9115F" />
          <stop offset="0.42" stopColor="#C90E58" />
          <stop offset="0.56" stopColor="#D30C53" />
          <stop offset="0.74" stopColor="#D60B52" />
          <stop offset="0.84" stopColor="#D70D52" />
          <stop offset="0.9" stopColor="#DA1554" />
          <stop offset="0.95" stopColor="#DE2356" />
          <stop offset="0.99" stopColor="#E53659" />
          <stop offset="1" stopColor="#E83F5B" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2_7142"
          x1="31.6627"
          y1="51.9058"
          x2="45.8671"
          y2="27.2996"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#662483" />
          <stop offset="0.2" stopColor="#6A2381" />
          <stop offset="0.39" stopColor="#75217D" />
          <stop offset="0.57" stopColor="#871D74" />
          <stop offset="0.74" stopColor="#A11769" />
          <stop offset="0.91" stopColor="#C20F5B" />
          <stop offset="1" stopColor="#D60B52" />
        </linearGradient>
      </defs>
    </svg>
  );
};
