import React, { useState } from "react";
import BoxCom from "./../../../../UI/BoxCom/BoxCom";
import TextfieldComp from "../../../../UI/TextFieldCom/Textfield";
import { UploadVideoIcon } from "../../../../../assets/icons/UploadVideoIcon";
import DeleteIconSmall from "../../../../../assets/icons/DeleteIconSmall";
import ImageUploadField from "../../../../UI/ImageUpload/ImageUploadField";
import IconButton from "@mui/material/IconButton";
import ProgressLoader from "./../../../../UI/ProgressLoader/ProgressLoader";
import { storage } from "./../../../../../config/Firebase/firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {
  FieldLabel,
  FieldWrapper1,
  List,
  VideoList,
  AddModuleButton,
  SubHeading,
} from "./../CreateCourseModule/CreateCourseModule.style";
import { Formik, Form } from "formik";
import * as Yup from "yup";

const AddModule = ({
  newSubmissionState,
  setNewSubmissionState,
  moduleListHandler,
}) => {
  const [list, setList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [progressTime, setProgressTime] = useState(0);
  const FORM_VALIDATION = Yup.object().shape({
    name: Yup.string().required("Module name required"),
    video: Yup.string().required("vide required"),
  });

  const initialValues = {
    name: "",
    video: "",
  };

  async function handleSetPreviewVideo(blobData, videoData) {
    setShowLoader(true);
    const imagePostService = async (image) => {
      return new Promise(function (resolve, reject) {
        const name2 = new Date().getTime() + "" + image.name;
        const storageRef = ref(storage, "photos/" + name2);
        const uploadTask = uploadBytesResumable(storageRef, image);
        uploadTask.on(
          "state_changed",
          function (snapshot) {
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            setProgressTime(Math.round(progress));
          },
          (error) => {
            console.error("error", error);
            reject(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              resolve(downloadURL);
            });
          }
        );
      });
    };
    await imagePostService(videoData)
      .then((el) => {
        if (blobData !== "") {
          setList([
            ...list,
            { video: blobData, details: videoData, videoUrl: el },
          ]);
          setShowLoader(false);
        }
      })
      .catch((error) => {
        console.error("error occurred", error);
      });
  }

  const deleteVideoHandler = (index) => {
    let temp = [...list];
    temp.splice(index, 1);
    setList(temp);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={FORM_VALIDATION}
      onSubmit={(values) => {
        moduleListHandler({ ...values, list });
      }}
    >
      {(formik) => {
        return (
          <>
            <Form>
              <SubHeading>
                Create modules and complete the course curriculum
              </SubHeading>
              <div
                style={{
                  border: "1px solid #B7C6D9",
                  borderRadius: "10px",
                  padding: "20px 10px 20px 10px",
                }}
              >
                <BoxCom>
                  <FieldLabel>Module Name</FieldLabel>
                  <TextfieldComp
                    height="45px"
                    width="100%"
                    autoComplete="false"
                    onChange={formik.handleChange}
                    name="name"
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    id="name"
                    value={formik.values.name}
                  />
                  <ImageUploadField
                    name="video"
                    setPreviewImage={handleSetPreviewVideo}
                    id="video"
                  >
                    <label htmlFor="video">
                      <FieldWrapper1 height={"100px"}>
                        <UploadVideoIcon />
                      </FieldWrapper1>
                    </label>
                  </ImageUploadField>
                </BoxCom>

                <BoxCom
                  sx={{ borderBottom: "1px solid #464646", marginTop: "5px" }}
                ></BoxCom>
                <VideoList>
                  <FieldLabel>Video List</FieldLabel>
                  {showLoader ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ProgressLoader value={progressTime} />
                    </div>
                  ) : (
                    list?.map((item, index) => {
                      return (
                        <>
                          <List key={index}>
                            <video
                              src={item.video}
                              controls
                              style={{
                                width: "100%",
                                height: "100px",
                                borderRadius: "15px",
                                marginBottom: "10px",
                              }}
                            />

                            <IconButton
                              aria-label="delete"
                              onClick={() => deleteVideoHandler(index)}
                            >
                              <DeleteIconSmall width={17} height={19} />
                            </IconButton>
                          </List>
                        </>
                      );
                    })
                  )}
                </VideoList>

                <AddModuleButton
                  variant="contained"
                  type="submit"
                  sx={{ marginTop: "25px" }}
                >
                  Create Module
                </AddModuleButton>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default AddModule;
