import React from "react";

const Left = ({ ...props }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        rx="10.5"
        ry="10.3845"
        transform="matrix(-1 0 0 1 11.2148 11.0114)"
        fill="#E63369"
      />
      <path
        d="M12.8555 14.2574L9.57422 11.0122L12.8555 7.76709"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Left;
