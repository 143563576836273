import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BoxCom from "../BoxCom/BoxCom";
import { BellIcon } from "../../../assets/icons/BellIcon";
import { Colors } from "../../../config/palette";
import { useSelector } from "react-redux";
import English from "./../../../config/Langugage/English.json";
import Polish from "./../../../config/Langugage/Polish.json";

export default function SideBarAccordion({ notifications }) {
  const selector = useSelector((state) => state.auth.language);
  return (
    <div>
      <Accordion sx={{ background: "#1A1A1C", boxShadow: "none" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: Colors.light }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ marginLeft: "80px", alignText: "center", marginRight: "50px" }}
        >
          <BoxCom
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <BellIcon />
            <Typography
              sx={{
                color: Colors.light,
                fontSize: "12px",
                fontFamily: '"Poppins", "sans-serif"',
                marginLeft: "5px",
              }}
            >
              {selector === "English" ? ` ${English["44"]}` : Polish["44"]}
            </Typography>
          </BoxCom>
        </AccordionSummary>
        <AccordionDetails sx={{ background: "#1D1D1E", marginBottom: "15px" }}>
          {notifications?.map((item, index) => {
            return (
              <>
                <BoxCom
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  key={index}
                >
                  <Typography
                    sx={{
                      color: Colors.light,
                      fontSize: "10px",
                      fontFamily: '"Poppins", "sans-serif"',
                    }}
                  >
                    {item.NotificationText}
                  </Typography>
                  <Typography
                    sx={{
                      color: Colors.light,
                      fontSize: "8px",
                      fontFamily: '"Poppins", "sans-serif"',
                      textAlign: "right",
                      lineHeight: "0px",
                    }}
                  >
                    {item.Date}
                  </Typography>
                </BoxCom>
              </>
            );
          })}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
