import React from "react";

const CameraIcon = () => {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15.3216" cy="15.2942" r="15.2454" fill="#E63369" />
      <rect
        width="18.2945"
        height="18.2945"
        transform="translate(6.17459 6.14795)"
        fill="#E63369"
      />
      <path
        d="M8.4614 13.0084C8.4614 12.1664 9.14395 11.4838 9.98593 11.4838H10.6946C11.2043 11.4838 11.6803 11.2291 11.9631 10.805L12.5824 9.8759C12.8652 9.45177 13.3412 9.19702 13.8509 9.19702H16.7927C17.3025 9.19702 17.7785 9.45177 18.0612 9.8759L18.6806 10.805C18.9633 11.2291 19.4393 11.4838 19.9491 11.4838H20.6577C21.4997 11.4838 22.1822 12.1664 22.1822 13.0084V19.8688C22.1822 20.7108 21.4997 21.3933 20.6577 21.3933H9.98593C9.14395 21.3933 8.4614 20.7108 8.4614 19.8688V13.0084Z"
        stroke="white"
        strokeWidth="1.52454"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6086 16.0574C17.6086 17.3204 16.5848 18.3443 15.3218 18.3443C14.0588 18.3443 13.035 17.3204 13.035 16.0574C13.035 14.7945 14.0588 13.7706 15.3218 13.7706C16.5848 13.7706 17.6086 14.7945 17.6086 16.0574Z"
        stroke="white"
        strokeWidth="1.52454"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CameraIcon;
