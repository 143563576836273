import { Box, Container, Grid } from "@mui/material";
import React, { useState } from "react";
import Button from "../../../../UI/Button/Button";
import NavBar from "../../../../UI/NavBar/NavBarMain/NavBar";
import TextfieldComp from "../../../../UI/TextFieldCom/Textfield";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import English from "../../../../../config/Langugage/English.json";
import Polish from "../../../../../config/Langugage/Polish.json";

import {
  FormContainer,
  GridItem,
  Heading,
  LinkContainer,
  PaperContainer,
  ErrorText,
} from "./Login.style";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import { signin, getCollectionData } from "./../../../../../services/services";
import { auth } from "../../../../../config/Firebase/firebase";
import { useSelector } from "react-redux";

function Login() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [errorData, setErrorData] = useState("");
  const [error, setError] = useState(false);

  const selector = useSelector((state) => state.auth.language);

  const validationSchema = yup.object({
    // Email Required and Email format is incorrect
    email: yup
      .string()
      .email(selector === "English" ? English["369"] : Polish["369"])
      .required(selector === "English" ? English["366"] : Polish["366"]),
    // Enter your password
    // Password should be of minimum 8 characters length
    // Password is required
    password: yup
      .string(selector === "English" ? English["370"] : Polish["370"])
      .min(8, selector === "English" ? English["371"] : Polish["371"])
      .required(selector === "English" ? English["372"] : Polish["372"]),
  });


  const getAllUsers = async (email) => {

    try {
      const users = await getCollectionData("Users");
      return users.some(user => user.Email === email);
    } catch (err) {
      alert("Email address couldn't check, Please try again")
    }
  };

  const getSignIn = async (values) => {
    const { email, password } = values;
    try {
      await signin(email, password);
      const isUser = await getAllUsers(email)

      if (isUser) {
        setError(false);
        // Successfully LoggedIn
        enqueueSnackbar(
          selector === "English" ? English["373"] : Polish["373"],
          {
            variant: "success",
            autoHideDuration: 4000,
          }
        );
        localStorage.setItem("authentication", "true");
        localStorage.setItem("userData", JSON.stringify(auth.currentUser));

        navigate("/home");

      } else {
        alert('Email address not found')
      }
    } catch (error) {
      setError(true);
      enqueueSnackbar(error.message, {
        variant: "error",
        autoHideDuration: 4000,
      });
      setErrorData(error.code);
    }

  }
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      getSignIn(values);
    },
  });

  return (
    <Box sx={{ background: "#131315" }}>
      <NavBar />
      <Container style={{ padding: "0px" }}>
        <PaperContainer elevation={0}>
          <Heading>
            {selector === "English" ? ` ${English["3"]}` : Polish["3"]}
          </Heading>

          <FormContainer>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={1}>
                <GridItem item xs={12}>
                  <TextfieldComp
                    autoComplete="false"
                    placeholder="Email"
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    name="email"
                    id="email"
                    value={formik.values.email}
                  />
                </GridItem>
                <GridItem item xs={12}>
                  <TextfieldComp
                    placeholder={
                      selector === "English" ? ` ${English[7]}` : Polish[7]
                    }
                    id="password"
                    name="password"
                    type="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />
                </GridItem>
                <GridItem
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    paddingRight: { sm: "120px", xs: "45px" },
                  }}
                >
                  <Link
                    style={{
                      color: "#295BE0",
                      fontSize: "20px",
                      fontFamily: '"Poppins", "sans-serif"',
                    }}
                    to="/forgotPassword"
                  >
                    {selector === "English" ? English["16"] : Polish["16"]}
                  </Link>
                </GridItem>
                <div style={{ width: "100%" }}>
                  {error ? <ErrorText>{errorData}</ErrorText> : null}
                </div>

                <GridItem
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "30px",
                  }}
                >
                  {/* href="/home" */}
                  <Button variant="contained" type="submit">
                    {selector === "English" ? ` ${English["3"]}` : Polish["3"]}
                  </Button>
                </GridItem>
              </Grid>
            </form>
          </FormContainer>
          <LinkContainer sx={{ width: "50%", textAlign: "center" }}>
            {selector === "English" ? ` ${English[17]}? ` : `${Polish[17]}? `}

            <Link
              style={{
                color: "#295BE0",
                fontSize: "20px",
                fontFamily: '"Poppins", "sans-serif"',
                textDecoration: "none",
              }}
              to="/signUp"
            >
              {selector === "English" ? ` ${English[18]}` : Polish[18]}
            </Link>
          </LinkContainer>
        </PaperContainer>
      </Container>
    </Box>
  );
}

export default Login;
