import { Grid } from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";
import BoxCom from "../../../UI/BoxCom/BoxCom";
import {
  AvatarName,
  AvatarWrapper,
  ButtonForDesktop,
  ButtonForSmallScreen,
  Count,
  Filter,
  FilterWrapper,
  Heading,
  MainWrapper,
  NameAndIconWrapper,
  ProfileButton,
  SettingText,
} from "./UserProfile.style ";
import ProductCard from "../../Shop/ProductCard/ProductCard";
import { Colors } from "../../../../config/palette";
import HomeCourseCard from "../../Home/HomeCourseCard/HomeCourseCard";
import SettingIcon from "../../../../assets/icons/SettingIcon";
import BlogCard from "../../Blog/BlogCard/BlogCard";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { getBasePath } from "../../../../Utils/utils";
import { useSelector } from "react-redux";
import English from "../../../../config/Langugage/English.json";
import Polish from "../../../../config/Langugage/Polish.json";
import {
  getService,
  // getServiceById,
  updateService,
  postServiceByCoustomId,
} from "../../../../services/services";
import Loader from "../../../UI/Loader/Loader";
import Alert from "../../../UI/Alert/Alert";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
// import { userActions } from "./../../../../redux/reducers/user.js";
// import { async } from "@firebase/util";
// import { authActions } from "./../../../../redux/reducers/auth";
import { subscriptionActions } from "./../../../../redux/reducers/subscription";
// import useCurrentUserData from "./../../../../hooks/useCurrentUserData";
import moment from "moment";

const UserProfile = () => {
  const [filter, setFilter] = useState("courses");
  const [status, setStatus] = useState("Active");
  const [products, setProducts] = useState([]);
  const [courses, setCourses] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [membered, setMembered] = useState(false);

  const dispatch = useDispatch();
  let navigate = useNavigate();
  let { pathname } = useLocation();
  const search = useLocation().search;
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const store = useSelector((state) => state);
  const { auth, user: _user } = store;
  const userId = _user?.currentUser.uid;
  const selector = auth.language;
  const currentUserData = auth.userData;
  pathname = getBasePath(pathname);
  const size = { sm: "6", md: "6", lg: "4" };
  function selectFilter(selectedTab) {
    setFilter(selectedTab);
  }

  function handleNavigation(path) {
    navigate(path);
  }

  const getData = async () => {
    setLoading(true);
    let blogList = [];
    let productList = [];
    const blogData = await getService("Blog");
    const productData = await getService("Product");

    blogData.forEach((doc) => {
      blogList.push({ id: doc.id, ...doc.data() });
    });

    productData.forEach((doc) => {
      productList.push({ id: doc.id, ...doc.data() });
    });

    setUserData({
      userImage: currentUserData.ProfileImage,
      userName: currentUserData.FullName,
    });

    if (pathname === "profile") {
      let tempData = [];
      blogList.forEach((blog) => {
        currentUserData?.PurchasedBlogs.forEach((ele) => {
          if (blog.id === ele) {
            tempData.push(blog);
          }
        });
      });

      blogList = [...tempData];
      let tempDataProduct = [];
      productList.forEach((product) => {
        currentUserData?.PurchasedProducts.forEach((ele) => {
          if (product.id === ele) {
            tempDataProduct.push(product);
          }
        });
      });
      setProducts(tempDataProduct);
      setLoading(false);
    }

    if (pathname === "coachProfile") {
      const coachProducts = productList.filter(
        (product) => product.CoachId === userId
      );
      setProducts(coachProducts);
      setLoading(false);
    }
    const coachBlogs = blogList.filter((blog) => blog.CoachId === userId);

    setBlogs(coachBlogs);
  };

  const getCourses = async () => {
    let courseList = [];
    let tempData = [];
    const courseData = await getService("Courses");
    courseData.forEach((doc) => {
      courseList.push({ id: doc.id, ...doc.data() });
    });

    if (pathname === "profile") {
      courseList.forEach((course) => {
        currentUserData.PurchasedCourse.forEach((ele) => {
          if (course.id === ele) {
            tempData.push(course);
          }
        });
      });
      courseList = [...tempData];
      setCourses(courseList);
    }
    if (pathname === "coachProfile") {
      // let currentUserId = currentUserData.id;
      const coachCourse = courseList.filter(
        (course) => course.CoachId === userId
      );
      setCourses(coachCourse);
    }
  };

  const favoriteButtonHandler = async (courseId, item, index) => {

    let user1 = currentUserData.data();
    let arr = [...user1.Favorites];
    let check = arr.some((el) => el.includes(courseId));
    let array = item?.favoritesBy?.length ? [...item.favoritesBy] : [];
    // let check2 = array.some((el) => el.includes(uid.uid));
    let check2 = array.some((el) => el.includes(userId));
    if (check2) {
      let index = array.indexOf(userId);
      array.splice(index, 1);
      let payload2 = { ...item, favoritesBy: array };
      delete payload2.flag;
      await postServiceByCoustomId("Courses", payload2, courseId);
    } else {
      // array.push(uid.uid);
      array.push(userId);
      let payload2 = { ...item, favoritesBy: array };
      delete payload2.flag;
      await postServiceByCoustomId("Courses", payload2, courseId);
    }
    if (check) {
      let index = arr.indexOf(courseId);
      arr.splice(index, 1);
      let payload = { ...user1, Favorites: arr };
      await updateService("Users", userId, payload);
    } else {
      arr.push(courseId);
      let payload = { ...user1, Favorites: arr };
      await updateService("Users", userId, payload);
    }
    getCourses();
  };

  async function createConnectedAccount() {
    const code = new URLSearchParams(search).get("code");
    if (code !== null) {
      var myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Content-Type", "application/json");
      const formData = JSON.stringify({
        code: code,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formData,
        redirect: "follow",
      };

      await fetch(
        "https://us-central1-evolove-56393.cloudfunctions.net/connectedToken",
        requestOptions
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.stripe_user_id !== undefined) {
            updateUser(response);
          }
        })
        .catch((error) => {
          console.log("out error", error);
        });
    } else {
      console.log("code is null");
    }
  }

  const updateUser = async (response) => {
    try {
      if (response?.stripe_user_id) {
        let payload = {
          ...currentUserData,
          Coach: true,
          stripe_user_id: response?.stripe_user_id,
        };

        await updateService("Users", currentUserData.id, payload);

        enqueueSnackbar("Bank details added Successfully", {
          variant: "success",
          autoHideDuration: 4000,
        });
      } else {
        console.log("stripe user id is not available");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getPaymentFromAdmin = async () => {
    let user = await getService("AdminPayment");
    let adminPrice;
    user.docs.forEach((doc) => {
      adminPrice = doc.data()?.memberPrice;
    });
    return adminPrice;
  };
  const productHandler = () => {
    getPaymentFromAdmin()
      .then((memberPrice) => {
        navigate(`/subscription/${memberPrice}`);
        dispatch(
          subscriptionActions.productIdState({
            productId: "",
            productName: "membership",
          })
        );
      })
      .catch((error) => alert("member id not found"));
  };

  // ######################### compare membersip data #################
  const compareMonth = async () => {
    if (
      moment().diff(currentUserData?.membershipDate, "months") > 1 &&
      currentUserData?.membership === true
    ) {
      try {
        const payload = {
          ...currentUserData,
          membership: true,
          membershipDate: moment().format("YYYY-MM-DD"),
        };
        await postServiceByCoustomId("Users", payload, currentUserData?.id);
        alert("Your membership has been removed");
      } catch (e) {
        console.log("error occured", e);
      }
      setMembered(false);
    } else {
      console.log("you are membered");
    }
  };

  useEffect(() => {
    createConnectedAccount();
  }, [location]);

  useEffect(() => {
    getData();
    getCourses();
    compareMonth();
  }, [pathname]);

  return (
    <BoxCom sx={{ marginTop: "36px", paddingRight: { lg: "60px", md: "0px" } }}>
      {/* Profile */}
      <Heading>
        {selector === "English" ? ` ${English["91"]}` : Polish["91"]}
      </Heading>
      <MainWrapper>
        {pathname === "coachProfile" ? (
          <BoxCom
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <AvatarWrapper alt="Remy Sharp" src={userData.userImage} />
            <ButtonForDesktop>
              <ProfileButton
                variant="contained"
                onClick={() => {
                  handleNavigation("/coachProfile/salesBoard");
                }}
                sx={{ width: "80%", marginLeft: "auto", marginRight: "0px" }}
              >
                {/* Sales Board */}
                {selector === "English" ? ` ${English["128"]}` : Polish["128"]}
              </ProfileButton>
              <ProfileButton
                variant="contained"
                onClick={() => {
                  handleNavigation("/coachProfile/newSubmission");
                }}
                sx={{ marginTop: "20px", width: "100%" }}
              >
                {/* New Submission */}
                {selector === "English" ? ` ${English["162"]}` : Polish["162"]}
              </ProfileButton>
            </ButtonForDesktop>
          </BoxCom>
        ) : (
          <AvatarWrapper alt="Remy Sharp" src={userData.userImage} />
        )}

        <NameAndIconWrapper>
          <AvatarName>{userData.userName}</AvatarName>
          <div
            style={
              {
                // background: "pink",
                // float: "right",
              }
            }
          >
            <Link
              to={`/${pathname}/setting`}
              style={{ textDecoration: "none" }}
            >
              <BoxCom
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: { xs: "18px", sm: "0px" },
                  marginBottom: { xs: "18px", sm: "0px" },
                }}
              >
                <SettingIcon />
                {/* Profile Settings */}
                <SettingText>
                  {selector === "English"
                    ? ` ${English["356"]}`
                    : Polish["356"]}
                </SettingText>
              </BoxCom>
            </Link>
            {pathname === "profile" ? (
              <ProfileButton
                variant="contained"
                disabled={currentUserData?.membership ? true : false}
                onClick={() => {
                  productHandler();
                }}
                sx={{ marginLeft: "auto", marginRight: "0px" }}
              >
                {currentUserData?.membership ? "Membered" : selector === "English" ? ` ${English["408"]}` : Polish["408"]}
              </ProfileButton>
            ) : null}
          </div>
          {pathname === "coachProfile" && (
            <ButtonForSmallScreen>
              <ProfileButton
                variant="contained"
                href="/coachProfile/salesBoard"
                sx={{ width: "100%" }}
              >
                {/* Sales Board */}
                {selector === "English" ? ` ${English["128"]}` : Polish["128"]}
              </ProfileButton>
              <ProfileButton
                variant="contained"
                href="/coachProfile/newSubmission"
                sx={{ marginTop: "20px", width: "100%" }}
              >
                {/* New Submission */}
                {selector === "English" ? ` ${English["162"]}` : Polish["162"]}
              </ProfileButton>
            </ButtonForSmallScreen>
          )}
        </NameAndIconWrapper>
      </MainWrapper>
      {pathname === "coachProfile" ? (
        <FilterWrapper>
          <Filter
            onClick={() => {
              setStatus("Active");
            }}
            component="span"
            sx={{ color: status === "Active" && Colors.info }}
          >
            {/* Active */}
            {selector === "English" ? ` ${English["129"]}` : Polish["129"]}
          </Filter>
          <Filter
            onClick={() => {
              setStatus("Submited");
            }}
            component="span"
            sx={{ color: status === "Submited" && Colors.info }}
          >
            {/* Submitted */}
            {selector === "English" ? ` ${English["130"]}` : Polish["130"]}
          </Filter>
        </FilterWrapper>
      ) : (
        <FilterWrapper>
          <Filter
            onClick={() => {
              selectFilter("courses");
            }}
            component="span"
            sx={{ color: filter === "courses" && Colors.info }}
          >
            {/* My Courses */}
            {selector === "English" ? ` ${English["357"]}` : Polish["357"]}
          </Filter>
          <Filter
            onClick={() => {
              selectFilter("blogs");
            }}
            component="span"
            sx={{ color: filter === "blogs" && Colors.info }}
          >
            {/* My Blogs */}
            {selector === "English" ? ` ${English["358"]}` : Polish["358"]}
          </Filter>
          <Filter
            onClick={() => {
              selectFilter("products");
            }}
            component="span"
            sx={{ color: filter === "products" && Colors.info }}
          >
            {/* My Products */}
            {selector === "English" ? ` ${English["359"]}` : Polish["359"]}
          </Filter>
        </FilterWrapper>
      )}

      <>
        {loading ? (
          <Loader />
        ) : (
          <>
            {(filter === "courses" || pathname === "coachProfile") && (
              <>
                <Count>
                  {pathname === "coachProfile"
                    ? `${status} ${courses.length} Kursów `
                    : `Kursów  ${courses.length}`}
                </Count>
                {courses.length === 0 ? (
                  <Alert severity="info" message={selector === "English" ? ` ${English["380"]}` : Polish["380"]} />
                ) : (
                  <Grid container spacing={10} sx={{ paddingTop: "20px" }}>
                    <HomeCourseCard
                      showButton={pathname === "coachProfile" ? true : false}
                      size={size}
                      courseList={courses}
                      favoriteButtonHandler={favoriteButtonHandler}
                    />
                  </Grid>
                )}
              </>
            )}
            {(filter === "blogs" || pathname === "coachProfile") && (
              <>
                <Count>
                  {pathname === "coachProfile"
                    ? `${status} ${blogs.length} Artykuły`
                    : `Artykuły ${blogs.length}`}
                </Count>
                {blogs.length === 0 ? (
                  <Alert severity="info" message={selector === "English" ? ` ${English["381"]}` : Polish["381"]}  />
                ) : (
                  <Grid container spacing={10} sx={{ paddingTop: "20px" }}>
                    {blogs?.map((blog, index) => {
                      return (
                        <BlogCard
                          key={index}
                          tag={true}
                          title={blog.Title}
                          description={blog.Discription}
                          image={blog.FeatureImage}
                          blogId={blog.id}
                          price={blog.Price}
                          pricetype={blog.PriceType}
                        />
                      );
                    })}
                  </Grid>
                )}
              </>
            )}
            {(filter === "products" || pathname === "coachProfile") && (
              <>
                <Count>
                  {pathname === "/coachProfile"
                    ? `${status} ${products.length} Produkty `
                    : `Produkty  ${products.length}`}
                </Count>
                {products.length === 0 ? (
                  <Alert severity="info" message={selector === "English" ? ` ${English["382"]}` : Polish["382"]}  />
                ) : (
                  <Grid container spacing={10} sx={{ paddingTop: "20px" }}>
                    {products?.map((product, index) => {
                      return (
                        <ProductCard
                          key={index}
                          size={size}
                          title={product.Title}
                          name={product.CoachName}
                          image={product.Image}
                          price={product.Price}
                          id={product.id}
                        />
                      );
                    })}
                  </Grid>
                )}
              </>
            )}
          </>
        )}
      </>
    </BoxCom>
  );
};

export default UserProfile;
