import React from "react";

const BankAcountIcon = ({ color }) => {
  return (
    <svg
      W="37"
      height="37"
      viewBox="0 0 37 37"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="18.0061" cy="18.0061" r="18.0061" fill="#2A2A3F" />
      <path
        d="M11.25 25.5H24.75"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 22.5V17.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 22.5V17.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 22.5V17.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5 22.5V17.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 10.5L24 14.25H12L18 10.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BankAcountIcon;
