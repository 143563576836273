import React, { useState, useEffect } from "react";
import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import { Colors } from "../../../../../config/palette";
import Footer from "../../../../UI/Footer/FooterMain/Footer";
import NavBar from "../../../../UI/NavBar/NavBarMain/NavBar";
import ContactUsImage from "../../../../../assets/images/homeOnBoarding/mackbok.png";
import { AboutUsMainImage } from "./AboutUsMain.style";
import { useSelector } from "react-redux";
import English from "./../../../../../config/Langugage/English.json";
import Polish from "./../../../../../config/Langugage/Polish.json";
import { getService } from "../../../../../services/services";

function AboutUs() {
  const selector = useSelector((state) => state.auth.language);
  const [aboutUsData, setAboutUsData] = useState("");

  const getAboutUs = async () => {
    const aboutUs = await getService("AddPolicy");
    setAboutUsData(aboutUs.docs[0]?.data()?.descriptionName);
  };

  useEffect(() => {
    getAboutUs();
  }, []);
  return (
    <Box sx={{ background: "#131315" }}>
      <NavBar />
      <Container style={{ padding: "0px" }}>
        <Paper
          elevation={0}
          square={true}
          style={{ background: Colors.secondary }}
        >
          <Typography
            sx={{
              color: Colors.light,
              textAlign: "center",
              paddingTop: "50px",
              fontSize: "30px",
              fontWeight: "bold",
              fontFamily: '"Poppins", "sans-serif"',
            }}
          >
            {selector === "English" ? ` ${English["325"]}` : Polish["325"]}
          </Typography>
          <Grid
            container
            spacing={1}
            sx={{ paddingTop: "50px", paddingBottom: "50px" }}
          >
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <AboutUsMainImage src={ContactUsImage} />
            </Grid>

            <Grid item xs={12}>
              {/* <Typography
                sx={{
                  color: Colors.light,
                  paddingTop: "50px",
                  fontSize: "25px",
                  fontFamily: '"Poppins", "sans-serif"',
                }}
              >
                Lorem ipsum dolor sit amet, consectetur
              </Typography> */}
              <Typography
                sx={{
                  color: Colors.dimGrey,
                  paddingTop: "50px",
                  fontSize: "18px",
                  fontFamily: '"Poppins", "sans-serif"',
                }}
              >
                {selector === "English" ? ` ${English["414"]}` : Polish["414"]}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <Footer />
    </Box>
  );
}

export default AboutUs;
