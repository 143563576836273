import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import Footer from "../../../../UI/Footer/FooterMain/Footer";
import NavBar from "../../../../UI/NavBar/NavBarMain/NavBar";
import AboutUsHome from "../AboutUsHome/AboutUsHomeMain/AboutUsHomeMain";
import HomeExploreBlogs from "../ExploreBlogsHome/ExploreBlogsHomeMain/ExploreBlogsHomeMain";
import PopularCoursesHome from "../PopularCoursesHome/PopularCoursesHomeMain/PopularCoursesHomeMain";
import SliderHomeMain from "../SliderHome/SliderMain/SliderHomeMain";
import TopCoachesHome from "../TopCoachesHome/TopCoachesHomeMain/TopCoachesHome";
import { getService } from "./../../../../../services/services";

function HomeOnBoarding() {
  const [aboutUsData, setAboutUsData] = useState("");
  const [courses, setCourses] = useState([]);
  const [coaches, setCoaches] = useState([]);
  const [allBlog, setAllBlog] = useState([]);
  const [showBlogLoader, setShowBlogLoader] = useState(true);

  const getAllData = async () => {
    const aboutUs = await getService("AddPolicy");
    const popularCourse = await getService("PopularCourses");
    const allCourses = await getService("Courses");
    const allUsers = await getService("Users");
    const popularCoaches = await getService("PopularCoaches");
    const allBlog = await getService("Blog");
    getAboutData(aboutUs);
    getTopCourses(popularCourse, allCourses);
    getPopularCoaches(allUsers, popularCoaches);
    getAllBlog(allBlog);
  };
  // Get AboutUs Data
  const getAboutData = (aboutUs) => {
    setAboutUsData(aboutUs.docs[0]?.data()?.descriptionName);
  };

  // Get PopularCourse Data
  const getTopCourses = (popularCourse, allCourses) => {
    let courseList = [];
    let _fullCourse = [];
    allCourses.docs.forEach((doc) => {
      _fullCourse.push({ ...doc.data() });
    });

    popularCourse.docs.forEach((doc) => {
      doc.data().PopularCourses.forEach((item) => {
        const data = _fullCourse.filter((el) => el.id === item.id);
        courseList.push(...data);
      });
    });

    setCourses(courseList);
  };

  // Get PopularCoaches Data
  const getPopularCoaches = (allUsers, popularCoaches) => {
    let coachList = [];
    let _allUsers = [];
    allUsers.docs.forEach((doc) => {
      _allUsers.push({ ...doc.data() });
    });

    popularCoaches.docs.forEach((doc) => {
      doc.data().PopularCoaches.forEach((item) => {
        const data = _allUsers.filter((el) => el.id === item.id);
        coachList.push(...data);
      });
    });

    setCoaches(coachList);
  };
  // Get All Blog Data
  const getAllBlog = (allBlog) => {
    let allBlogList = [];
    allBlog?.docs.forEach((doc) => {
      allBlogList.push({ ...doc.data() });
    });
    setAllBlog(allBlogList.slice(0, 3));
    setShowBlogLoader(false);
  };
  useEffect(() => {
    getAllData();
  }, []);

  return (
    <Box sx={{ background: "#131315" }}>
      <NavBar />
      <Container style={{ padding: "0px" }}>
        <SliderHomeMain />
        <AboutUsHome aboutUsData={aboutUsData} />
        <PopularCoursesHome
          courseList={courses}
          showBlogLoader={showBlogLoader}
        />
        <TopCoachesHome coaches={coaches} showBlogLoader={showBlogLoader} />
        <HomeExploreBlogs allBlog={allBlog} showBlogLoader={showBlogLoader} />
      </Container>
      <Footer />
    </Box>
  );
}

export default HomeOnBoarding;
