import React from "react";

const SuccessIcon = () => {
  return (
    <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75 142.5C112.279 142.5 142.5 112.279 142.5 75C142.5 37.7212 112.279 7.5 75 7.5C37.7212 7.5 7.5 37.7212 7.5 75C7.5 112.279 37.7212 142.5 75 142.5ZM75 150C116.423 150 150 116.423 150 75C150 33.5775 116.423 0 75 0C33.5775 0 0 33.5775 0 75C0 116.423 33.5775 150 75 150Z"
        fill="#259F46"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.012 45.9712C115.75 46.6377 116.193 47.5699 116.244 48.5628C116.294 49.5558 115.949 50.5281 115.282 51.2662L65.1974 106.62L34.9124 77.7112C34.2324 77.0162 33.8489 76.0843 33.8428 75.112C33.8368 74.1396 34.2086 73.203 34.8799 72.4996C35.5512 71.7961 36.4695 71.381 37.4411 71.3416C38.4126 71.3023 39.3615 71.6419 40.0874 72.2887L64.7999 95.8762L109.721 46.2375C110.052 45.8722 110.451 45.5756 110.896 45.3647C111.342 45.1537 111.824 45.0326 112.316 45.0083C112.808 44.9839 113.3 45.0567 113.764 45.2226C114.228 45.3885 114.655 45.6441 115.02 45.975L115.012 45.9712Z"
        fill="#259F46"
      />
    </svg>
  );
};

export default SuccessIcon;
