import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  loggedIn: "false",
  userData: "",
  categoriesList: [],
  // language: "Polish",
  language: localStorage.getItem("language"),
};

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginHandler: (state, payload) => {
      state.loggedIn = payload.payload.loggedIn;
      if (state.loggedIn === "true") {
        localStorage.setItem("auth", payload.payload.token);
      }
    },
    setUserData: (state, action) => {
      console.log("user", action.payload)
      state.userData = action.payload;
    },
    languageHandler: (state, payload) => {
      state.language = payload.payload;
      return state;
    },
    categoriesListHandler: (state, action) => {
      console.log("payload", action.payload);
      state.categoriesList = action.payload;
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const authActions = auth.actions;

export const authReducer = auth.reducer;
