import { Box, Grid, Paper, Typography } from "@mui/material";
import { Colors } from "../../../../../../config/palette";
import {
  AvatarWrapper,
  HomeAvatarName,
  HomeAvatarOccupation,
  HomeAvatarWrapper,
} from "./TopCoachesHome.style";

import { useSelector } from "react-redux";
import English from "./../../../../../../config/Langugage/English.json";
import Polish from "./../../../../../../config/Langugage/Polish.json";
import CoachSkeleton from "../../../../../UI/skeleton/CoachSkeleton";

const TopCoachesHome = ({ coaches, showBlogLoader }) => {
  const selector = useSelector((state) => state.auth.language);
  return (
    <Paper elevation={0} square={true} style={{ background: Colors.secondary }}>
      <Typography
        sx={{
          color: Colors.light,
          textAlign: "center",
          paddingTop: "50px",
          fontSize: "36px",
          fontWeight: "bold",
        }}
      >
        {/* Top Coaches */}
        {selector === "English" ? ` ${English["37"]}` : Polish["37"]}
      </Typography>

      <Grid
        container
        spacing={1}
        // sx={{ paddingTop: "50px", paddingBottom: "50px" }}
        sx={{
          paddingTop: "50px",
          paddingBottom: "50px",
          paddingLeft: { md: "60px", sm: "0px" },
        }}
      >
        {showBlogLoader ? (
          <>
            <Grid item xs={6} sm={6} md={3}>
              <CoachSkeleton />
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
              <CoachSkeleton />
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
              <CoachSkeleton />
            </Grid>
          </>
        ) : (
          <>
            {coaches?.map((item, index) => {
              return (
                <HomeAvatarWrapper
                  item
                  xs={6}
                  sm={6}
                  md={3}
                  key={index + 1}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AvatarWrapper alt="Remy Sharp" src={item?.ProfileImage} />
                  <Box sx={{ marginLeft: "10px" }}>
                    <HomeAvatarName variant="body2">
                      {item?.FullName}
                    </HomeAvatarName>
                    <HomeAvatarOccupation variant="body1">
                      {`${item.About.slice(0, 20)}...`}
                    </HomeAvatarOccupation>
                  </Box>
                </HomeAvatarWrapper>
              );
            })}
            {/* <CoachSkeleton /> */}
          </>
        )}
      </Grid>
    </Paper>
  );
};

export default TopCoachesHome;
