import React from "react";
// import CheckCircleIcon from "../../../../assets/icons/CheckCircleIcon";
import BoxCom from "../../../UI/BoxCom/BoxCom";
import TextfieldComp from "../../../UI/TextFieldCom/Textfield";
import {
  FieldLabel,
  // Heading,
  MainContainer,
  ProfileButton,
  SubHeading,
} from "./AddCard.style";
import { useFormik } from "formik";
import * as yup from "yup";
import { getServiceById, updateService } from "./../../../../services/services";
import { useSnackbar } from "notistack";
import English from "../../../../config/Langugage/English.json";
import Polish from "../../../../config/Langugage/Polish.json";
import { useSelector } from "react-redux";

const AddCard = ({ setShowForm, bankDetails, previousBankDetails }) => {
  const store = useSelector((state) => state);
  const { auth, user: _user } = store;
  const selector = auth.language;
  const { enqueueSnackbar } = useSnackbar();

  const addBankDetails = async (values) => {
    let uid = await JSON.parse(localStorage.getItem("userData"));
    uid = uid.uid;
    let user = await getServiceById("Users", uid);
    let user1 = user.data();

    const { name, cardNumber, cvv, expireDate } = values;
    const bankDetailsObject = {
      name: name,
      number: cardNumber,
      cvv: cvv,
      valid: expireDate,
    };
    let payload1 = { ...user1, BankDetails: bankDetailsObject };
    await updateService("Users", uid, payload1);
    enqueueSnackbar("Bank detail added successfully", {
      variant: "success",
      autoHideDuration: 4000,
    });
  };

  const validationSchema = yup.object({
    // Username Required
    name: yup.string().required("Bank Name Required"),
    cardNumber: yup
      .string()
      .min(16, "Card number should not be less than 16 digits")
      .max(16, "Card number should not be more than 16 digits")
      .required("Card number required"),
    expireDate: yup
      .string()
      .required("Expiration date Required")
      .matches(
        /^\d{2}\/\d{2}$/g,
        "Invalid format, Please use this format (11/23)"
      ),

    cvv: yup
      .string()
      .min(3, "CVV number should be 3 digits")
      .max(3, "CVV number should be 3 digits")
      .required("CVV number required"),
  });

  const formik = useFormik({
    initialValues: {
      name:
        previousBankDetails !== undefined || previousBankDetails !== ""
          ? previousBankDetails?.name
          : "",
      // name: bankDetail,
      cardNumber:
        previousBankDetails !== undefined || previousBankDetails !== ""
          ? previousBankDetails?.number
          : "",
      expireDate:
        previousBankDetails !== undefined || previousBankDetails !== ""
          ? previousBankDetails?.valid
          : "",
      cvv:
        previousBankDetails !== undefined || previousBankDetails !== ""
          ? previousBankDetails?.cvv
          : "",
    },
    validationSchema: validationSchema,

    onSubmit: (values) => {
      bankDetails(values);
      addBankDetails(values);
      setShowForm(false);
    },
  });

  return (
    <MainContainer>
      <>
        <SubHeading>{selector === "English" ? ` ${English["391"]}` : Polish["391"]}</SubHeading>
        <form onSubmit={formik.handleSubmit}>
          <BoxCom sx={{ marginTop: "40px" }}>
            <BoxCom
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <FieldLabel>{selector === "English" ? ` ${English["392"]}` : Polish["392"]}</FieldLabel>
            </BoxCom>
            <TextfieldComp
              // height="50px"
              // width="100%"
              autoComplete="false"
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              name="name"
              id="name"
              value={formik.values.name}
            />
          </BoxCom>
          <BoxCom sx={{ marginTop: "40px" }}>
            <BoxCom
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <FieldLabel>{selector === "English" ? ` ${English["393"]}` : Polish["393"]}</FieldLabel>
            </BoxCom>
            <TextfieldComp
              // height="50px"
              // width="100%"
              autoComplete="false"
              onChange={formik.handleChange}
              error={
                formik.touched.cardNumber && Boolean(formik.errors.cardNumber)
              }
              helperText={formik.touched.cardNumber && formik.errors.cardNumber}
              name="cardNumber"
              id="cardNumber"
              value={formik.values.cardNumber}
            />
          </BoxCom>
          <BoxCom sx={{ marginTop: "40px" }}>
            <BoxCom
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <FieldLabel>{selector === "English" ? ` ${English["394"]}` : Polish["394"]}</FieldLabel>
            </BoxCom>
            <TextfieldComp
              onChange={formik.handleChange}
              error={
                formik.touched.expireDate && Boolean(formik.errors.expireDate)
              }
              helperText={formik.touched.expireDate && formik.errors.expireDate}
              name="expireDate"
              id="expireDate"
              value={formik.values.expireDate}
            />
          </BoxCom>
          <BoxCom sx={{ marginTop: "40px" }}>
            <BoxCom
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <FieldLabel>{selector === "English" ? ` ${English["395"]}` : Polish["395"]}</FieldLabel>
            </BoxCom>
            <TextfieldComp
              // height="50px"
              // width="100%"
              onChange={formik.handleChange}
              error={formik.touched.cvv && Boolean(formik.errors.cvv)}
              helperText={formik.touched.cvv && formik.errors.cvv}
              name="cvv"
              id="cvv"
              value={formik.values.cvv}
            />
          </BoxCom>
          <ProfileButton variant="contained" type="submit">
          {selector === "English" ? ` ${English["396"]}` : Polish["396"]}
          </ProfileButton>
        </form>
      </>
    </MainContainer>
  );
};

export default AddCard;
