import axios from "axios";


// const secretkey = `${Config.secretkey}`
const secretkey = `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`
  // 'sk_test_51K7JHQA3bg7gwyTwMm58yl6lIkGgVfgAtwsbG69uVNEVljIuuk3ejlyiQqea2HkecvPwGLzCnIF8rjxHUdH0cmoV00qW3gobgl';
export const confirmPayment=async(amount:number=0,cardDetails:{
    number:string,
    expiryMonth:string,
    expiryYear:string,
    cvc:string,
})=>{
  try {
    console.log('amount:',amount);
    const card:any = {
      'card[number]':cardDetails?.number,
      'card[exp_month]':cardDetails?.expiryMonth,
      'card[exp_year]':cardDetails?.expiryYear,
      'card[cvc]':cardDetails?.cvc,
    }
    // setActive(true)
     
    console.log('data::::',Object.keys(card)
    .map(key => key + '=' + card[key])
    .join('&'));
    
    const results = await axios({
      url:"https://api.stripe.com/v1/tokens",
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + secretkey,
      },
      data: Object.keys(card)
        .map(key => key + '=' + card[key])
        .join('&'),
    });

    // console.log('result data:=>',results?.data)
    // Below is cloud function calling written in funtions/index file
   const responseJson= await axios('https://us-central1-evolove-56393.cloudfunctions.net/payWithStripe', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        amount:Math.trunc(amount),
        currency: "pln",
        token: results?.data.id,
      },
    })
        console.log("response =>", responseJson);
        // if (responseJson?.status == "succeeded"){
        //   console.log('transaction successfully execeuted');
        // }
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log('error.response.data',error.response?.data?.error?.message);
        console.log('error.response.status',error.response.status);
        throw error.response?.data?.raw?.message||error.response?.data?.code|| error.response?.data?.error?.message||'something went wrong';
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log('typeof of request',typeof error?.request);
        
        console.log('error.request',error?.request?.toString());
        throw error?.request?.toString();
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('error.message', error.message);
        throw error.message;
      }
    }
   }

   export const payToConnectedAccount =async(amount:number,connected_account_id:string)=>{
    try {
      
      const res=await axios({
        url:'https://us-central1-evolove-56393.cloudfunctions.net/payToConnectedAccount',
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        data: {
          amount: Math.trunc(amount),
          currency: "pln",
          connected_account_id: connected_account_id,//stripe_user_id here
        },
      });
      console.log('res',res);
      
    } catch (error) {
      if (error.response) {
        console.log('error.response.data',error.response?.data);
        console.log('error.message',error.message);
        // console.log('error.response.status',error.response.status);
        throw error.response?.data?.raw?.message||error.response?.data?.code|| error.response?.data?.error?.message||'something went wrong';
      } else if (error.request) {
        console.log('error.request',error?.request?.toString());
        throw error?.request?.toString();
      } else {
        console.log('error.message', error.message);
        throw error.message;
      }
    }
   }
   