import { Box, Container, Grid } from "@mui/material";
import React from "react";
import Button from "../../../../UI/Button/Button";
import Footer from "../../../../UI/Footer/FooterMain/Footer";
import NavBar from "../../../../UI/NavBar/NavBarMain/NavBar";
import TextfieldComp from "../../../../UI/TextFieldCom/Textfield";
import { useSelector } from "react-redux";
import English from "./../../../../../config/Langugage/English.json";
import Polish from "./../../../../../config/Langugage/Polish.json";
import { useFormik } from "formik";
import * as yup from "yup";
import { postServiceByCoustomId } from "./../../../../../services/services";
import { useSnackbar } from "notistack";
import { v4 as uuidv4 } from "uuid";

import {
  FormContainer,
  GridItem,
  Heading,
  PaperContainer,
} from "./ContactUsMain.style";

function ContactUs() {
  const selector = useSelector((state) => state.auth.language);
  const { enqueueSnackbar } = useSnackbar();
  const uuid = uuidv4();

  const validationSchema = yup.object({
    // Username Required
    name: yup
      .string()
      .required(selector === "English" ? English["365"] : Polish["365"]),
    // Email required!
    // "Email format is incorrect"
    email: yup
      .string()
      .email(selector === "English" ? English["369"] : Polish["369"])
      .required(selector === "English" ? English["366"] : Polish["366"]),
    // Subject Required
    subject: yup
      .string()
      .required(selector === "English" ? English["367"] : Polish["367"]),
    // Message Required
    message: yup
      .string()
      .required(selector === "English" ? English["358"] : Polish["358"]),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      saveUserMessage(values);
    },
  });

  const saveUserMessage = async (values) => {
    const { email, message, subject, name } = values;
    const data = {
      Message: message,
      Title: subject,
      UserId: "",
      id: uuid,
    };
    try {
      await postServiceByCoustomId("Contact", data, uuid);
      enqueueSnackbar(selector === "English" ? English["364"] : Polish["364"], {
        variant: "success",
        autoHideDuration: 4000,
      });
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: "error",
        autoHideDuration: 4000,
      });
    }
  };
  return (
    <Box sx={{ background: "#131315" }}>
      <NavBar />
      <Container style={{ padding: "0px" }}>
        <PaperContainer elevation={0}>
          <Heading>
            {selector === "English" ? `${English["329"]}` : Polish["329"]}
          </Heading>

          <FormContainer>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={0}>
                <GridItem item xs={12}>
                  <TextfieldComp
                    height="60px"
                    placeholder={
                      selector === "English" ? `${English["4"]}` : Polish["4"]
                    }
                    // onChange={handleChange}
                    // name="fullName"
                    // value={fullName}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    name="name"
                    id="name"
                    value={formik.values.name}
                  />
                </GridItem>
                <GridItem item xs={12}>
                  <TextfieldComp
                    height="60px"
                    placeholder="Email"
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    name="email"
                    id="email"
                    value={formik.values.email}

                    // onChange={handleChange}
                    // name="email"
                    // value={email}
                  />
                </GridItem>
                <GridItem item xs={12}>
                  <TextfieldComp
                    height="60px"
                    placeholder={
                      selector === "English"
                        ? `${English["330"]}`
                        : Polish["330"]
                    }
                    onChange={formik.handleChange}
                    error={
                      formik.touched.subject && Boolean(formik.errors.subject)
                    }
                    helperText={formik.touched.subject && formik.errors.subject}
                    name="subject"
                    id="subject"
                    value={formik.values.subject}
                  />
                </GridItem>
                <GridItem item xs={12}>
                  <TextfieldComp
                    multiLine={true}
                    height="300px"
                    placeholder={
                      selector === "English"
                        ? `${English["331"]}`
                        : Polish["331"]
                    }
                    onChange={formik.handleChange}
                    error={
                      formik.touched.message && Boolean(formik.errors.message)
                    }
                    helperText={formik.touched.message && formik.errors.message}
                    name="message"
                    id="message"
                    value={formik.values.message}
                  />
                </GridItem>
                <GridItem
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "60px",
                    marginBottom: "60px",
                  }}
                >
                  <Button variant="contained" type="submit">
                    {selector === "English"
                      ? `${English["332"]}`
                      : Polish["332"]}
                  </Button>
                </GridItem>
              </Grid>
            </form>
          </FormContainer>
        </PaperContainer>
      </Container>
      <Footer />
    </Box>
  );
}

export default ContactUs;
