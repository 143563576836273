import { Grid, Paper, Typography } from "@mui/material";
import { Colors } from "../../../../../../config/palette";
import {
  ExploreBlogsHeading,
  ExploreBlogsImage,
  ExploreBlogsText,
} from "./ExploreBlogsHome.style";
import { useSelector } from "react-redux";
import English from "../../../../../../config/Langugage/English.json";
import Polish from "../../../../../../config/Langugage/Polish.json";
import { Link } from "react-router-dom";
import BlogSkeleton from "../../../../../UI/skeleton/BlogSkeleton";

const HomeExploreBlogs = ({ allBlog, showBlogLoader }) => {
  const selector = useSelector((state) => state.auth.language);
  return (
    <Paper elevation={0} square={true} style={{ background: Colors.secondary }}>
      <Typography
        sx={{
          color: Colors.light,
          textAlign: "center",
          paddingTop: "50px",
          fontSize: "36px",
          fontWeight: "bold",
          fontFamily: '"Poppins", "sans-serif"',
        }}
      >
        {/* Explore Blogs */}
        {selector === "English" ? ` ${English["335"]}` : Polish["335"]}
      </Typography>
      <Link to="/login" style={{ textDecoration: "none" }}>
        <Grid
          container
          spacing={6}
          sx={{
            paddingTop: "50px",
            paddingBottom: "50px",
            paddingLeft: { md: "60px", sm: "0px" },
          }}
        >
          {showBlogLoader ? (
            <>
              <Grid item xs={12} sm={6} md={4}>
                <BlogSkeleton />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <BlogSkeleton />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <BlogSkeleton />
              </Grid>
            </>
          ) : (
            allBlog?.map((item, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <ExploreBlogsImage src={item.FeatureImage} alt="Paris" />
                  <ExploreBlogsHeading>{item.Title}</ExploreBlogsHeading>
                  <ExploreBlogsText>
                    {item.Discription.length > 120
                      ? `${item.Discription.slice(0, 120)}...`
                      : item.Discription}
                  </ExploreBlogsText>
                </Grid>
              );
            })
          )}
        </Grid>
      </Link>
    </Paper>
  );
};

export default HomeExploreBlogs;
