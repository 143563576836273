import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import BoxCom from "../../UI/BoxCom/BoxCom";
import Calender from "../../UI/Calender/Calender";
import ExploreBlogImage from "../../../assets/images/homeOnBoarding/MoonCalendar.jpeg";
import MoonImage from "../../../assets/images/homeOnBoarding/fullMoon.png";
import isEqual from "date-fns/isEqual";
import { compareAsc } from "date-fns";
import { useSelector } from "react-redux";
import English from "../../../config/Langugage/English.json";
import Polish from "../../../config/Langugage/Polish.json";

import {
  Heading,
  ImageContainer,
  LeftSideGrid,
  MoonImageContainer,
  RightSideGrid,
  SubHeading,
  TaskIconAndTextWrapper,
  TaskTypography,
  TextContainer,
} from "./MoonCalendar.style";
import TaskUncompledIcon from "../../../assets/icons/TaskUncompletedIcon";
import { HalfMoon } from "../../../assets/icons/HalfMoon";
import { getService } from "./../../../services/services.js";

const MoonCalendar = () => {
  const [blogList, setBlogList] = useState([]);
  const [calenderTime, setCalenderTime] = useState("");
  const selector = useSelector((state) => state.auth.language);
  const getBlogs = async () => {
    const allBlogs = [];
    const blogData = await getService("MoonCalander");

    blogData.docs.forEach((doc) => {
      allBlogs.push({ id: doc.id, ...doc.data() });
    });
    setBlogList(allBlogs);
  };

  const sideEffects = (selectedDate) => {
    setCalenderTime(selectedDate);
  };

  useEffect(() => {
    getBlogs();
  }, []);

  return (
    <>
      {/* Moon Calendar */}
      <Heading>
        {selector === "English" ? ` ${English["110"]}` : Polish["110"]}
      </Heading>
      <Grid container spacing={6} sx={{ width: { md: "80%", lg: "100%" } }}>
        <RightSideGrid item xs={12} sm={6} md={6} lg={12}>
          <BoxCom sx={{ marginTop: "9px" }}>
            <Calender sideEffects={sideEffects} />
          </BoxCom>
          <BoxCom sx={{ display: "flex", alignItems: "center" }}>
            <TaskIconAndTextWrapper>
              <TaskUncompledIcon color="yellow" />
              <BoxCom sx={{ marginLeft: "10px", marginTop: "3px" }}>
                <MoonImageContainer src={MoonImage} alt="Paris" />
              </BoxCom>
              {/* Full Moon */}
              <TaskTypography>
                {selector === "English" ? ` ${English["355"]}` : Polish["355"]}
              </TaskTypography>
            </TaskIconAndTextWrapper>

            <TaskIconAndTextWrapper sx={{ marginLeft: "12px" }}>
              <TaskUncompledIcon color="green" />
              <BoxCom sx={{ marginLeft: "10px", marginTop: "3px" }}>
                <HalfMoon />
              </BoxCom>
              {/* Half Moon */}
              <TaskTypography>
                {selector === "English" ? ` ${English["148"]}` : Polish["148"]}
              </TaskTypography>
            </TaskIconAndTextWrapper>
          </BoxCom>
        </RightSideGrid>
        <>
          {blogList.map((blog, index) => {

            const splitDate = blog?.Date.split("-").reverse().join(", ");
            const dates = [new Date(splitDate)];
            const newDate = dates.sort(compareAsc);
            let actualDate = isEqual(calenderTime, newDate[0]);

            return (
              <>
                {actualDate ? (
                  <LeftSideGrid item xs={12} sm={6} md={12} lg={6}>
                    <SubHeading>{blog?.FullMoon}</SubHeading>
                    <BoxCom
                      sx={{
                        background:
                          blog?.FullMoon === "Full Moon"
                            ? "#ffff00"
                            : "#259F46",
                        width: "58px",
                        height: "5px",
                        borderRadius: "50px",
                      }}
                    ></BoxCom>
                    <ImageContainer src={blog?.FeatureImage} alt="Paris" />
                    <TextContainer>{blog?.Discription}</TextContainer>
                  </LeftSideGrid>
                ) : null}
              </>
            );
          })}

          <></>
        </>
      </Grid>
    </>
  );
};

export default MoonCalendar;
