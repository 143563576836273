import React from "react";

const TaskUncompledIcon = ({ color }) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0002 25.6666C20.4437 25.6666 25.6668 20.4434 25.6668 13.9999C25.6668 7.55642 20.4437 2.33325 14.0002 2.33325C7.55666 2.33325 2.3335 7.55642 2.3335 13.9999C2.3335 20.4434 7.55666 25.6666 14.0002 25.6666Z"
        stroke={color}
        strokeWidth="2.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TaskUncompledIcon;
