import { Grid } from "@mui/material";
import React, { useState } from "react";
import DeleteIconSmall from "../../../../../assets/icons/DeleteIconSmall";
import BoxCom from "../../../../UI/BoxCom/BoxCom";
import IconButton from "@mui/material/IconButton";
import {
  CricullumIntroWrapper,
  SubHeading2,
  SubHeading3,
  SubHeading4,
  TimeTypography,
} from "./CreateCourseModule.style";
import { Count } from "./CreateCourseModule.style";
import {
  CardText,
  FieldLabel,
  FieldWrapper,
  LeftSideGrid,
  ProfileButton,
  RightSideGrid,
  SubHeading,
} from "./CreateCourseModule.style";
import AddModule from "./../AddModule/AddModule";

const CreateCourseModule = ({
  newSubmissionState,
  setNewSubmissionState,
  selectedCategory,
  setSelectedCategory,
}) => {
  const [moduleList, setModuleList] = useState([]);
  const [moduleData, setModuleData] = useState({});
  const moduleListHandler = (payload) => {
    setModuleList([...moduleList, payload]);
  };
  const deleteVideoHandler = (index) => {
    let temp = [...moduleList];
    temp.splice(index, 1);
    setModuleList(temp);
  };

  return (
    <Grid container spacing={4} sx={{ width: { md: "80%", lg: "100%" } }}>
      <LeftSideGrid item xs={12} sm={6} md={12} lg={6}>
        <AddModule
          newSubmissionState={newSubmissionState}
          setNewSubmissionState={setNewSubmissionState}
          moduleListHandler={moduleListHandler}
        />
        <BoxCom sx={{ marginTop: "60px" }}>
          <BoxCom
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <FieldLabel>Modules </FieldLabel>
            <Count>{moduleList.length}</Count>
          </BoxCom>
          <div
            style={{
              maxHeight: "250px",
              overflowY: "auto",
            }}
          >
            {moduleList.map((item, index) => {
              return (
                <FieldWrapper height={"50px"} border={true}>
                  <div
                    onClick={() => setModuleData(item)}
                    style={{ width: "100%" }}
                  >
                    <CardText>{item.name}</CardText>
                  </div>
                  <IconButton
                    aria-label="delete"
                    onClick={() => deleteVideoHandler(index)}
                  >
                    <DeleteIconSmall />
                  </IconButton>
                </FieldWrapper>
              );
            })}
          </div>
        </BoxCom>
      </LeftSideGrid>

      <RightSideGrid item xs={12} sm={6} md={12} lg={6}>
        <SubHeading2>Curriculum</SubHeading2>
        <BoxCom sx={{ marginTop: "30px" }}>
          <CricullumIntroWrapper
            sx={{ borderRadius: "20px", paddingBottom: "20px" }}
          >
            <SubHeading3>{moduleData?.name}</SubHeading3>
            {moduleData?.list?.map((item) => {
              return (
                <>
                  <BoxCom
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "1px solid grey",
                      marginTop: "6px",
                    }}
                  >
                    <SubHeading4 sx={{ marginBottom: "10px" }}>
                      {item.details.name}
                    </SubHeading4>
                    <TimeTypography>1:45</TimeTypography>
                  </BoxCom>
                </>
              );
            })}
          </CricullumIntroWrapper>
        </BoxCom>
        <ProfileButton
          variant="contained"
          onClick={() =>
            setNewSubmissionState({
              ...newSubmissionState,
              Module: moduleList,
              step: newSubmissionState.step + 1,
            })
          }
        >
          Continue
        </ProfileButton>
      </RightSideGrid>
    </Grid>
  );
};

export default CreateCourseModule;
