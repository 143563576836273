import React from "react";
import Stack from "@mui/material/Stack";
import {
  SkeletonWrapper,
  Favorite,
  PriceWrapper,
} from "./courseSkeleton.style";

const CourseSkeleton = () => {
  return (
    <div>
      <Stack spacing={0}>
        {/* For variant="text", adjust the height via font-size */}
        <SkeletonWrapper
          variant="rounded"
          width={335}
          height={150}
          style={{ borderRadius: "20px" }}
        />
        <Favorite>
          <SkeletonWrapper
            variant="circular"
            height={40}
            width={40}
            style={{}}
          />
        </Favorite>
        <SkeletonWrapper
          variant="text"
          sx={{ fontSize: "1.5rem" }}
          width={120}
        />
        <SkeletonWrapper
          variant="text"
          sx={{ fontSize: "1.5rem" }}
          width={120}
        />

        {/* For other variants, adjust the size with `width` and `height` */}
        <PriceWrapper>
          <SkeletonWrapper
            variant="text"
            sx={{ fontSize: "1.5rem" }}
            width={120}
          />
          <SkeletonWrapper
            variant="rounded"
            width={100}
            height={25}
            style={{ borderRadius: "30px" }}
          />
        </PriceWrapper>
      </Stack>
    </div>
  );
};

export default CourseSkeleton;
