import React from 'react'

export const HalfMoon = () => {
  return (
    <svg width="30" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.6511 19.2732C21.9685 21.8812 19.3895 23.7831 16.4007 24.6199C13.412 25.4567 10.2203 25.1706 7.42805 23.8156L7.29515 23.7517C7.1471 23.6776 7.00241 23.6019 6.85941 23.5246C6.81231 23.501 6.76688 23.4741 6.72146 23.4488C6.54145 23.3462 6.3648 23.2419 6.19151 23.1326L6.07711 23.0585L5.98626 22.9997C5.94252 22.9727 5.90046 22.9441 5.8584 22.9155C5.73223 22.8331 5.60773 22.7473 5.48828 22.6564L5.45464 22.6329C5.41594 22.606 5.37725 22.5757 5.34023 22.5471L5.22079 22.4697C5.04246 22.3318 4.86917 22.1938 4.7043 22.0491C4.66733 22.0202 4.63195 21.9893 4.59831 21.9566C2.16056 19.8738 0.594274 16.6201 0.346966 14.0175V13.9754C0.0818225 11.9043 0.331355 9.79975 1.07348 7.84805C1.8156 5.89635 3.02742 4.15771 4.60168 2.786L4.61513 2.77254C5.46644 2.02544 6.41632 1.39879 7.43815 0.91016C8.17207 0.559771 8.93689 0.278171 9.7228 0.0689764L9.99198 0C3.09427 4.85027 3.30289 15.3617 10.569 19.7258C10.5926 19.7426 10.6161 19.756 10.6414 19.7712C10.7558 19.8418 10.8752 19.9108 10.998 19.9798C15.5472 22.3654 20.2561 21.7227 23.6511 19.2732Z" fill="#E6E6E6"/>
    </svg>
    
  )
}
 