import React from "react";

const ChangePasswordIcon = ({ color }) => {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="18.0061" cy="18.0061" r="18.0061" fill="#1A1A1C" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9532 13.5038C13.6616 13.4671 16.1292 12.4338 18.0061 10.7539C19.883 12.4338 22.3506 13.4671 25.059 13.5038C25.1573 14.0897 25.2085 14.6916 25.2085 15.3055C25.2085 20.0094 22.2022 24.0112 18.0061 25.4944C13.81 24.0112 10.8036 20.0094 10.8036 15.3055C10.8036 14.6916 10.8548 14.0897 10.9532 13.5038ZM21.3436 16.8424C21.6952 16.4908 21.6952 15.9207 21.3436 15.5691C20.992 15.2175 20.422 15.2175 20.0704 15.5691L17.1058 18.5337L15.9418 17.3697C15.5902 17.0182 15.0202 17.0182 14.6686 17.3697C14.317 17.7213 14.317 18.2914 14.6686 18.643L16.4692 20.4436C16.8208 20.7952 17.3908 20.7952 17.7424 20.4436L21.3436 16.8424Z"
        fill={color}
      />
    </svg>
  );
};

export default ChangePasswordIcon;
