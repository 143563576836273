import React from "react";

const BlogIcon = ({ color }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.81531 14.8525H1V23.6678H9.81531V14.8525Z"
        stroke={color}
        strokeWidth="1.67911"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.81531 1H1V9.81531H9.81531V1Z"
        stroke={color}
        strokeWidth="1.67911"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8525 2.25977H23.6678"
        stroke={color}
        strokeWidth="1.67911"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8525 8.55566H23.6678"
        stroke={color}
        strokeWidth="1.67911"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8525 16.1123H23.6678"
        stroke={color}
        strokeWidth="1.67911"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8525 22.4082H23.6678"
        stroke={color}
        strokeWidth="1.67911"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BlogIcon;
