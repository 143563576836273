import { useState, useEffect } from "react";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "./../config/Firebase/firebase";

const useGetData = (_collection) => {
  const [data, setData] = useState([]);

  const getAllTasks = async () => {
    const colRef = collection(db, _collection);
    onSnapshot(colRef, (snapshot) => {
      let tasks = [];
      snapshot.docs.forEach((doc) => {
        tasks.push({ ...doc.data(), id: doc.id });
      });

      setData(tasks);
    });
  };
  useEffect(() => {
    getAllTasks();
  }, []);
  return data;
};

export default useGetData;
