import { Grid, Paper } from "@mui/material";
import { Colors } from "../../../../../../config/palette";
import Slider from "../Slider/Slider";
import { useSelector } from "react-redux";
import { HeroSubText, HeroText } from "./SliderHomeMain.style";
import English from "./../../../../../../config/Langugage/English.json";
import Polish from "./../../../../../../config/Langugage/Polish.json";

const SliderHomeMain = () => {
  const selector = useSelector((state) => state.auth.language);
  return (
    <Paper elevation={0} square={true} style={{ background: Colors.secondary }}>
      <Grid
        container
        spacing={2}
        sx={{ paddingTop: "50px", paddingBottom: "50px" }}
      >
        <Grid item xs={12} md={6}>
          <HeroText>{selector === "English" ? ` ${English["415"]}` : Polish["415"]}</HeroText>
          <HeroSubText>
            {selector === "English" ? ` ${English["416"]}` : Polish["416"]}
          </HeroSubText>
        </Grid>
        <Grid item xs={12} md={6}>
          <Slider />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SliderHomeMain;
