import React from "react";
import ChangePasswordIcon from "../../../../assets/icons/ChangePasswordIcon";
import EditProfileIcon from "../../../../assets/icons/EditProfileIcon";
import BankAcountIcon from "../../../../assets/icons/BankAcountIcon";
import BoxCom from "../../../UI/BoxCom/BoxCom";
import TextfieldComp from "../../../UI/TextFieldCom/Textfield";
import defaultImage from "../../../../assets/images/homeOnBoarding/defaultProfileImage.jpg";
import {
  AvatarContainer,
  AvatarWrapper,
  ChangePasswordText,
  EditProfileText,
  FieldLabel,
  HeaderIconTextWrapper,
  Heading,
  IconAndTextContainer,
  MainContainer,
  ProfileButton,
  ProfileSettingHeader,
  SubHeading,
} from "./EditProfile.style ";
import CameraIcon from "../../../../assets/icons/CameraIcon";
import { Colors } from "../../../../config/palette";
import { useLocation } from "react-router";
import { getBasePath } from "../../../../Utils/utils";
import AddBank from "../AddBank/AddBank";
import ImageUploadField from "../../../UI/ImageUpload/ImageUploadField";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { auth } from "../../../../config/Firebase/firebase";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  imagePostService,
  updateService,
  getServiceById,
} from "../../../../services/services";
import { authActions } from "./../../../../redux/reducers/auth.js";
import English from "../../../../config/Langugage/English.json";
import Polish from "../../../../config/Langugage/Polish.json";

const EditProfile = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.userData);
  const [name, setName] = React.useState(userData.FullName);
  const [email, setEmail] = React.useState("");
  const [selected, setSelected] = React.useState("editProfile");
  const [showNewBankForm, setShowNewBankForm] = React.useState(true);
  const [previewImage, setPreviewImage] = React.useState(userData.ProfileImage);
  const [loading, setLoading] = React.useState(false);
  const selector = useSelector((state) => state.auth.language);
  // {selector === "English" ? ` ${English["110"]}` : Polish["110"]}

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  let FORM_VALIDATION = "";
  let initialValues = "";
  if (selected === "editProfile") {
    FORM_VALIDATION = Yup.object().shape({
      name: Yup.string().required("name is required"),
      image: Yup.string(),
    });

    initialValues = {
      image: userData.ProfileImage,
      name: name,
    };
  } else {
    FORM_VALIDATION = Yup.object().shape({
      email: Yup.string().email().required("email is required"),
    });

    initialValues = {
      email: "",
    };
  }

  let { pathname } = useLocation();
  pathname = getBasePath(pathname);

  function handleEditProfile() {
    setSelected("editProfile");
    setShowNewBankForm(false);
  }

  function handleChangePassword() {
    setSelected("changePassword");
    setShowNewBankForm(false);
  }

  const getUserData = async () => {
    let userData = localStorage.getItem("userData");

    if (userData) {
      userData = JSON.parse(userData);
      const docSnap = await getServiceById("Users", userData.uid);

      if (docSnap.exists()) {
        dispatch(authActions.setUserData(docSnap.data()));
      } else {
      }
    }
  };

  async function submitHandler(values) {
    if (selected === "changePassword") {
      const resetPassword = async () => {
        setLoading(true);
        await sendPasswordResetEmail(auth, values.email)
          .then((el) => {
            setLoading(false);

            // Reset Password link send to your Email
            enqueueSnackbar(
              selector === "English" ? English["361"] : Polish["361"],
              {
                variant: "success",
                autoHideDuration: 4000,
              }
            );
            navigate("/verifyCode");
          })
          .catch((e) => {
            setLoading(false);
            // Error occurred!
            enqueueSnackbar(
              selector === "English" ? English["362"] : Polish["362"],
              {
                variant: "error",
                autoHideDuration: 4000,
              }
            );
          });
      };
      resetPassword(values.email);
    } else if (selected === "editProfile") {
      try {
        setLoading(true);
        let image = userData.ProfileImage;
        let name = userData.FullName;
        if (previewImage) {
          image = await imagePostService(values.image);
        }
        if (values.name) {
          name = values.name;
        }

        let payload = { ...userData, FullName: name, ProfileImage: image };

        await updateService("Users", userData.id, payload);
        getUserData();
        // profile updated successfully
        enqueueSnackbar(
          selector === "English" ? English["363"] : Polish["363"],
          {
            variant: "success",
            autoHideDuration: 4000,
          }
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
        // Error occurred
        enqueueSnackbar(
          selector === "English" ? English["361"] : Polish["361"],
          {
            variant: "error",
            autoHideDuration: 4000,
          }
        );
      }
    } else {
      console.log("values of BankAccount", values);
    }
  }

  useEffect(() => {
    setName(userData.FullName);

    setEmail(userData.Email);
  }, [userData]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={FORM_VALIDATION}
      onSubmit={(values) => {
        submitHandler(values);
      }}
    >
      {(formik) => {
        const { errors, touched, values, handleChange } = formik;

        return (
          <>
            <Form>
              <ProfileSettingHeader
                width={pathname === "coachProfile" ? "80%" : "450px"}
              >
                {/* Profile settings */}
                <Heading>
                  {selector === "English" ? English["356"] : Polish["356"]}
                </Heading>
                {/* Account Setting */}
                <SubHeading>
                  {selector === "English" ? English["97"] : Polish["97"]}
                </SubHeading>
                <HeaderIconTextWrapper>
                  <IconAndTextContainer onClick={handleEditProfile}>
                    <EditProfileIcon
                      color={
                        selected === "editProfile" ? Colors.info : Colors.light
                      }
                    />
                    <EditProfileText
                      sx={{
                        color:
                          selected === "editProfile"
                            ? Colors.info
                            : Colors.light,
                      }}
                    >
                      {/* Edit Profile */}
                      {selector === "English" ? English["98"] : Polish["98"]}
                    </EditProfileText>
                  </IconAndTextContainer>
                  <IconAndTextContainer onClick={handleChangePassword}>
                    <ChangePasswordIcon
                      color={
                        selected === "changePassword"
                          ? Colors.info
                          : Colors.light
                      }
                    />
                    <ChangePasswordText
                      sx={{
                        color:
                          selected === "changePassword"
                            ? Colors.info
                            : Colors.light,
                      }}
                    >
                      {/* Change Password */}
                      {selector === "English" ? English["99"] : Polish["99"]}
                    </ChangePasswordText>
                  </IconAndTextContainer>
                  {pathname === "coachProfile" && (
                    <IconAndTextContainer
                      onClick={() => setSelected("bankAccount")}
                    >
                      <BankAcountIcon
                        color={
                          selected === "bankAccount"
                            ? Colors.info
                            : Colors.light
                        }
                      />
                      <ChangePasswordText
                        sx={{
                          color:
                            selected === "bankAccount"
                              ? Colors.info
                              : Colors.light,
                        }}
                      >
                        {/* Bank Account */}
                        {selector === "English"
                          ? English["135"]
                          : Polish["135"]}
                      </ChangePasswordText>
                    </IconAndTextContainer>
                  )}
                </HeaderIconTextWrapper>
                {selected === "editProfile" && (
                  <AvatarContainer>
                    <ImageUploadField
                      name="image"
                      setPreviewImage={(previewImage) =>
                        setPreviewImage(previewImage)
                      }
                    >
                      <label htmlFor="file">
                        <AvatarWrapper
                          sx={{ cursor: "pointer" }}
                          alt="Rimy Sharp"
                          src={
                            previewImage
                              ? previewImage
                              : userData.ProfileImage
                                ? userData.ProfileImage
                                : defaultImage
                          }
                        />
                      </label>
                    </ImageUploadField>

                    <BoxCom
                      sx={{
                        marginTop: "-35px",
                        zindex: "-5",
                        position: "absolute",
                        left: "72px",
                      }}
                    >
                      <CameraIcon />
                    </BoxCom>
                  </AvatarContainer>
                )}
              </ProfileSettingHeader>
              <MainContainer>
                <SubHeading>
                  {/* personal Information */}
                  {selected === "editProfile"
                    ? selector === "English"
                      ? English["102"]
                      : Polish["102"]
                    : selected === "changePassword"
                      ? selector === "English"
                        ? English["99"]
                        : Polish["99"]
                      : ""}
                </SubHeading>
                {selected === "editProfile" && (
                  <>
                    <BoxCom sx={{ marginTop: "20px" }}>
                      {/* Name */}
                      <FieldLabel>
                        {selector === "English"
                          ? English["103"]
                          : Polish["103"]}
                      </FieldLabel>
                      <TextfieldComp
                        height="50px"
                        width="100%"
                        autoComplete="false"
                        onChange={(e) => {
                          handleChange(e);
                          setName("");
                        }}
                        name="name"
                        value={values.name ? values.name : name}
                        justifyproperty="flex-start"
                        alignproperty="null"
                        helperText={
                          touched.name && errors.name ? errors.name : ""
                        }
                        error={errors.name && touched.name ? true : null}
                      />
                    </BoxCom>
                    <BoxCom sx={{ paddingTop: "35px" }}>
                      {/* Email */}
                      <FieldLabel>
                        {selector === "English" ? English["5"] : Polish["5"]}
                      </FieldLabel>
                      <TextfieldComp
                        height="50px"
                        width="100%"
                        autoComplete="false"
                        name="email"
                        value={values.email ? values.email : email}
                        justifyproperty="flex-start"
                        alignproperty="null"
                        disabled={true}
                      />
                    </BoxCom>
                  </>
                )}
                {selected === "changePassword" && (
                  <BoxCom sx={{ paddingTop: "35px" }}>
                    {/* Email */}
                    <FieldLabel>
                      {selector === "English" ? English["5"] : Polish["5"]}
                    </FieldLabel>
                    <TextfieldComp
                      height="50px"
                      width="100%"
                      autoComplete="false"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      name="email"
                      value={values.email}
                      justifyproperty="flex-start"
                      alignproperty="null"
                      helperText={errors.email ? errors.email : ""}
                      error={errors.email ? true : null}
                    />
                  </BoxCom>
                )}

                {selected === "bankAccount" && (
                  <>
                    <AddBank
                      showNewBankForm={showNewBankForm}
                      setShowNewBankForm={setShowNewBankForm}
                    />
                  </>
                )}
                {selected !== "bankAccount" && (
                  <ProfileButton type="submit" variant="contained">
                    {/* Please wait...  SEND   Save Changes */}
                    {loading
                      ? selector === "English"
                        ? English["360"]
                        : Polish["360"]
                      : selected === "changePassword"
                        ? selector === "English"
                          ? English["21"]
                          : Polish["21"]
                        : selector === "English"
                          ? English["104"]
                          : Polish["104"]}
                  </ProfileButton>
                )}
              </MainContainer>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default EditProfile;
