import React from "react";
import {
  CardFooter,
  CourseImage,
  CourseNameContainer,
  IconContainer,
  NameContainer,
  FavoriteButton,
  PayButton,
} from "./CourseCard.style";
import BoxCom from "../../../../UI/BoxCom/BoxCom";
import { Grid, Typography } from "@mui/material";
import { Colors } from "../../../../../config/palette";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Link } from "react-router-dom";
import { CURRENCY } from "../../../../../config/constant";
import { useSelector } from "react-redux";
import English from "../../../../../config/Langugage/English.json";
import Polish from "../../../../../config/Langugage/Polish.json";

const CourseCard = ({ size, courseList }) => {
  const store = useSelector((state) => state);
  const { auth, user: _user } = store;
  const selector = auth.language;
  return (
    <>
      {courseList?.map((item, index) => {
        return (
          <Grid
            item
            xs={12}
            sm={size?.sm}
            md={size?.md}
            lg={size?.lg}
            key={index}
          >
            <Link to="/login" style={{ textDecoration: "none" }}>
              <BoxCom sx={{ background: Colors.dark, borderRadius: "16px" }}>
                <CourseImage src={item.CoverImage} alt="Paris" />
                <IconContainer>
                  <FavoriteButton
                    aria-label="share"
                  //   onClick={() => addFavorite(item.id, item, index)}
                  >
                    <FavoriteIcon />
                  </FavoriteButton>
                </IconContainer>
                <BoxCom>
                  <NameContainer variant="body1">
                    {item.CoachName}
                  </NameContainer>
                  <CourseNameContainer variant="body1">
                    {item.Title}
                  </CourseNameContainer>
                  <CardFooter>
                    <Typography
                      variant="body1"
                      sx={{ color: "#0AB27D", marginLeft: "12px" }}
                    >
                      {item.Price} {CURRENCY}
                    </Typography>

                    <PayButton
                      variant="contained"
                      size="small"
                    //   onClick={() => (isPucrchased ? navigatHandler(item) : null)}
                    >
                      {selector === "English" ? ` ${English["389"]}` : Polish["389"]}
                    </PayButton>
                  </CardFooter>
                </BoxCom>
              </BoxCom>
            </Link>
          </Grid>
        );
      })}
    </>
  );
};

export default CourseCard;
