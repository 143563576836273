import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  CourseBox,
  CourseVideoAccordianAlign,
  CourseVideoAccordianHeading,
  CourseVideoAccordianTypography,
  VideoLinkWrapper,
} from "./CourseVideosAccordian.style";
import { Colors } from "../../../../config/palette";
import { Grid } from "@mui/material";
import Modal from "./../../../UI/Modal/Modal";
import VideoPlayer from "./../../../UI/VideoPlayer/VideoPlayer";

export default function CourseAccordions({ module }) {
  const [expanded, setExpanded] = React.useState(false);
  const [openModal, setopenModal] = React.useState(false);
  const [videoLink, setVideoLink] = React.useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const videoLinkHanlder = (link) => {
    setVideoLink(link);
    setopenModal(true);
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} lg={12}>
        <CourseBox>
          <CourseVideoAccordianHeading>Curriculum</CourseVideoAccordianHeading>
          {module?.map((item) => {
            return (
              <Accordion
                sx={{ background: Colors.dark, border: "0.25px solid #676F85" }}
                disableGutters={true}
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: Colors.light }} />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <CourseVideoAccordianTypography>
                    {item.ModuleName}
                  </CourseVideoAccordianTypography>
                </AccordionSummary>
                <AccordionDetails>
                  {item.ModuleVideoArr.map((nestedItem) => {
                    return (
                      <>
                        <CourseVideoAccordianAlign>
                          <VideoLinkWrapper
                            onClick={() => videoLinkHanlder(nestedItem.Video)}
                          >
                            <CourseVideoAccordianTypography>
                              {nestedItem.Title}
                            </CourseVideoAccordianTypography>
                          </VideoLinkWrapper>

                          <CourseVideoAccordianTypography>
                            {nestedItem.duration.substring(0, 4)}
                          </CourseVideoAccordianTypography>
                        </CourseVideoAccordianAlign>
                      </>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            );
          })}
          <Modal
            open={openModal}
            setOpen={setopenModal}
            modelWidth="50%"
            modelHight="100px"
          >
            <VideoPlayer videoLink={videoLink} maxHeight="500px" />
          </Modal>
        </CourseBox>
      </Grid>
    </Grid>
  );
}
